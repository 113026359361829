import { IShareholder } from "../../../../../../../store/shareholderList/types";

const disabledFeatureUser = {
  firstName: "Jane",
  lastName: "D",
  sharesOwned: 100,
  shareValue: 100,
  location: "California, US",
  ticker: "ABC",
  globalShareholderId: "1",
};

export const disabledFeatureData = Array.from({ length: 10 }, (_, i) => ({
  ...disabledFeatureUser,
  globalShareholderId: `${i}`,
})) as IShareholder[];
