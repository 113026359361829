import { Text } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import {
  BrandAssetsColors,
  BrandAssetsImages,
} from "../../../../../components/Common";

interface IProps {
  isLoading?: boolean;
}

const BrandAssets = ({ isLoading }: IProps) => {
  return (
    <Stack spacing={6}>
      <Box>
        <Text variant="subtitle1" sx={{ mb: 2 }}>
          Brand assets
        </Text>
        <Text variant="body2">
          These assets give your company a consistent look across Say. Reference
          asset guidelines{" "}
          <a
            href={`${process.env.REACT_APP_ASSETS}documents/say_brand_assets_guidelines.pdf`}
            target="_blank"
            style={{ fontWeight: 600 }}
            rel="noreferrer"
          >
            here
          </a>
          .
        </Text>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        {/* LOGO */}
        <BrandAssetsImages isLoading={isLoading} />
        {/* COLORS */}
        <BrandAssetsColors isLoading={isLoading} />
      </Stack>
    </Stack>
  );
};

export default BrandAssets;
