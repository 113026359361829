import {
  COLORS,
  Dialog,
  IButtonAction,
  Text,
} from "@asayinc/component-library";
import { Stack } from "@mui/material";

interface IProps {
  open: boolean;
  buttonActions: IButtonAction[];
  toggleDialog: () => void;
  img: string;
  footerText: string;
  title: string;
  description: string;
}

const ViewSampleDialog = ({
  open,
  buttonActions,
  toggleDialog,
  img,
  title,
  footerText,
  description,
}: IProps) => {
  return (
    <Dialog
      title={title}
      customTitle={
        <Text variant="body2" pl={8}>
          {description}
        </Text>
      }
      fullBleed
      customActionStyles={{ alignItems: "center", button: { width: "auto" } }}
      content={
        <Stack maxWidth="650px" width="100%" my={5}>
          <Stack bgcolor={"#F0F0F0"} py={5} px={10}>
            <img width="100%" height="auto" src={img} />
          </Stack>
          <Stack py={4} bgcolor={COLORS.WHITE} px={8}>
            <Text variant="body3" emphasis="medium_high">
              {footerText}
            </Text>
          </Stack>
        </Stack>
      }
      open={open}
      buttonActions={buttonActions}
      handleClose={toggleDialog}
    />
  );
};

export default ViewSampleDialog;
