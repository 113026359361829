import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Stack } from "@mui/material";
import { GenericFilter } from "../../../../../../../components/Common";
import { useGetQuestionTagsQuery } from "../../../../../../../store/questionTags";
import { useGetQuestionCategoriesQuery } from "../../../../../../../store/questionCategories";
import { useGetShareholderTagsQuery } from "../../../../../../../store/shareholderTags";
import { URL_PARAMS } from "../../../../../../../constants";
import { useParams } from "react-router-dom";
import { AnsweredFilterOptions } from "./constants";
import {
  DateFilter,
  RangeFilter,
  TagFilter,
} from "../../../../../../../components/Common";
import { useTracking } from "./useTracking";
import {
  initialState,
  useGetEventQuery,
} from "../../../../../../../store/event";
import { ISettings } from "../../../../../../../store/settings/types";
import { useGetSettingsQuery } from "../../../../../../../store/settings";

const FilterList = (): JSX.Element => {
  const { eventSlug } = useParams() as { eventSlug: string };
  // company data
  const { data: settings = {} } = useGetSettingsQuery();
  const { name: companyName } = settings as ISettings;
  // event data
  const { data: event = initialState } = useGetEventQuery(eventSlug);
  const { status, slug } = event;

  const { data: tagOptions = [], isLoading: isTagDataLoading } =
    useGetQuestionTagsQuery(eventSlug);
  useTracking({ companyName, slug, eventStatus: status });
  const { data: categoryOptions = [], isLoading: isCategoryDataLoading } =
    useGetQuestionCategoriesQuery(eventSlug);

  const {
    data: shareholderTagOptions = [],
    isLoading: shareholderTagsIsLoading,
  } = useGetShareholderTagsQuery();

  const methods = useForm({ criteriaMode: "all" });

  return (
    <Stack
      direction="row"
      gap={4}
      mt={2}
      width="100%"
      justifyContent="flex-end"
    >
      <FormProvider {...methods}>
        <TagFilter
          tagIdsParameter={URL_PARAMS.tags}
          noTagsParameter={URL_PARAMS.filter}
          noTagsValue="does_not_contain_tags"
          tagOptions={tagOptions}
          fieldName="questionTags"
          chipLabel="Question tags"
          isLoading={isTagDataLoading}
          valuesLabel="Questions tagged with"
          noValueLabel="Is not tagged"
        />
        <TagFilter
          tagIdsParameter={URL_PARAMS.specificCategoryIds}
          tagOptions={categoryOptions}
          fieldName="questionCategories"
          chipLabel="Question topics"
          isLoading={isCategoryDataLoading}
          valuesLabel="Questions belonging to topic"
        />
        <RangeFilter
          fieldName="sharesRange"
          minParam={URL_PARAMS.sharesUpvotedMin}
          maxParam={URL_PARAMS.sharesUpvotedMax}
          chipLabel="Shares"
        />
        <RangeFilter
          fieldName="upvotesRange"
          minParam={URL_PARAMS.upvoteCountMin}
          maxParam={URL_PARAMS.upvoteCountMax}
          chipLabel="Upvotes"
        />
        <DateFilter
          beforeParam={URL_PARAMS.submittedBefore}
          afterParam={URL_PARAMS.submittedAfter}
          fieldNamePrefix="submitted"
          chipLabel="Submitted"
        />
        <TagFilter
          tagIdsParameter={URL_PARAMS.shareholderTagIds}
          noTagsParameter={URL_PARAMS.shareholdersNotTagged}
          noTagsValue="true"
          tagOptions={shareholderTagOptions}
          fieldName="shareholderTags"
          chipLabel="Asked by"
          isLoading={shareholderTagsIsLoading}
          valuesLabel="Shareholders tagged with"
          noValueLabel="Shareholders not tagged"
        />
      </FormProvider>
      <GenericFilter
        options={AnsweredFilterOptions}
        label="Answered"
        queryParameter={URL_PARAMS.isAnswered}
        customDisplayValue="Answered"
      />
    </Stack>
  );
};

export default React.memo(FilterList);
