import { RootState } from "../store";
import { IPublishDialog } from "./types";

export const selectCreationStep = (state: RootState): number =>
  state.event.creationStep;
export const selectDisplayPublishDialog = (
  state: RootState
): IPublishDialog | undefined | null => state.event.displayPublishDialog;
export const selectIsDraftCreated = (state: RootState): boolean =>
  state.event.isDraftCreated;
