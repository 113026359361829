import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
// components
import { CardWrapper } from "../../../../components/Common";
import { QATimeline } from "./components";
// data
import { useGetEventQuery, initialState } from "../../../../store/event";
import { useGetSettingsQuery } from "../../../../store/settings";
// types
import { ISettings } from "../../../../store/settings/types";
import { track } from "../../../../analytics";
import { useEffect } from "react";

const EventInfo = () => {
  const { eventSlug } = useParams() as {
    eventSlug: string;
  };
  const { data: event = initialState, isSuccess } = useGetEventQuery(eventSlug);
  const { status, slug } = event;

  const { data: settings = {}, isSuccess: isCompanySuccess } =
    useGetSettingsQuery();
  const { name: companyName } = settings as ISettings;

  useEffect(() => {
    if (isSuccess && isCompanySuccess) {
      track({
        name: "Q&A Event Selected",
        client: companyName,
        qaEvent: slug,
        eventStatus: status,
        menuTab: "Event info",
      });
    }
  }, [isSuccess, isCompanySuccess]);

  return (
    <Stack
      gap={10}
      direction={["column-reverse", "row"]}
      justifyContent="flex-end"
      flex="1"
      data-testid="event-overview"
    >
      <Stack flex="1">
        <CardWrapper sx={{ p: 8 }} cardName="qa-preview">
          <iframe
            style={{ width: "100%", height: "800px" }}
            src={`${process.env.REACT_APP_QA_URL}${eventSlug}`}
          />
        </CardWrapper>
      </Stack>
      <Stack flex={["0", "1"]} gap={10}>
        <CardWrapper sx={{ p: 8 }} cardName="qa-timeline">
          <QATimeline />
        </CardWrapper>
      </Stack>
    </Stack>
  );
};

export default EventInfo;
