export const AI_TOOLTIP_TITLE = (
  <span>
    Say uses AI to show the most popular topics from your event, and shareholder
    sentiment about them.
    <br />
    <br />
    Sentiment scores range from 0 to 100, with the following distribution:
    <br />
    Negative (0-24)
    <br />
    Slightly negative (25-44)
    <br />
    Neutral (45-54)
    <br />
    Slightly positive (55-74)
    <br />
    Positive (75-100)
  </span>
);
