import {
  Dialog,
  IButtonAction,
  IconButton,
  Text,
} from "@asayinc/component-library";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRequestMoreInfo } from "../../../../../../../hooks";
import { UiEventActions } from "../../../../../../../store/consumerUser/types";

interface IProps {
  createMessage: (type: string) => void;
  toggleDialog: () => void;
  dialogType?: string;
  isLoading: boolean;
}

const TryMessagesDialog = ({
  createMessage,
  toggleDialog,
  dialogType,
  isLoading,
}: IProps) => {
  // track request more info
  const { requestMoreInfo, isLoading: isRequestLoading } = useRequestMoreInfo({
    action: UiEventActions.RequestInfoMessages,
    callback: toggleDialog,
  });

  // click message users
  const clickMessage = () => {
    createMessage(dialogType as string);
  };

  // sign up for messages
  const signUp = () => {
    requestMoreInfo();
  };

  // close dialog
  const closeDialog = () => {
    toggleDialog();
  };

  const buttonActions = [
    {
      label: "Try for free",
      onClick: clickMessage,
      variant: "primary",
      disabled: isLoading || isRequestLoading,
    },
    {
      label: "Sign up",
      onClick: signUp,
      variant: "textSecondary",
      disabled: isLoading || isRequestLoading,
    },
  ] as IButtonAction[];

  return (
    <Dialog
      fullBleed
      data-testid={`try-messages-dialog`}
      handleClose={closeDialog}
      buttonActions={buttonActions}
      sx={{
        ".MuiDialogActions-root>div": {
          alignItems: "center",
          button: {
            width: "auto",
          },
        },
      }}
      content={
        <Box textAlign="center" mb={8} maxWidth={662}>
          <IconButton
            aria-label="close"
            sx={{
              right: (theme) => theme.spacing(3),
              top: (theme) => theme.spacing(3),
              position: "absolute",
            }}
            data-testid="dialog-close"
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={`${process.env.REACT_APP_ASSETS}images/ir-portal/ip/messages_dialog_illustration.svg`}
            alt="Try messages"
            width="100%"
            height="auto"
          />
          <Text variant="h4" mb={6} mt={10}>
            Message shareholders directly, through Say
          </Text>
          <Text variant="body1" maxWidth="546px" margin="0 auto">
            You choose the list of shareholders - top 50, 100, or all - and
            craft a message thats delivered right to their inbox.
          </Text>
        </Box>
      }
      open={!!dialogType}
    />
  );
};

export default TryMessagesDialog;
