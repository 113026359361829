import { Stack } from "@mui/material";
import { useEffect, useRef } from "react";
import { EventCard } from "../../Molecules";
import { Event } from "../../../../../types/Events";
import { Carousel } from "../../../../../components/Common";
import { SwiperSlide } from "swiper/react";

interface IProps {
  events: Event[];
  count: number;
  isLoading: boolean;
}

const EventCardList = ({ events, isLoading }: IProps) => {
  const didMount = useRef<boolean>(false);

  useEffect(() => {
    didMount.current = true;
  }, []);

  const loadingContent = Array(3)
    .fill(true)
    .map((_, i) => <EventCard isLoading={true} key={"loading" + i} />);

  return (
    <Stack mb={4} data-testid={`cards-in-progress`} gap={6}>
      {isLoading ? (
        <Stack direction="row" gap={6} pl="13px" ml="-13px" pr={10}>
          {loadingContent}
        </Stack>
      ) : (
        <Carousel>
          {events.map((event) => (
            <SwiperSlide key={event.slug}>
              <EventCard event={event} />
            </SwiperSlide>
          ))}
        </Carousel>
      )}
    </Stack>
  );
};

export default EventCardList;
