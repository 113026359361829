import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSearchParams } from "react-router-dom";
// components
import { Close } from "@mui/icons-material";
import { Drawer, Stack } from "@mui/material";
import { IconButton, Text, nFormatter } from "@asayinc/component-library";
import { Sentiment } from "../../Atoms";
import CardWrapper from "../CardWrapper";
// redux / data
import {
  ITrendingCategory,
  useGetSentimentQuery,
} from "../../../../store/sentiment";
// constants / types
import { URL_PARAMS } from "../../../../constants";

const TopicDrawer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const topicId = searchParams.get(URL_PARAMS.tid);
  const eventSlug = searchParams.get(URL_PARAMS.eid);

  const open = !!topicId && !!eventSlug;

  // close side drawer
  const close = () => {
    searchParams.delete(URL_PARAMS.tid);
    searchParams.delete(URL_PARAMS.eid);
    setSearchParams(searchParams);
  };

  const onClose = (_: unknown, reason: string) => {
    if (reason === "backdropClick") {
      close();
    }
  };

  const {
    data: topicData,
    isFetching,
    isLoading,
  } = useGetSentimentQuery(
    open
      ? {
          eventSlug,
          params: {
            specific_category_ids: topicId,
          },
        }
      : skipToken
  );
  const topic = topicData?.results?.[0] || {};
  const { name, sharesOwned, bestQuestion, sentiment } =
    topic as ITrendingCategory;

  const showLoadingState = isFetching || isLoading;

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      ModalProps={{
        BackdropProps: {
          sx: { backgroundColor: "transparent" },
        },
      }}
      onClose={onClose}
      data-testid="topic-drawer"
      PaperProps={{ elevation: 5 }}
      sx={{
        ".MuiDrawer-paper.MuiPaper-root": {
          width: "600px",
          backgroundColor: "#F0F0F0",
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 3px 18px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Stack gap={8} p={10}>
        <Stack direction="row" alignItems="center">
          <Text variant="h5">Topic</Text>
          <IconButton
            onClick={close}
            data-testid="drawer-close-button"
            sx={{ ml: "auto" }}
          >
            <Close />
          </IconButton>
        </Stack>
        <CardWrapper cardName="topic-drawer-content">
          <Text variant="h5" mb={1} loadingProps={{ sx: { width: "100px" } }}>
            {showLoadingState ? undefined : name}
          </Text>
          <Stack direction="row" mb={6}>
            {showLoadingState ? (
              <Text loadingProps={{ sx: { width: "200px" } }} />
            ) : (
              <>
                <Sentiment sentiment={sentiment} />
                <Text variant="body2" ml={1}>
                  {" "}
                  · {nFormatter(sharesOwned)} shares represented
                </Text>
              </>
            )}
          </Stack>
          <Text variant="body1" loadingProps={{ sx: { width: "100%" } }}>
            {showLoadingState ? undefined : bestQuestion}
          </Text>
        </CardWrapper>
      </Stack>
    </Drawer>
  );
};

export default TopicDrawer;
