import { Link, Stack } from "@mui/material";
import {
  Text,
  ButtonV2,
  COLORS,
  DATE_FORMAT,
  TIME_FORMAT,
} from "@asayinc/component-library";
// route / data
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {
  initialState,
  useGetEventQuery,
} from "../../../../../../../store/event";
import { useNavigate } from "react-router-dom";
import { ContentWrapper } from "../../../../../Atoms";

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface IProps {
  onClose: () => void;
  isDraft: boolean;
  eventSlug: string;
}

/**
 * Displays different content based on if its a draft event or not
 */
const SetPublishDateReview = ({
  onClose,
  isDraft,
  eventSlug,
}: IProps): JSX.Element | null => {
  const navigate = useNavigate();
  const { data: event = initialState } = useGetEventQuery(eventSlug);
  const { title, publishDatetime, slug } = event;

  const previewUrl = `${process.env.REACT_APP_QA_URL}${slug}`;

  /**
   * redirect to all qa's
   */
  const goToAllQAs = () => {
    onClose();
    navigate("/qa");
  };

  /**
   * Open preview url in a new 800x600 window
   */
  const openPreviewUrl = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    window.open(previewUrl, "newwindow", "width=800,height=600");
  };

  const formattedDate = dayjs(publishDatetime).format(DATE_FORMAT);
  const formattedTime = dayjs(publishDatetime).format(TIME_FORMAT);

  const getBodyCopy = () => {
    if (isDraft) {
      return (
        <>
          <Text variant="body2" sx={{ mb: 5 }}>
            You can now preview <b>{title}</b> at{" "}
            <Text variant="subtitle2" component="span">
              <Link
                href={previewUrl}
                onClick={openPreviewUrl}
                data-testid="link-preview-url"
                aria-label="view preview url of Q&amp;A event"
                style={{
                  color: COLORS.SHADED_COBALT,
                  textDecorationColor: COLORS.SHADED_COBALT,
                }}
                rel="noreferrer"
              >
                {`${process.env.REACT_APP_QA_URL?.split("://")?.[1]}${slug}`}
              </Link>
            </Text>
          </Text>
          <Text variant="body2" sx={{ mb: 5 }}>
            The Q&amp;A will be reviewed for quality assurance by our team. This
            may take up to 1 business day.
          </Text>
        </>
      );
    }

    return (
      <>
        <Text variant="body2" sx={{ mb: 5 }}>
          Your changes will be reviewed for quality assurance by our team. This
          may take up to 1 business day.
        </Text>
        <Text variant="body2">
          After approval, your Q&amp;A is set to be published on{" "}
          <b>{formattedDate}</b> at <b>{formattedTime}</b>.
        </Text>
      </>
    );
  };

  return (
    <ContentWrapper
      mw={468}
      onClose={onClose}
      title={
        isDraft ? "Your Shareholder QA is under review" : "Changes submitted!"
      }
    >
      <>
        {getBodyCopy()}
        <Stack spacing={3} width="100%" mt={6}>
          <ButtonV2
            data-testid="fab-ok"
            sx={{ width: "100%" }}
            onClick={onClose}
          >
            Ok
          </ButtonV2>
          <ButtonV2
            data-testid="fab-all-qa"
            sx={{ width: "100%" }}
            variant="secondary"
            onClick={goToAllQAs}
          >
            Go to all Q&amp;As
          </ButtonV2>
        </Stack>
      </>
    </ContentWrapper>
  );
};

export default SetPublishDateReview;
