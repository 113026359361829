import { ButtonV2, Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
  featureEnabled?: boolean;
}

const NoEvents = ({ featureEnabled }: IProps) => {
  const navigate = useNavigate();

  const goToCreateEvent = useCallback(() => {
    navigate("/qa/create");
  }, []);

  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      flex={1}
      pb={10}
    >
      <img
        width={400}
        src={`${process.env.REACT_APP_ASSETS}images/ir-portal/empty_qa.png`}
      />
      <Text variant="subtitle1" sx={{ mb: 3 }}>
        No Q&A event
      </Text>
      <Text variant="body1" mb={6}>
        Create your first Q&A event
      </Text>
      <ButtonV2
        data-testid="fab-create-event"
        onClick={goToCreateEvent}
        disabled={!featureEnabled}
      >
        New Q&amp;A
      </ButtonV2>
    </Stack>
  );
};

export default NoEvents;
