import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Stack } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { FeatureIntroDialog, ListHeader } from "../../components/Common";

import { useGetProxyEventDetailsQuery } from "../../store/proxyEvent";
import { getLifeCycleData, getMeetingName, isAfterVoteCutoff } from "./utils";
import { LifecyclePill } from "./components";
import {
  ConfirmEditDialog,
  ScheduleShareholderMessage,
  TestEmailDialog,
} from "../../components/Messages";
import { useGetFeaturesQuery } from "../../store/settings";
import { useGetAllProxyEventsQuery } from "../../store/proxyEvents";
import { IProxyEvent } from "../../store/proxyEvents/types";
import { UiEventActions } from "../../store/consumerUser/types";
import { useFeaturesDisabled } from "../../hooks/useFeaturesDisabled";

const Layout = () => {
  const { proxyEventId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useGetProxyEventDetailsQuery(
    proxyEventId || skipToken
  );
  const { disabledFeatures, isSingleFeature } = useFeaturesDisabled();

  const { data: events = [] as IProxyEvent[] } =
    useGetAllProxyEventsQuery(true);
  const event = events.length ? events[0] : ({} as IProxyEvent);
  const activeProxyId = proxyEventId || event?.id;

  // get text/icon for lifecycle status if on voteid page
  const { text, icon, disabled } = useMemo(
    () =>
      getLifeCycleData({
        meetingDate: data?.meeting?.meetingDate,
        voteCutoff: data?.voteCutoff,
      }),
    [data]
  );

  const goBack = () => {
    navigate("/vote");
  };

  const disableHeaderButton = useMemo(
    () => isLoading || isAfterVoteCutoff(data?.voteCutoff),
    [data, isLoading]
  );

  const { data: features } = useGetFeaturesQuery();
  const isFeatureEnabled = features?.messages;

  return (
    <>
      <Stack px={10} maxWidth={1440} m="0 auto" height="100%">
        <ListHeader
          sx={{ pb: 0 }}
          title={
            proxyEventId
              ? isLoading
                ? undefined
                : getMeetingName(
                    data?.meeting?.type || "",
                    data?.meeting?.meetingDate
                  )
              : "Voting"
          }
          goBackProps={
            proxyEventId
              ? {
                  text: "All votings",
                  onClick: goBack,
                }
              : undefined
          }
          displayCta
          statusNode={
            proxyEventId && !isLoading && text ? (
              <LifecyclePill text={text} icon={icon} disabled={disabled} />
            ) : undefined
          }
          buttonProps={
            isFeatureEnabled
              ? {
                  disabled: proxyEventId ? disableHeaderButton : !activeProxyId,
                  url: `/vote/${activeProxyId}/create`,
                  text: "New message",
                }
              : undefined
          }
        />
        <Outlet />
      </Stack>
      <FeatureIntroDialog
        img={`${process.env.REACT_APP_ASSETS}images/ir-portal/ip/vote_dialog_illustration.svg`}
        imgAlt="Voting messages intro"
        title="Introducing Voting"
        description="Activate your shareholders and influence company voting events. Educate your shareholders, and alert them to upcoming votes through vote messages."
        url="/vote"
        action={UiEventActions.CloseModalUpsellVoteMessages}
        fieldName="portalVsModalUpsell"
        conditional={
          isSingleFeature && disabledFeatures.includes("voteSolicitation")
        }
      />
      <ScheduleShareholderMessage />
      <TestEmailDialog />
      <ConfirmEditDialog />
    </>
  );
};

export default Layout;
