import { useState } from "react";
import { Box, Collapse, AlertProps } from "@mui/material";
import { Alert } from "@asayinc/component-library";
import { SxProps } from "@mui/system";

interface IAction {
  label: string;
  disabled?: boolean;
  onClick: () => void;
  testid?: string;
}

interface IProps {
  showAlert: boolean;
  actions?: IAction[];
  message: string;
  icon?: React.ReactElement;
  alertProps?: AlertProps;
  sx?: SxProps;
  collapseMargin?: number;
  defaultCloseIcon?: boolean;
  showNotificationIcon?: boolean;
}

const CollapsingAlert = ({
  showAlert,
  actions,
  message,
  icon,
  alertProps,
  sx,
  collapseMargin,
  ...rest
}: IProps) => {
  const [open, setOpen] = useState(showAlert);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Collapse
      in={open}
      sx={{ mt: collapseMargin ? -collapseMargin : undefined }}
      data-testid={`snackbar-collapse-${open ? "open" : "closed"}`}
    >
      <Box pt={collapseMargin}>
        <Alert
          alertProps={alertProps}
          customNotificationIcon={icon}
          actions={actions}
          onClose={handleClose}
          message={message}
          sx={sx}
          {...rest}
        />
      </Box>
    </Collapse>
  );
};

export default CollapsingAlert;
