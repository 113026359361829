export interface IUserFeatures {
  inAppEntrypointOverlay: boolean;
  portalMessagesModalUpsell: boolean;
  portalGeneralModalUpsell: boolean;
  portalSnModalUpsell: boolean;
  portalVsModalUpsell: boolean;
  portalQaModalUpsell: boolean;
  portalSnTabUpsell: boolean;
  portalQaTabUpsell: boolean;
  portalVsTabUpsell: boolean;
  portalMessagesTabUpsell: boolean;
  hasActiveSnFeatureTrial: boolean;
}

export interface INoboCheck {
  displayNoboModal: boolean;
}

export interface IUser {
  dateJoined: string;
  email: string;
  features: IUserFeatures;
  firstName: string;
  id: number;
  lastName: string;
  phoneNumber: string;
  token: string | null;
}

export enum UiEventTypes {
  Click = "click",
}

export enum UiEventActions {
  AcceptNobo = "accept_nobo",
  RequestInfoMessages = "request_info_messages",
  RequestInfoVoteMessages = "request_info_vs",
  RequestInfoShareholderNetwork = "request_info_sn",
  RequestInfoQA = "request_info_qa",
  TabClickMessages = "tab_click_messages",
  TabClickVoteMessages = "tab_click_vs",
  TabClickShareholderNetwork = "tab_click_sn",
  CloseModalUpsellMessages = "close_modal_upsell_messages",
  CloseModalUpsellVoteMessages = "close_modal_upsell_vs",
  CloseModalUpsellShareholderNetwork = "close_modal_upsell_sn",
  CloseModalUpsellGeneral = "close_modal_upsell_general",
  Login = "login",
}

export interface IUiEvent {
  type: UiEventTypes;
  action: UiEventActions;
  sourceUrl: string;
  targetUrl: string | null;
}
