import { Params } from "../../../../types/Common";

/**
 * Get no results messaging
 */
export const getNoResultsProps = (
  params: Params
): { title: string; subtitle: string } => {
  const hasFilterApplied = params.filter || params.query || params.type;
  return {
    title: hasFilterApplied
      ? "No topics in this filtered view"
      : "No topics yet",
    subtitle: hasFilterApplied
      ? "Sorry, there are no topics that match your applied filters."
      : "There have not been any topics generated from questions yet.",
  };
};
