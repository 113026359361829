import { IGraphDotStat } from "../../types";

interface IProps {
  cx: number;
  cy: number;
  onMouseEnter: (
    e: React.MouseEvent<SVGSVGElement>,
    stats: IGraphDotStat
  ) => void;
  onMouseLeave: () => void;
  dotStats: IGraphDotStat;
}

const MailDot = ({ cx, cy, onMouseEnter, onMouseLeave, dotStats }: IProps) => {
  const handleMouseEnter = (e: React.MouseEvent<SVGSVGElement>) => {
    onMouseEnter(e, dotStats);
  };
  return (
    <svg
      x={cx}
      y={cy}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid="icon-MailDot"
      width="52"
      height="58"
      viewBox="0 0 52 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_753_78316)">
        <rect x="12" y="12" width="28" height="28" rx="4" fill="white" />
        <path
          d="M20.6663 31.3333C20.2997 31.3333 19.9859 31.2028 19.725 30.942C19.4637 30.6806 19.333 30.3666 19.333 30V22C19.333 21.6333 19.4637 21.3195 19.725 21.0586C19.9859 20.7973 20.2997 20.6666 20.6663 20.6666H31.333C31.6997 20.6666 32.0137 20.7973 32.275 21.0586C32.5359 21.3195 32.6663 21.6333 32.6663 22V30C32.6663 30.3666 32.5359 30.6806 32.275 30.942C32.0137 31.2028 31.6997 31.3333 31.333 31.3333H20.6663ZM25.9997 26.6666L31.333 23.3333V22L25.9997 25.3333L20.6663 22V23.3333L25.9997 26.6666Z"
          fill="#26896B"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_753_78316"
          x="0"
          y="0"
          width="52"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_753_78316"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_753_78316"
            result="effect2_dropShadow_753_78316"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_753_78316"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MailDot;
