// say
import { Snackbar as SnackbarComponent } from "@asayinc/component-library";
// redux
import { selectSnackbar, clearSnackbar } from "../../../../store/commonReducer";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { useNavigate, useLocation } from "react-router-dom";

const Snackbar = (): JSX.Element => {
  // router
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  // state
  const {
    message,
    questionId,
    open,
    type,
    ctaText,
    severity,
    autoHideDuration,
  } = useAppSelector(selectSnackbar);

  /* istanbul ignore next */ // default mui snackbar
  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(clearSnackbar());
  };

  const goToQuestion = (e: React.SyntheticEvent | React.MouseEvent) => {
    navigate(`${pathname}/${questionId}${search}`);
    handleClose(e);
  };

  const actions = (() => {
    switch (type) {
      case "goToQuestion":
        return [
          {
            testid: "button-view-snackbar",
            onClick: goToQuestion,
            label: "View",
          },
        ];
      case "standard":
        return [
          {
            onClick: handleClose,
            testid: "button-view-snackbar",
            label: ctaText as string,
          },
        ];
      default:
        return undefined;
    }
  })();

  return (
    <SnackbarComponent
      open={open}
      onClose={handleClose}
      autoHideDuration={autoHideDuration || 4000}
      message={message}
      defaultCloseIcon={false}
      actions={actions}
      alertProps={{
        severity,
      }}
    />
  );
};

export default Snackbar;
