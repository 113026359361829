import {
  COLORS,
  SayIcon,
  Text,
  TooltipInfoIcon,
  nFormatter,
} from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { formatAsPercentage } from "../../../../utils";

interface IProps {
  csat: number;
  neutralPlus: number;
  replies: number;
  sent: number;
  isLoading: boolean;
  inCarousel?: boolean;
}

const ShareholderFeedbackSummaryCard = ({
  csat,
  neutralPlus,
  replies,
  sent,
  inCarousel,
  isLoading,
}: IProps) => {
  return (
    <Stack>
      {inCarousel ? (
        <Stack
          mb={10}
          sx={{ svg: { display: "block" } }}
          alignItems="center"
          justifyContent="center"
          height={24}
          width={24}
        >
          <SayIcon name="NewFileFilled" size="20" />
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" gap={1} mb={2}>
          <Stack maxWidth={["unset", "130px", "unset"]}>
            <Text
              variant="subtitle2"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Shareholder feedback
            </Text>
          </Stack>
          <TooltipInfoIcon
            title="Shareholders fill out a survey sent by Say about your event."
            iconColor={COLORS.BLACK}
          />
        </Stack>
      )}
      <Text mb={1} variant="h4">
        {isLoading ? undefined : formatAsPercentage(csat)}
      </Text>
      <Stack mb={6} direction="row" alignItems="center" gap={1}>
        <Text variant="body2">CSAT score</Text>
        <TooltipInfoIcon
          title="Customer Satisfaction (CSAT) shows the number of satisfied and very satisfied shareholders, out of all shareholders who responded."
          iconColor={COLORS.BLACK}
        />
      </Stack>
      <Text mb={1} variant="h4">
        {isLoading ? undefined : formatAsPercentage(neutralPlus)}
      </Text>
      <Text variant="body2">
        of shareholders rated the event neutral and above
      </Text>
      <Stack mt={10} direction="row">
        <Stack gap={1} mr={12}>
          <Text variant="body2" emphasis="medium">
            Replies
          </Text>
          <Text variant="body2">
            {isLoading ? undefined : nFormatter(replies)}
          </Text>
        </Stack>
        <Stack gap={1}>
          <Text variant="body2" emphasis="medium">
            Sent
          </Text>
          <Text variant="body2">
            {isLoading ? undefined : nFormatter(sent)}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ShareholderFeedbackSummaryCard;
