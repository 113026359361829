import { DotProps } from "recharts";
import { IGraphDotStats } from "../../types";

interface IActiveDot extends DotProps {
  graphEventStats?: IGraphDotStats;
  payload?: {
    name: string;
  };
  value?: number;
}

const ActiveDot = (props: IActiveDot) => {
  const { cx, cy, fill, graphEventStats, value } = props;

  // dont display dot if there is already a custom dot on this location
  if (
    typeof value === "undefined" ||
    value === null ||
    (props?.payload?.name && graphEventStats?.[props?.payload?.name]?.type)
  ) {
    return null;
  }
  return (
    <circle data-testid="active-dot-circle" cx={cx} cy={cy} r="4" fill={fill} />
  );
};

export default ActiveDot;
