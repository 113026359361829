import { Box, Stack } from "@mui/material";
import { TrendingUp } from "@mui/icons-material";
import {
  COLORS,
  LoadingSkeleton,
  nFormatter,
  Text,
} from "@asayinc/component-library";
import LineChart from "../LineChart";
import { Stat } from "../../../../../../../../../../components/Messages/";
// types
import { PointData } from "../../../types";

interface IProps {
  isLoading: boolean;
  title: string;
  tooltipText: string;
  votedCount: string;
  total: number;
  chartData: PointData[];
  badgeText: string;
  lineColor: string;
}

const VotingStat = ({
  isLoading,
  title,
  tooltipText,
  votedCount,
  total,
  chartData,
  badgeText,
  lineColor,
}: IProps) => {
  const percentage =
    !!votedCount && !!total
      ? ((parseFloat(votedCount) / total) * 100).toFixed(2)
      : "0";
  return (
    <Stack
      alignItems="flex-start"
      p={8}
      borderRadius={2}
      border={`1px solid ${COLORS.P_OUTLINE}`}
    >
      <Stat
        isLoading={isLoading}
        title={title}
        tooltipText={tooltipText}
        largeStat={nFormatter(parseFloat(votedCount))}
        subStat={`/ ${nFormatter(total)} (${percentage}%)`}
      />
      {isLoading ? (
        <LoadingSkeleton sx={{ width: "132px", height: "24px", mt: 3 }} />
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          mt={3}
          px={3}
          py={1.5}
          spacing={1}
          bgcolor="#EAF6ED"
          borderRadius={4}
        >
          <TrendingUp
            sx={{ width: "16px", height: "16px", color: "#29886B" }}
          />
          <Text variant="body2" sx={{ color: "#29886B" }}>
            {badgeText}
          </Text>
        </Stack>
      )}
      <Box mt={6.5}>
        {isLoading ? (
          <LoadingSkeleton sx={{ width: "232px", height: "64px" }} />
        ) : (
          <LineChart data={chartData} lineColor={lineColor} />
        )}
      </Box>
    </Stack>
  );
};

export default VotingStat;
