import { Outlet } from "react-router-dom";
import {
  ScheduleShareholderMessage,
  TestEmailDialog,
  ConfirmEditDialog,
} from "../../Organisms";
import { FeatureIntroDialog } from "../../../Common";
import { UiEventActions } from "../../../../store/consumerUser/types";
import { useFeaturesDisabled } from "../../../../hooks/useFeaturesDisabled";

const MessagesLayout = () => {
  const { disabledFeatures, isSingleFeature } = useFeaturesDisabled();
  return (
    <>
      <Outlet />
      <FeatureIntroDialog
        img={`${process.env.REACT_APP_ASSETS}images/ir-portal/ip/messages_dialog_illustration.svg`}
        imgAlt="Messages intro"
        title="Introducing Messages"
        description="Share your company's story directly with retail shareholders using Messages - let shareholders know about product launches, partnership announcements, events and more, right to their inbox."
        url="/messages"
        fieldName="portalMessagesModalUpsell"
        action={UiEventActions.CloseModalUpsellMessages}
        conditional={isSingleFeature && disabledFeatures.includes("messages")}
      />
      <ScheduleShareholderMessage />
      <TestEmailDialog />
      <ConfirmEditDialog />
    </>
  );
};

export default MessagesLayout;
