import { Box, Stack } from "@mui/material";
import { SayIcon, Text, Tooltip, nFormatter } from "@asayinc/component-library";
import { mapSentimentToWords } from "../../../../utils/sentiment";

interface IProps {
  sentiment?: number;
  includeIcon?: boolean;
}

const Sentiment = ({ sentiment, includeIcon = false }: IProps) => {
  const { text, color } = mapSentimentToWords(sentiment);

  return (
    <Tooltip
      wrap={false}
      title={
        text
          ? `The AI-generated sentiment score is ${text.toLowerCase()} (${nFormatter(
              sentiment as number
            )}), based on the tone of the questions asked about this topic.`
          : ""
      }
    >
      <Stack direction="row" component="span">
        {includeIcon && color && (
          <Box mr={1} height="20px">
            <SayIcon size="14px" name="Sparkles" color={color} />
          </Box>
        )}
        <Text
          variant="subtitle2"
          sx={{ color }}
          component="span"
          loadingProps={{ sx: { width: "140px" } }}
        >
          {text}
        </Text>
      </Stack>
    </Tooltip>
  );
};

export default Sentiment;
