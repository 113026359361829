import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
// components
import {
  Summary,
  TrendingTopics,
  QuestionsTable,
  ShareholderFeedback,
} from "./components";
// data
import { useGetEventQuery, initialState } from "../../../../store/event";
import { useGetSettingsQuery } from "../../../../store/settings";
// types
import { ISettings } from "../../../../store/settings/types";
import { track } from "../../../../analytics";
import { useEffect } from "react";
import { EventStatusOptions } from "../../../../types/Events";

const Overview = () => {
  const { eventSlug } = useParams() as {
    eventSlug: string;
  };
  const { data: event = initialState, isSuccess } = useGetEventQuery(eventSlug);
  const { status, slug } = event;

  const { data: settings = {}, isSuccess: isCompanySuccess } =
    useGetSettingsQuery();
  const { name: companyName } = settings as ISettings;

  useEffect(() => {
    if (isSuccess && isCompanySuccess) {
      track({
        name: "Q&A Event Selected",
        client: companyName,
        qaEvent: slug,
        eventStatus: status,
        menuTab: "Overview",
      });
    }
  }, [isSuccess, isCompanySuccess]);

  // is active or ended status
  const activeOrEnded =
    event.status === EventStatusOptions.ACTIVE ||
    event.status === EventStatusOptions.COMPLETED;

  return (
    <Stack gap={4}>
      <Summary />
      <TrendingTopics />
      {activeOrEnded && <ShareholderFeedback slug={eventSlug} />}
      <QuestionsTable />
    </Stack>
  );
};

export default Overview;
