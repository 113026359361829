import { useCallback, useEffect, useState } from "react";
// material
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Box, Stack } from "@mui/material";
// components
import QAInsightsLine from "./QAInsightsLine";
import {
  COLORS,
  ChipV2 as Chip,
  IconButton,
  SayIcon,
  Text,
  Tooltip,
} from "@asayinc/component-library";
// api
import {
  useExportSentimentDataMutation,
  useGetSentimentTrendsQuery,
} from "../../../../../store/eventStats";
import { useSuccessErrorSnacks } from "../../../../../hooks/useSuccessErrorSnacks";
import { AI_TOOLTIP_TITLE } from "../../../../../constants/text";

const QAInsights = (): JSX.Element => {
  useEffect(() => {
    document.title = "Events | Say Issuer Portal";
  }, []);

  const [activeLine, setActiveLine] = useState<string | null>(null);

  const [
    getExportedSentimentData,
    { isSuccess: isExportSuccess, isError: isExportError },
  ] = useExportSentimentDataMutation();

  const { data, isSuccess } = useGetSentimentTrendsQuery();

  useSuccessErrorSnacks({
    isSuccess: isExportSuccess,
    isError: isExportError,
    successMsg: `Event sentiment data exported!`,
    errorMsg: "Failed to parse CSV data",
  });

  /**
   * call api to download the csv
   */
  const exportSentimentData = () => {
    getExportedSentimentData();
  };

  const clearActiveLine = useCallback(() => {
    setActiveLine(null);
  }, [setActiveLine]);

  return (
    <>
      <Stack maxWidth={1440} m={"0 auto"} height="100%" gap={2}>
        <Stack
          mb={8}
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Text variant="subtitle2">
              Popular Topics and Sentiment Over Time
            </Text>
            <Tooltip title={AI_TOOLTIP_TITLE}>
              <Chip
                icon={
                  <SayIcon
                    name="Sparkles"
                    size="14px"
                    color={COLORS.SAY_COBALT}
                  />
                }
                variant="secondary"
                selected={true}
                label="Powered by AI"
                sx={{ ml: 2.5 }}
              />
            </Tooltip>
          </Stack>
          <IconButton
            onClick={exportSentimentData}
            sx={{ alignSelf: "flex-start" }}
            data-testid="sentiment-download-button"
          >
            <SaveAltIcon />
          </IconButton>
        </Stack>
        <Box
          height="550px"
          sx={{ svg: { overflow: "visible" } }}
          onMouseOut={clearActiveLine}
        >
          <QAInsightsLine
            graphData={data?.graphData || []}
            legendData={data?.legendData || []}
            isSuccess={isSuccess}
            activeLine={activeLine}
            setActiveLine={setActiveLine}
            clearActiveLine={clearActiveLine}
          />
        </Box>
      </Stack>
    </>
  );
};

export default QAInsights;
