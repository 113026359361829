export enum GraphIconType {
  Additional = "additional_mailing",
  ProxyMessage = "proxy_message",
  CalendarEvent = "calendar_event",
}

export interface IGraphDotStat {
  shares?: number;
  shareholders?: number;
  range?: string;
  type: GraphIconType;
  name: string;
  fullDate?: string;
}

export interface IGraphDotStats {
  [key: string]: IGraphDotStat;
}
