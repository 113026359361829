import { Card, Text, nFormatter } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { TooltipProps } from "recharts";
import { mapSentimentToWords } from "../../../../../utils/sentiment";

interface IProps extends TooltipProps<number, string> {
  activeId: string | null;
}

const CustomTooltip = ({ active, payload, label, activeId }: IProps) => {
  // reverse array to put "Shares" before "Shareholders"
  const sortedPayload = [...(payload || [])].sort(
    (a, b) => (b.value || 1) - (a.value || 1)
  );

  const filteredPayload = activeId
    ? sortedPayload.filter((i) => i.name === activeId)
    : sortedPayload;
  if (active && sortedPayload && sortedPayload.length) {
    return (
      <Card
        data-testid="graph-tooltip"
        elevation={8}
        sx={{ px: 4, py: 3, borderRadius: 2 }}
      >
        <Text variant="body3" emphasis="medium_high" mb={1.5}>
          {label}
        </Text>
        <Stack gap={2}>
          {filteredPayload.map((itm) => (
            <Stack key={itm.name} direction="row" alignItems="center">
              <Box
                bgcolor={itm.color}
                width={8}
                height={8}
                borderRadius="100%"
                mr={2}
              />
              <Stack>
                <Text variant="subtitle3">{itm.dataKey}</Text>{" "}
                <Text variant="body3">
                  {nFormatter(Math.round(itm.value || 0))}
                  {" | "}
                  {mapSentimentToWords(itm.value || 0).text}
                </Text>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Card>
    );
  }

  return null;
};

export default CustomTooltip;
