import { CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Row,
  ColumnType,
  CustomTypographyProps,
  nFormatter,
} from "@asayinc/component-library";
import { CreateQuestionRowData } from "./types";
import { SORT } from "../../../../constants";
import { createColumnData } from "../../../../utils";
import { ITrendingCategory } from "../../../../store/sentiment";
import { Sentiment } from "../../../../components/Common";

/**
 * factory for generating row data
 * @param rowData key for each column
 * @param data object returned with callback functions
 * @param id id of the row
 */
const valign = "top";

export const createRowData = ({
  name,
  sharesOwned,
  sentiment,
  bestQuestion,
  checked,
  id,
  data,
  checkRow,
  // areRowsSelected,
  highlighted,
}: CreateQuestionRowData): Row => ({
  rowData: {
    checkbox: {
      popoverMenuCellData: {
        selected: checked,
        handleCheckboxClick: checkRow,
        menuItems: [
          {
            id: "check",
            icon: <CheckBoxOutlineBlank />,
            onClick: checkRow,
          },
        ],
      },
      checked: checked,
    },
    name: { content: name, valign },
    sharesOwned: { content: nFormatter(sharesOwned), valign },
    sentiment: {
      asHTML: true,
      content: (<Sentiment sentiment={sentiment} />) as unknown as string,
      valign,
    },
    bestQuestion: { content: bestQuestion, valign },
  },
  data,
  id,
  highlighted,
});

interface ColumnDataProps {
  goToTopic: (data: unknown) => void;
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
}

const tdHeadProps = {
  textProps: { variant: "body3", weight: "normal" } as CustomTypographyProps,
};

/**
 * Column data for table
 */
export const getColumnData = ({
  goToTopic,
  getSort,
  sortFn,
}: ColumnDataProps): {
  checkbox: ColumnType;
  name: ColumnType;
  sharesOwned: ColumnType;
  sentiment: ColumnType;
  bestQuestion: ColumnType;
} => ({
  checkbox: createColumnData({
    title: "",
    style: "popoverMenu",
    width: "36px",
  }),
  name: createColumnData({
    title: "Topic",
    style: "text",
    width: "200px",
    clickHandler: goToTopic,
    sort: getSort(SORT.name),
    sortFn,
    align: "left",
    tdHeadProps,
    tdBodyProps: {
      textProps: {
        variant: "subtitle2",
      },
    },
  }),
  sharesOwned: createColumnData({
    title: "Shares represented",
    style: "text",
    width: "200px",
    clickHandler: goToTopic,
    sort: getSort(SORT.sharesOwned),
    sortFn,
    align: "left",
    tdHeadProps,
  }),
  sentiment: createColumnData({
    title: "Sentiment",
    style: "text",
    width: "200px",
    clickHandler: goToTopic,
    sort: getSort(SORT.sentiment),
    sortFn,
    align: "left",
    tdHeadProps,
  }),
  bestQuestion: createColumnData({
    title: "Top question",
    style: "text",
    width: "200px",
    align: "left",
    tdHeadProps,
  }),
});

interface RowDataProps {
  topics: ITrendingCategory[];
  selectedIds: string[];
  checkRow: (data: unknown) => void;
  drawerTopicId: string | null;
}

/**
 * Each rows specific data for table
 */
export const getRowData = ({
  topics,
  selectedIds,
  checkRow,
  drawerTopicId,
}: RowDataProps): Row[] =>
  topics.map(({ id, name, sharesOwned, sentiment, bestQuestion }, idx) =>
    createRowData({
      name,
      sharesOwned,
      sentiment,
      bestQuestion,
      areRowsSelected: selectedIds.length > 0,
      checked: selectedIds.includes(id),
      id: id,
      data: {
        id: id,
        idx,
        checked: selectedIds.includes(id),
      },
      checkRow,
      idx,
      highlighted: drawerTopicId === id,
    })
  );
