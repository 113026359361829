import { EventSortOptions } from "../types/Events";

export enum SORT {
  sharesUpvoted = "shares_upvoted",
  asked = "num_questions_asked",
  answered = "num_questions_answered",
  shares = "shares_owned",
  votes = "upvote_count",
  date = "created",
  sharesOwnedAtUpvote = "shares_owned_at_upvote",
  investorType = "investor_type",
  upvotedAt = "upvoted_at",
  shareholder = "name",
  upvoted = "num_upvotes",
  lastParticipated = "last_participated",
  link = "link",
  clickPercent = "share_of_total_clicks",
  totalClicks = "totalClicks",
  voted = "voted",
  count = "count",
  reply = "value",
  totalReplyPercent = "share_of_total_responses",
  totalReplies = "total_replies",
  opened = "opened_at",
  repliedWith = "replied_with",
  replied = "replied",
  clicked = "links_clicked_count",
  campaignName = "campaign_name",
  deliveredCount = "delivered_count",
  scheduledSendAt = "scheduled_send_at",
  openedRate = "opened_rate",
  clickedRate = "clicked_rate",
  repliedRate = "replied_rate",
  sentDate = "sent_date",
  isQuestionAnswered = "answered",
  ticker = "ticker",
  location = "location",
  firstName = "first_name",
  shortTitle = "short_title",
  startTime = "event_start_datetime",
  totalQuestions = "total_questions",
  answeredCount = "answered_count",
  votedCount = "voted_count",
  totalShareholders = "total_shareholders",
  totalShares = "total_shares",
  feedbackEmoji = "feedback_emoji",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  created = "created",
  // topics table
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  sharesOwned = "shares_owned",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  name = "name",
  sentiment = "sentiment",
}

export const FLIP_SORT = {
  asc: "desc",
  desc: "asc",
};

export enum SORT_SYMBOL {
  asc = "",
  desc = "-",
}

export const EVENT_SORT_ORDER_OPTIONS = [
  {
    label: "Recommended",
    value: EventSortOptions.RECOMMENDED,
    tooltip:
      "The Recommended sort option allows shareholders to view Q&A questions in an order determined by Say’s algorithm. Say’s algorithm takes time and upvote rate into consideration, allowing for more questions to be viewed and upvoted by shareholders, resulting in the top voted questions having the highest upvote rate.",
  },
  {
    label: "Top Voted",
    value: EventSortOptions.TOP_VOTED,
  },
  {
    label: "Most Shares",
    value: EventSortOptions.MOST_SHARES,
  },
  {
    label: "Newest",
    value: EventSortOptions.NEWEST,
  },
];

/**
 * value label mapping required for select to display data properly
 */
export const EVENT_SORT_LABEL_MAP = EVENT_SORT_ORDER_OPTIONS.reduce(
  (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
  {}
);
