import {
  Checkbox,
  Dialog,
  IButtonAction,
  IconButton,
  Text,
  TooltipInfoIcon,
} from "@asayinc/component-library";
import CloseIcon from "@mui/icons-material/Close";
import { Box, FormControlLabel, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useRequestMoreInfo } from "../../../../../hooks";
import { UiEventActions } from "../../../../../store/consumerUser/types";

interface IProps {
  open: boolean;
  toggleDialog: () => void;
}

const NoboDialog = ({ open, toggleDialog }: IProps) => {
  // track request more info
  const { requestMoreInfo, isSuccess, isLoading } = useRequestMoreInfo({
    action: UiEventActions.AcceptNobo,
    autoHideDuration: 7000,
    successMsg:
      "We've received your authorization submission! Now that you have granted NOBO access, you'll see up-to-date shareholder analytics below in 7 business days. You’ll receive an email from Say once this is available.",
  });
  const [checked, setChecked] = useState(false);
  const onClose = () => {
    toggleDialog();
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onSubmit = () => {
    requestMoreInfo();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  const buttonActions = [
    {
      onClick: onSubmit,
      disabled: !checked || isLoading,
      label: "Submit for review",
      size: "small",
      variant: "contained",
    },
    {
      onClick: onClose,
      label: "Cancel",
      disabled: isLoading,
      variant: "secondary",
      size: "small",
    },
  ] as IButtonAction[];

  return (
    <Dialog
      customTitle={
        <Stack
          sx={{ margin: 0, p: 8, pb: 0 }}
          direction="row"
          alignItems="center"
          gap={2}
        >
          <Text variant="subtitle1">View shareholder analytics on Say</Text>
          <TooltipInfoIcon
            title="Allow Say to access NOBO List information to see daily shareholder analytics"
            iconColor="black"
            tooltipColor="black"
          />
          <IconButton
            aria-label="close"
            sx={{
              right: (theme) => theme.spacing(3),
              top: (theme) => theme.spacing(3),
              position: "absolute",
            }}
            data-testid="dialog-close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      }
      content={
        <Stack maxWidth="570px" width="100%">
          <Text variant="body1">
            Authorize Say to request a list of Non-Objecting Beneficial Owners
            and related information (&quot;NOBO List&quot;) on behalf of my
            company as its agent and attorney-in-fact for the limited purposes
            of providing and/or improving the Services under my company&apos;s
            existing agreement with Say for Shareholder Q&A Services (the
            &quot;Agreement&quot;). By providing this authorization to Say, I
            understand that I will be able to view the shareholder data daily.
            Notwithstanding any terms in the Agreement that require the parties
            to agree in writing for any amendments to be effective, I understand
            that this authorization has the effect of amending the Agreement to
            provide Say with this authorization until the Agreement is
            terminated in accordance with its terms.
          </Text>
          <Box ml={2} mt={5.5}>
            <FormControlLabel
              sx={{
                ".MuiTypography-root": { fontSize: "14px" },
              }}
              control={
                <Checkbox
                  name="nobo"
                  id="nobo"
                  data-testid="nobo-checkbox"
                  disabled={isLoading}
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
              }
              label={`I authorize Say to request a "NOBO List" on behalf of my company`}
            />
          </Box>
        </Stack>
      }
      open={open}
      buttonActions={buttonActions}
      handleClose={onClose}
    />
  );
};

export default NoboDialog;
