export const TabOptions = {
  overview: {
    label: "Overview",
    value: "overview",
  },
  participants: {
    label: "Participants",
    value: "participants",
  },
  eventInfo: {
    label: "Event info",
    value: "event-info",
  },
};

export const tabs = [
  TabOptions.overview,
  TabOptions.participants,
  TabOptions.eventInfo,
];
