import { COLORS } from "@asayinc/component-library";

/**
 * Map sentiment score to color / text
 * 0-25
 * 25.1-45
 * 45.1-54.9
 * 55-74.9
 * 75-100
 */
export const mapSentimentToWords = (score?: number) => {
  // handle loading state
  if (typeof score === "undefined") {
    return { text: undefined, color: undefined };
  }

  if (score <= 25) {
    return { text: "Negative", color: COLORS.SATURATED_RED };
  } else if (score <= 45) {
    return { text: "Slightly negative", color: COLORS.SATURATED_RED };
  } else if (score <= 54.99) {
    return { text: "Neutral", color: COLORS.SATURATED_ORANGE };
  } else if (score <= 74.99) {
    return { text: "Slightly positive", color: COLORS.SATURATED_GREEN };
  }
  return { text: "Positive", color: COLORS.SATURATED_GREEN };
};
