import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FilterSelectionFlow, IFilter } from "@asayinc/component-library";
import { validateParam } from "../../../../utils";
import { URL_PARAMS } from "../../../../constants";

interface IProps {
  options: {
    name: string;
    value: string;
    id: string;
  }[];
  optionsLimit?: number;
  chipLabel: string;
  fieldName: string;
  filterParam: string;
  isLoading: boolean;
  disabled?: boolean;
  groupLabel: string;
}

/**
 * Uses FilterSelectionFlow from component library to update
 * URL search params according to param props. Requires form
 * context in parent or ancestor component.
 */

const AutocompleteFilter = ({
  options,
  optionsLimit,
  chipLabel,
  fieldName,
  disabled,
  filterParam,
  isLoading,
  groupLabel,
}: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [firstRender, setFirstRender] = useState(true);
  const methods = useFormContext();
  const { watch, setValue } = methods;
  const formValues = watch(fieldName);

  /**
   * set formValues on page load
   */
  useEffect(() => {
    const valueIdsString: string = searchParams.get(filterParam) || "";

    // use URL_PARAMS.tags validation
    const validatedValue = validateParam(URL_PARAMS.tags, valueIdsString);
    // update query params if value changed during validation
    if (validatedValue !== valueIdsString) {
      searchParams.set(filterParam, validatedValue);
    }
    if (validatedValue.length > 0 && options.length > 0) {
      const valueList = validatedValue.split(",");
      const pageLoadValues = options.filter((value) =>
        valueList.includes(value.id.toString())
      );
      const formVals = pageLoadValues.map((val) => val.id);
      setValue(fieldName, { values: formVals });
    }
  }, [isLoading]);

  /**
   * use rangeFormValues to update URL search params
   */
  useEffect(() => {
    // prevent running on first render to avoid duplicate history states
    if (!firstRender) {
      if (formValues) {
        const { values = [] } = formValues;

        // if values exist, set filterParam
        if (values.length) {
          const paramValue = values.join(",");
          searchParams.set(filterParam, paramValue);
        } else {
          searchParams.delete(filterParam);
        }
      }
      setSearchParams(searchParams);
    } else {
      setFirstRender(false);
    }
  }, [formValues]);

  const filterTypeOptions = [
    {
      type: "values",
      label: "Test",
      options: {
        groupLabel,
        options,
        optionsLimit,
      },
    },
  ] as IFilter[];

  const onClear = () => {
    searchParams.delete(filterParam);
    setSearchParams(searchParams);
  };

  return (
    <FilterSelectionFlow
      skipMessaging
      onClear={onClear}
      withinForm={{ fieldName }}
      clearable
      chipProps={{
        disabled,
      }}
      chipLabel={chipLabel}
      filterTypeOptions={filterTypeOptions}
    />
  );
};

export default AutocompleteFilter;
