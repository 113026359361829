import { Text } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { Sentiment } from "../../../../../../../components/Common";

interface IProps {
  title?: string;
  sentiment?: number;
}

const TrendingTopic = ({ title, sentiment }: IProps) => {
  return (
    <Box>
      <Text
        loadingProps={{ sx: { width: "100px" } }}
        variant="subtitle1"
        mb={1}
      >
        {title}
      </Text>
      <Stack direction="row" alignItems="center" gap={1}>
        <Sentiment sentiment={sentiment} includeIcon />
      </Stack>
    </Box>
  );
};

export default TrendingTopic;
