import { rootApi } from "../rootApi";
import { IAlert, IOverviewStats, ISecurityPrice } from "./types";

export const commonApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * get price of a given ticker
     */
    getSecurityPrice: builder.query<ISecurityPrice, void>({
      query: () => "security/price/",
    }),
    /**
     * get company overarching stats
     */
    getOverviewStats: builder.query<IOverviewStats, void>({
      query: () => "company-overview-stats/",
    }),
    /**
     * get list of alerts to showcase to the user
     */
    getAlerts: builder.query<IAlert[], void>({
      query: () => "alerts/",
      providesTags: ["Alerts"],
    }),
    /**
     * Remove Alert
     */
    removeAlert: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `alerts/${id}/dismiss/`,
        method: "POST",
      }),
    }),
    /**
     * Remove All Alert
     */
    removeAllAlerts: builder.mutation<void, void>({
      query: () => ({
        url: `alerts/dismiss-all/`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetSecurityPriceQuery,
  useGetOverviewStatsQuery,
  useGetAlertsQuery,
  useRemoveAlertMutation,
  useRemoveAllAlertsMutation,
} = commonApi;
