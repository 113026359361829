import { Card, Text, nFormatter } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { TooltipProps } from "recharts";
import StatPercent from "./StatPercent";

interface IProps extends TooltipProps<number, string> {
  firstValueSet: {
    "Top 50"?: number | null;
    "Top 100"?: number | null;
    "Top 500"?: number | null;
  };
}

const ShareholderTrendGraphTooltip = ({
  active,
  payload,
  label,
  firstValueSet,
}: IProps) => {
  if (active && payload && payload.length) {
    return (
      <Card
        data-testid="graph-tooltip"
        elevation={8}
        sx={{ px: 4, py: 3, borderRadius: 2 }}
      >
        <Text variant="body3" emphasis="medium_high" mb={1.5}>
          As of {label}
        </Text>
        {payload.map((item) => (
          <Stack key={item.color} direction="row" alignItems="center" mb={1}>
            <Box
              bgcolor={item.color}
              width={8}
              height={8}
              borderRadius="100%"
              mr={2}
            />
            <Stack alignItems="center" direction="row">
              <Text variant="body3" mr={1}>
                {item.name}: {nFormatter(item.value || 0)} shares
              </Text>
              <StatPercent
                value={item.value || 0}
                firstValue={firstValueSet?.[item.name || ""] || 0}
              />
            </Stack>
          </Stack>
        ))}
      </Card>
    );
  }

  return null;
};

export default ShareholderTrendGraphTooltip;
