/* istanbul ignore file */
import { router } from "../router";
import { REFRESH_TOKEN, removeAuthToken } from "@asayinc/component-library";

// clear local storage and auth token
export const logout = () => {
  removeAuthToken();
  window.localStorage.removeItem(REFRESH_TOKEN);
  router.navigate("/login", { replace: true });
};
