import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAppConfig } from "./types";
import { getBaseQueryWithCamelize } from "../apiTools";

const baseQuery = getBaseQueryWithCamelize(
  fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_CONSUMER_API}v3/`,
  })
);

/**
 * App Config api
 */

export const appConfigApi = createApi({
  reducerPath: "appConfigApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getAppConfig: builder.query<IAppConfig, void>({
      query: () => `app-config/`,
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetAppConfigQuery } = appConfigApi;
