import {
  Star,
  StarOutline,
  CheckBoxOutlineBlank,
  LabelOutlined,
  StickyNote2,
  FiberManualRecord,
} from "@mui/icons-material";
import {
  Row,
  ColumnType,
  nFormatter,
  COLORS,
  getInitials,
  DATE_FORMAT,
  CustomTypographyProps,
} from "@asayinc/component-library";
import { CreateQuestionRowData } from "./types";
import { getAuthorName } from "../../../../../../../utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { Question } from "../../../../../../../types/Questions";
import { SORT } from "../../../../../../../constants";
import { DELETED_USER_ID } from "../../../../../../../constants/settings";
import { createColumnData } from "../../../../../../../utils";

dayjs.extend(relativeTime);
dayjs.extend(utc);

/**
 * factory for generating row data
 * @param rowData key for each column
 * @param data object returned with callback functions
 * @param id id of the row
 */
const valign = "top";

export const createRowData = ({
  votes,
  sharesUpvoted,
  question,
  participant,
  category,
  initials,
  profileColor,
  date,
  starred,
  checked,
  id,
  data,
  checkRow,
  starRow,
  goToNote,
  openTagDialog,
  tags,
  notes,
  answered,
  areRowsSelected,
  shareholderTags,
  highlighted,
}: CreateQuestionRowData): Row => ({
  rowData: {
    checkbox: {
      popoverMenuCellData: {
        selected: checked,
        handleCheckboxClick: checkRow,
        menuItems: [
          // if any row is selected, we'll hide star or tag menuItems
          ...(!areRowsSelected && !starred
            ? [
                {
                  icon: <StarOutline />,
                  id: "star",
                  onClick: starRow,
                },
              ]
            : []),
          // if any row is selected, we'll hide star or tag menuItems
          ...(!areRowsSelected
            ? [
                {
                  icon: <LabelOutlined />,
                  id: "tag",
                  onClick: openTagDialog,
                },
              ]
            : []),
          // popoverMenuCells will not render menuItems when selected, so this
          // item can be added in the menuItems array without conditions
          {
            id: "check",
            icon: <CheckBoxOutlineBlank />,
            onClick: checkRow,
          },
        ],
      },
      checked: checked,
    },
    votes: { content: votes, valign },
    sharesUpvoted: { content: sharesUpvoted, valign },
    question: {
      asHTML: true,
      valign,
      content: question,
      buttons: [
        ...(starred
          ? [
              {
                id: "star",
                icon: <Star />,
                onClick: starRow,
                tooltip: "Remove from favorites",
              },
            ]
          : []),
        ...(notes?.textPlain
          ? [
              {
                id: "note",
                icon: <StickyNote2 />,
                onClick: goToNote,
                tooltip: notes.textPlain,
              },
            ]
          : []),
      ],
      tagsData: tags
        ? {
            tags,
            tagsToShow: 2,
          }
        : undefined,
    },
    participant: {
      valign,
      asHTML: true,
      userCellData: {
        isDeletedUser: data.globalUserId === DELETED_USER_ID,
        userName: participant,
        initials,
        profileColor: profileColor,
      },
      tagsData: shareholderTags
        ? {
            tags: shareholderTags,
            tagsToShow: 1,
          }
        : undefined,
    },
    category: { content: category, valign },
    date: { content: date, valign },
    answered: { content: answered, valign },
  },
  data,
  id,
  highlighted,
});

interface ColumnDataProps {
  goToQuestion: (data: unknown) => void;
  goToShareholder: (data: unknown) => void;
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
}

const tdHeadProps = {
  textProps: { variant: "body3", weight: "normal" } as CustomTypographyProps,
};

/**
 * Column data for table
 */
export const getColumnData = ({
  goToQuestion,
  goToShareholder,
  getSort,
  sortFn,
}: ColumnDataProps): {
  checkbox: ColumnType;
  votes: ColumnType;
  sharesUpvoted: ColumnType;
  question: ColumnType;
  category: ColumnType;
  participant: ColumnType;
  date: ColumnType;
  answered: ColumnType;
} => ({
  checkbox: createColumnData({
    title: "",
    style: "popoverMenu",
    width: "36px",
  }),
  votes: createColumnData({
    title: "Votes received",
    style: "text",
    width: "200px",
    clickHandler: goToQuestion,
    sort: getSort(SORT.votes),
    sortFn,
    align: "left",
    tdHeadProps,
  }),
  sharesUpvoted: createColumnData({
    title: "Shares represented",
    style: "text",
    width: "200px",
    clickHandler: goToQuestion,
    sort: getSort(SORT.sharesUpvoted),
    sortFn,
    align: "left",
    tdHeadProps,
  }),
  question: createColumnData({
    title: "Question",
    style: "text",
    clickHandler: goToQuestion,
    align: "left",
    width: "338px",
    tdHeadProps,
    tdBodyProps: {
      textProps: {
        variant: "subtitle2",
      },
    },
  }),
  category: createColumnData({
    title: "Topic",
    style: "text",
    width: "200px",
    clickHandler: goToShareholder,
    align: "left",
    tdHeadProps,
  }),
  participant: createColumnData({
    title: "Asked by",
    style: "user",
    width: "200px",
    clickHandler: goToShareholder,
    align: "left",
    tdHeadProps,
  }),
  date: createColumnData({
    title: "Submitted",
    style: "text",
    width: "200px",
    clickHandler: goToQuestion,
    sort: getSort(SORT.date),
    sortFn,
    align: "left",
    tdHeadProps,
  }),
  answered: createColumnData({
    title: "Answered",
    style: "text",
    width: "200px",
    clickHandler: goToQuestion,
    align: "left",
    tdHeadProps,
  }),
});

interface RowDataProps {
  questions: Question[];
  selectedIds: string[];
  checkRow: (data: unknown) => void;
  starRow: (data: unknown) => void;
  goToNote: (data: unknown) => void;
  openTagDialog: (data: unknown) => void;
  primaryColor: string;
  drawerQuestionId: string | null;
  drawerShareholderId: string | null;
}

/**
 * Each rows specific data for table
 */
export const getRowData = ({
  questions,
  selectedIds,
  checkRow,
  starRow,
  primaryColor,
  goToNote,
  openTagDialog,
  drawerQuestionId,
  drawerShareholderId,
}: RowDataProps): Row[] =>
  questions.map((question, idx) =>
    createRowData({
      areRowsSelected: selectedIds.length > 0,
      answered: question.status === "answered" ? "Yes" : "No",
      profileColor: question.profileColor,
      starred: question.isSelectedByIssuer,
      checked: selectedIds.includes(question.id),
      votes: nFormatter(question.countUsersUpvoted),
      sharesUpvoted: nFormatter(question.countSharesUpvoted),
      question: question.highlightedQuestionText || question.questionText,
      participant: getAuthorName({
        authorName: question.highlightedQuestionAuthor || question.authorName,
        type: question.type,
        institutionName: question.institutionName,
      }),
      category: question.specificCategory?.name || "",
      initials: getInitials(question.authorName),
      participantType: question.type,
      date: dayjs(question.dateAsked).format(DATE_FORMAT),
      note: {
        Icon: FiberManualRecord,
        color: !!question.notes?.textPlain ? primaryColor : COLORS.LIGHT_GREY,
      },
      notes: question.notes,
      id: question.id,
      data: {
        id: question.id,
        idx,
        globalShareholderId: question.globalShareholderId,
        globalUserId: question.globalUserId,
        isSelectedByIssuer: question.isSelectedByIssuer,
        checked: selectedIds.includes(question.id),
      },
      checkRow,
      tags: question.tags,
      starRow,
      goToNote,
      openTagDialog,
      idx,
      shareholderTags: question.shareholderTags,
      highlighted:
        drawerQuestionId === question.id ||
        drawerShareholderId === question.globalUserId,
    })
  );
