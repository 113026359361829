import { Box, Stack } from "@mui/material";
import {
  Text,
  nFormatter,
  TIME_FORMAT,
  ButtonV2 as Button,
} from "@asayinc/component-library";
import { StatusBadge } from "../../../../../../../components/Common";
import { EventSummaryStat } from "../Atoms";
import dayjs from "dayjs";
import { EventStatusOptions } from "../../../../../../../types/Events";
import { IEventSummary } from "../../../../../../../store/eventStats";

interface IProps {
  eventSummary: IEventSummary;
  isLoading: boolean;
  eventStatus?: EventStatusOptions;
  eventStartDateTime?: string | null;
  eventTitle?: string;
  goToOverview: () => void;
}

const EventOverview = ({
  eventSummary,
  isLoading,
  eventStatus,
  eventStartDateTime,
  eventTitle,
  goToOverview,
}: IProps) => {
  // is active event status
  const isActive = eventStatus === EventStatusOptions.ACTIVE;

  return (
    <>
      <Stack mb={[8, 19.5]}>
        <StatusBadge messageStatus={eventStatus} />
        <Text
          mt={4}
          mb={6}
          variant="h4"
          loadingProps={{ sx: { width: "220px" } }}
        >
          {eventTitle}
        </Text>
        <Stack gap={12} direction="row">
          <Stack direction={["row", "column"]} gap={[12, 6]}>
            <EventSummaryStat
              title="Views"
              value={
                isLoading
                  ? undefined
                  : nFormatter(eventSummary.stats.uniqueViews)
              }
            />
            <EventSummaryStat
              title="Questions"
              value={
                isLoading
                  ? undefined
                  : nFormatter(eventSummary.stats.questionsAsked)
              }
            />
          </Stack>
          <Stack direction={["row", "column"]} gap={[12, 6]}>
            <EventSummaryStat
              title="Participants"
              value={
                isLoading
                  ? undefined
                  : nFormatter(eventSummary.stats.totalShareholders)
              }
            />
            <EventSummaryStat
              title={isActive ? "Event date and time" : "Answers"}
              value={
                !eventStartDateTime
                  ? undefined
                  : isActive
                  ? dayjs(eventStartDateTime).format(
                      `MMM DD [at] ${TIME_FORMAT}`
                    )
                  : nFormatter(eventSummary.stats.questionsAnsweredCount)
              }
            />
          </Stack>
        </Stack>
      </Stack>
      <Box display={["none", "block"]}>
        <Button
          variant="secondary"
          onClick={goToOverview}
          data-testid="button-desktop-viewdetails"
        >
          View Details
        </Button>
      </Box>
    </>
  );
};

export default EventOverview;
