import { Alert, GitInfo, isLoggedIn } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import {
  matchRoutes,
  Outlet,
  useLocation,
  ScrollRestoration,
  useMatch,
} from "react-router-dom";
import generatedGitInfo from "../../../../generatedGitInfo.json";
import { PublishSuccessDialog } from "../../Organisms";
import SideNav from "../SideNav";
import { Snackbar } from "../../Molecules";
import { PageCategories } from "../../../../constants";
import { Drawers } from "./components";
import { ExploreSaysFeaturesDialog } from "../../Organisms";
import { useIsSuperUser } from "../../../../hooks";
import { useGetSettingsQuery } from "../../../../store/settings";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ISettings } from "../../../../store/settings/types";

const GlobalLayout = (): JSX.Element => {
  const isSuperUser = useIsSuperUser();
  const isUserLoggedIn = isLoggedIn();
  const { pathname } = useLocation();
  // used to get page path as using .includes on path can be incorrect if an event name contains a keyword
  const match = useMatch(`/:pageType/*`) as {
    params: { pageType: string };
  };
  const { data: settings = {} } = useGetSettingsQuery(
    isUserLoggedIn ? undefined : skipToken
  );
  const { name: companyName = "" } = settings as ISettings;
  const pageType = match?.params?.pageType;

  const noPaddingRoutes = matchRoutes(
    [
      { path: "/settings" },
      { path: "/messages/:id/edit" },
      { path: "/messages/create" },
      { path: "/qa/create" },
      { path: "/qa/:id/edit" },
      { path: "/qa/:id/edit" },
    ],
    pathname
  );

  // store last visited page
  if (pageType === "messages") {
    window.localStorage.setItem("last-page-category", PageCategories.MESSAGES);
  } else if (pageType === "qa") {
    window.localStorage.setItem("last-page-category", PageCategories.QA);
  } else if (pageType === "shareholders") {
    window.localStorage.setItem(
      "last-page-category",
      PageCategories.SHAREHOLDERS
    );
  } else if (pageType === "vote") {
    window.localStorage.setItem("last-page-category", PageCategories.PROXY);
  } else if (pageType === "settings") {
    window.localStorage.setItem("last-page-category", PageCategories.SETTINGS);
  }

  return (
    <Box minHeight="100vh">
      {isSuperUser && process.env.REACT_APP_ENV === "production" && (
        <Alert
          alertProps={{ severity: "warning" }}
          message={`Warning! You are on ${companyName}'s account on production, do not make any changes to client accounts.`}
          sx={{ width: "100%", justifyContent: "center", height: "150px" }}
          defaultCloseIcon={false}
        />
      )}
      {/* only restore scroll for pathname changes not query param */}
      <ScrollRestoration getKey={(location) => location.pathname} />
      <Stack flexDirection="row">
        {isUserLoggedIn && <SideNav />}
        <Box
          minWidth={600}
          m={"0 auto"}
          sx={{
            flex: 1,
            pb: !!noPaddingRoutes ? 0 : 10,
          }}
        >
          <GitInfo gitInfo={generatedGitInfo} />
          <PublishSuccessDialog />
          <ExploreSaysFeaturesDialog />
          <Drawers />
          <Outlet />
        </Box>
      </Stack>
      <Snackbar />
    </Box>
  );
};

export default GlobalLayout;
