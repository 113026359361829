import { COLORS } from "@asayinc/component-library";

export const COLOR_OPTIONS = [
  COLORS.DATA_VIZ_AZURE,
  COLORS.DATA_VIZ_PINK,
  COLORS.DATA_VIZ_BLUE,
  COLORS.DATA_VIZ_GOLD,
  COLORS.DATA_VIZ_PURPLE,
  COLORS.DATA_VIZ_DARK_GREEN,
  COLORS.DATA_VIZ_GIGAS,
  COLORS.DATA_VIZ_RED,
  COLORS.DATA_VIZ_BLUE_VIOLET,
  COLORS.DATA_VIZ_SUSHI,
];
