/* istanbul ignore file */
import { useEffect, useState } from "react";
// material
import { Stack } from "@mui/material";
// components
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { COLORS, Checkbox, Text, nFormatter } from "@asayinc/component-library";
import { ContentType } from "recharts/types/component/DefaultLegendContent";
import CustomTooltip from "./CustomTooltip";
import { XAxisTick, YAxisTick } from "../../../../../components/Graphs";
import { ILegendData } from "../../../../../store/eventStats";

interface IProps {
  graphData: {
    [key: string]: number | string;
    name: string;
  }[];
  legendData: ILegendData[];
  isSuccess: boolean;
  activeLine: string | null;
  setActiveLine: (id: string) => void;
  clearActiveLine: () => void;
}

const QAInsightsLine = ({
  graphData,
  legendData,
  isSuccess,
  activeLine,
  setActiveLine,
  clearActiveLine,
}: IProps): JSX.Element => {
  const [legendValues, setLegendValues] = useState({});

  useEffect(() => {
    document.title = "Events | Say Issuer Portal";
  }, []);

  // set all legend values to true to start
  useEffect(() => {
    if (isSuccess) {
      setLegendValues(
        legendData.reduce((acc, item) => {
          acc[item.id] = true;
          return acc;
        }, {})
      );
    }
  }, [isSuccess]);

  // toggle a legend value
  const toggleLegend = (id: string) => {
    setLegendValues((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // get line data based on legend selections
  const lineData = activeLine
    ? [...legendData].sort((a, b) =>
        a.id === activeLine ? 1 : b.id === activeLine ? -1 : 0
      )
    : legendData;

  interface IPayload extends ILegendData {
    value: string;
  }

  const renderFullLegend: ContentType = (props) => {
    const { payload } = props as { payload: IPayload[] };
    return (
      <Stack
        width={200}
        mr={10}
        minHeight={460}
        borderRight={`1px solid ${COLORS.P_OUTLINE}`}
      >
        <ul>
          {payload?.map((entry, index) => (
            <li key={`item-${index}`} style={{ marginBottom: "8px" }}>
              <Stack
                alignItems="center"
                direction="row"
                gap={4}
                sx={{
                  cursor: "pointer",
                  ".MuiCheckbox-root": {
                    color: COLORS.P_OUTLINE,
                    "&.Mui-checked": {
                      color: entry.color,
                    },
                  },
                }}
                onMouseOver={
                  legendValues?.[entry.id as string]
                    ? () => setActiveLine(entry.id as string)
                    : undefined
                }
                onMouseOut={clearActiveLine}
                onClick={() => toggleLegend(entry.id as string)}
              >
                <Checkbox checked={entry.id && legendValues[entry.id]} />

                <Stack>
                  <Text
                    variant="subtitle2"
                    component="span"
                    data-testid={`legend-${entry.value}`}
                    textTransform="capitalize"
                  >
                    {entry.value}
                  </Text>
                  <Text variant="body3" emphasis="medium">
                    {nFormatter(entry.count)} questions
                  </Text>
                </Stack>
              </Stack>
            </li>
          ))}
        </ul>
      </Stack>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="95%">
      <LineChart
        data={graphData}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
        key={graphData?.length}
      >
        <Legend
          iconType="circle"
          layout="vertical"
          margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
          align="left"
          payload={legendData.map((item) => ({ ...item, value: item.display }))}
          content={renderFullLegend}
          verticalAlign="top"
        />

        <Tooltip
          content={<CustomTooltip activeId={activeLine} />}
          wrapperStyle={{ left: -90 }}
        />
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          onMouseOut={clearActiveLine}
          onMouseLeave={clearActiveLine}
        />
        <XAxis
          dataKey="name"
          padding={{ left: 20, right: 0 }}
          axisLine={false}
          tickLine={true}
          tick={<XAxisTick isLoading={false} />}
          tickMargin={7}
          tickCount={6}
          interval={0}
        />
        {lineData
          .filter((i) => legendValues[i.id])
          .map((item) => (
            <Line
              key={item.id}
              yAxisId="left"
              strokeWidth={3}
              isAnimationActive={false}
              type="linear"
              dataKey={item.id}
              stroke={
                activeLine === null || activeLine === item.id
                  ? item.color
                  : "#E0E0E0"
              }
            />
          ))}
        <YAxis
          yAxisId="left"
          orientation="left"
          tickMargin={12}
          type="number"
          tickCount={10}
          tickLine={false}
          label={{
            value: "Sentiment Score",
            angle: -90,
            position: "middle",
            dx: -25,
          }}
          onMouseOver={clearActiveLine}
          tick={
            <YAxisTick
              color={COLORS.P_MED_LOW_EMPHASIS}
              anchor="end"
              isLoading={false}
              rightTick={true}
            />
          }
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          axisLine={false}
          domain={[0, 100]}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default QAInsightsLine;
