import { Select } from "@asayinc/component-library";
import FilterListIcon from "@mui/icons-material/FilterList";
import { SelectChangeEvent } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import { TOP_SHAREHOLDER_LABEL_MAPPING } from "../../../../../../constants";

const options = [
  { label: "Top 50", value: "50" },
  { label: "Top 100", value: "100" },
  { label: "Top 500", value: "500" },
  { label: "Top 1%", value: "p99" },
  { label: "Top 10%", value: "p90" },
  { label: "Top 25%", value: "p75" },
];

const mapping = options.reduce(
  (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
  {}
);

interface IProps {
  isLoading?: boolean;
  fieldPath: string;
  disabled?: boolean;
}

const TopShareholderFilter = ({ fieldPath, isLoading, disabled }: IProps) => {
  const value = useWatch({ name: `${fieldPath}.radio` });
  const { setValue } = useFormContext();

  // clear out field
  const onClear = () => {
    setValue(fieldPath, undefined, {
      shouldDirty: true,
    });
  };

  // update form on change
  const onChange = (e: SelectChangeEvent<unknown>) => {
    setValue(`${fieldPath}.radio`, e.target.value, {
      shouldDirty: true,
    });
  };

  // Filtering has a BE limitation of now allowed undefined values
  // react-hook-form by default instantiates undefined when using hook components
  // instead of manually sifting through all the data and clearing undefined, sticking with
  // non-hook components and ensuring be doesnt receive what they consider bad data
  return (
    <Select
      chipProps={{
        variant: "secondary",

        icon: <FilterListIcon fontSize="small" />,
        onDelete: value ? onClear : undefined,
        disabled: isLoading || disabled,
      }}
      name={fieldPath}
      id={fieldPath}
      value={value}
      customDisplayValue={
        value
          ? `Shareholders in ${TOP_SHAREHOLDER_LABEL_MAPPING[
              value
            ].toLowerCase()}`
          : "Top shareholders"
      }
      onChange={onChange}
      data-testid={`select-${fieldPath}`}
      valueMapping={mapping}
      clearable
      asChip
      options={options}
    />
  );
};

export default TopShareholderFilter;
