import { useEffect } from "react";
// material
import { Stack } from "@mui/material";
// say
import { Text } from "@asayinc/component-library";
// redux / data
import { useHasAnEvent } from "./hooks";
// components
import { ListHeader } from "../../components/Common";
import {
  AllEventsTable,
  EventSummaryCards,
  EventsListContainer,
  NoEvents,
} from "./components";
// types
import { useRequestMoreInfo } from "../../hooks";
import { CollapsingAlert } from "../../components/Messages";
import { useGetFeaturesQuery } from "../../store/settings";
import { UiEventActions } from "../../store/consumerUser/types";

const Events = (): JSX.Element => {
  const {
    inProgressOrActiveCount,
    eventSummarySlugs,
    hasInProgressEvent,
    hasAnEvent,
    loading,
  } = useHasAnEvent();

  const { data: features, isSuccess: isFeaturesSuccess } =
    useGetFeaturesQuery();
  // track request more info
  const { requestMoreInfo } = useRequestMoreInfo({
    action: UiEventActions.RequestInfoQA,
  });

  useEffect(() => {
    document.title = "Events | Say Issuer Portal";
  }, []);

  const alertActions = [
    {
      label: "Request more info",
      onClick: requestMoreInfo,
    },
  ];

  return (
    <>
      <Stack px={10} maxWidth={1440} m={"0 auto"} height="100%">
        <ListHeader
          title="Q&As"
          buttonProps={{
            url: "/qa/create",
            text: "New Q&A",
          }}
          displayCta={hasAnEvent && features?.qAndA}
        />
        {isFeaturesSuccess && !features?.qAndA && (
          <CollapsingAlert
            showAlert={true}
            actions={alertActions}
            showNotificationIcon={false}
            defaultCloseIcon={false}
            message="Expand your Say feature set to engage shareholders in Q&A events."
            sx={{
              width: "100%",
              px: "32px !important",
              mb: 8,
            }}
          />
        )}

        {hasAnEvent || loading ? (
          <>
            {/* has a summary slug or an inprogress event */}
            {(eventSummarySlugs.length || hasInProgressEvent) && (
              <Text
                variant="subtitle1"
                loadingProps={{ sx: { width: "80px" } }}
                mb={6}
              >
                {loading ? undefined : `Recent (${inProgressOrActiveCount})`}
              </Text>
            )}
            {/* if summary slugs exist show summary cards */}
            {!!eventSummarySlugs.length && (
              <EventSummaryCards allEventSlugs={eventSummarySlugs} />
            )}
            {/* render draft events container for loading state */}
            {(hasInProgressEvent || loading) && (
              <EventsListContainer showLoadingState={loading} />
            )}
            <AllEventsTable />
          </>
        ) : (
          <NoEvents featureEnabled={features?.qAndA} />
        )}
      </Stack>
    </>
  );
};

export default Events;
