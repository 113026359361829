import { INIT_FEATURES_STATE, useGetFeaturesQuery } from "../../store/settings";

// check if shareholder feature is enabled
export const useIsShareholdersEnabled = () => {
  // check if feature is enabled
  const {
    data: features = INIT_FEATURES_STATE,
    isLoading: isGetFeaturesLoading,
    isSuccess: isGetFeaturesSuccess,
  } = useGetFeaturesQuery();
  const { shareholders } = features;

  const isFeatureEnabled = !!shareholders;
  // end enabled check
  const isFeatureEnabledLoading = !!isGetFeaturesLoading;

  return { isFeatureEnabled, isFeatureEnabledLoading, isGetFeaturesSuccess };
};
