// Unfortunately swiper isnt set up to be entirely testable in reach so added
// istanbul ignore to the functions that wont be reached in testing

import { Box, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperClass, SwiperProps } from "swiper/react";
import { Navigation, Mousewheel, Keyboard } from "swiper/modules";

import "swiper/css/navigation";
import CarouselArrow from "./CarouselArrow";
import { Loader } from "@asayinc/component-library";

interface IProps {
  children: React.ReactNode;
  isFetching?: boolean;
  loadMore?: () => void;
  slidesPerClick?: number;
  isLoading?: boolean;
}

// Carousel wrapper component levereging Swiper JS
const Carousel = ({
  children,
  isFetching,
  loadMore,
  slidesPerClick = 2,
  isLoading,
}: IProps) => {
  const swiperRef = useRef<SwiperClass>();

  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);

  // manually set first and last slide so we can use custom components for arrows
  /* istanbul ignore next */
  const onSlideChange = (swiper: SwiperClass) => {
    if (swiper.isBeginning) {
      setIsFirstSlide(true);
    } else {
      setIsFirstSlide(false);
    }
    if (swiper.isEnd) {
      setIsLastSlide(true);
    } else {
      setIsLastSlide(false);
    }
  };

  // when getting to end, try to load more
  /* istanbul ignore next */
  const onToEdge = (swiper: SwiperClass) => {
    if (swiper.isEnd && !isLastSlide) {
      loadMore?.();
    }
    onSlideChange(swiper);
  };

  /* istanbul ignore next */
  const setArrowEnablement = (swiper: SwiperClass) => {
    setIsFirstSlide(!swiper.allowSlidePrev || swiper.isBeginning);
    setIsLastSlide(!swiper.allowSlideNext || swiper.isEnd);
  };

  // swiper settings
  /* istanbul ignore next */
  const settings: SwiperProps = {
    spaceBetween: 32,
    slidesPerView: "auto",
    mousewheel: {
      forceToAxis: true,
    },
    keyboard: true,
    pagination: {
      clickable: true,
    },
    onToEdge: onToEdge,
    onFromEdge: onSlideChange,
    onSlideChange,
    speed: 500,
    modules: [Navigation, Mousewheel, Keyboard],
    onResize: setArrowEnablement,
    onSwiper: (swiper) => {
      swiperRef.current = swiper;
      setArrowEnablement(swiper);
    },
  };

  // re run arrow checks after carousel loads
  useEffect(() => {
    if (swiperRef?.current && !isLoading) {
      setArrowEnablement(swiperRef.current);
    }
  }, [isLoading]);

  return (
    <Box
      position="relative"
      sx={{
        ".swiper-slide": {
          width: "auto",
        },
        ".swiper": {
          width: "100%",
          pb: 8,
          pr: isFetching ? "40px" : "0px",
        },
      }}
      className={
        isFirstSlide ? "MessageList__FirstSlide" : "MessageList__NotFirstSlide"
      }
    >
      <Swiper {...settings}>{children}</Swiper>
      {isFetching && (
        <Stack
          justifyContent="center"
          height="calc(100% - 32px)"
          position="absolute"
          right={-10}
          top={0}
          zIndex={100}
        >
          <Loader />
        </Stack>
      )}
      <CarouselArrow
        direction="l"
        /* istanbul ignore next */
        onClick={() => {
          [...Array(slidesPerClick)].forEach((_) =>
            swiperRef.current?.slidePrev()
          );
        }}
        isDisabled={isFirstSlide}
      />
      <CarouselArrow
        direction="r"
        /* istanbul ignore next */
        onClick={() => {
          [...Array(slidesPerClick)].forEach((_) =>
            swiperRef.current?.slideNext()
          );
        }}
        isDisabled={isLastSlide}
      />
    </Box>
  );
};

export default Carousel;
