import { useCallback } from "react";
import { useTrackEventMutation } from "../store/consumerUser";
import { UiEventActions, UiEventTypes } from "../store/consumerUser/types";
import { useSuccessErrorSnacks } from "./useSuccessErrorSnacks";

interface IProps {
  action: UiEventActions;
  callback?: () => void;
  successMsg?: string;
  autoHideDuration?: number;
}
/**
 * Hook for user to request more info on features not yet enabled
 * displays error or success snackbars
 */
export const useRequestMoreInfo = ({
  action,
  callback,
  autoHideDuration,
  successMsg = "Your request for more info has been successfully submitted. A member of our team will get in touch soon.",
}: IProps) => {
  const [trackEvent, { isError, isSuccess, isLoading }] =
    useTrackEventMutation();
  const pathname = window.location.pathname;

  // display success or error message
  useSuccessErrorSnacks({
    isError,
    isSuccess,
    successMsg,
    errorMsg: "Please try again.",
    successAction: callback,
    autoHideDuration,
  });

  // track request more info
  const requestMoreInfo = useCallback(() => {
    trackEvent({
      action: action,
      type: UiEventTypes.Click,
      sourceUrl: location.href,
      targetUrl: null,
    });
  }, [pathname, action, trackEvent]);

  return { requestMoreInfo, isLoading, isSuccess, isError };
};
