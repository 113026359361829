import { IProxyEvent } from "./types";
import { rootApi } from "../rootApi";

export const proxyEventsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllProxyEvents: builder.query<IProxyEvent[], boolean>({
      query: (active) => {
        return `proxy-events/?is_vote=true&approved_only=true${
          active ? "&active=true&details=true" : "&active=false"
        }`;
      },
    }),
  }),
});

export const { useGetAllProxyEventsQuery } = proxyEventsApi;
