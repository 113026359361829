// types
import {
  IAllShareholderStats,
  IAllShareholderLocationStat,
  IPercentileShareholderTrendsResponse,
  IShareholderTrendParams,
  ITopShareholderTrendsResponse,
} from "./types";
import { rootApi } from "../rootApi";
import { IURL_PARAMS } from "../../types/Common";

interface IStatParams {
  aggregators: string;
  ordering?: string;
}

// Shareholder Lists Api
export const allShareholderStatsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * GET General Stats
     * */
    getAllShareholderStats: builder.query<IAllShareholderStats, IStatParams>({
      query: ({ aggregators }) => ({
        url: `shareholder-network/stats/?aggregators=${aggregators}`,
        method: "POST",
      }),
    }),
    /**
     * GET Trend stats over time for top 50/100/500 shareholders
     * */
    getTopShareholderTrends: builder.query<
      ITopShareholderTrendsResponse,
      IShareholderTrendParams
    >({
      query: (params) => {
        const queryString = new URLSearchParams(
          params as IURL_PARAMS
        ).toString();
        return `shareholder-network/trends/customers/?${queryString}`;
      },
    }),
    /**
     * GET General Stats
     * */
    getPercentileShareholderTrends: builder.query<
      IPercentileShareholderTrendsResponse,
      IShareholderTrendParams
    >({
      query: (params) => {
        const queryString = new URLSearchParams(
          params as IURL_PARAMS
        ).toString();
        return `shareholder-network/trends/percentile/?${queryString}`;
      },
    }),
    /**
     * GET Location Stats
     * */
    getAllShareholderLocationStats: builder.query<
      IAllShareholderLocationStat[],
      void
    >({
      query: () => `shareholder-network/locations/`,
    }),
  }),
});

export const {
  useGetAllShareholderStatsQuery,
  useGetPercentileShareholderTrendsQuery,
  useGetAllShareholderLocationStatsQuery,
  useGetTopShareholderTrendsQuery,
} = allShareholderStatsApi;
