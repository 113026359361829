import { Stack } from "@mui/material";
import {
  COLORS,
  nFormatter,
  pluralize,
  Text,
  minMaxMessaging,
  selectListMessaging,
  TooltipInfoIcon,
} from "@asayinc/component-library";
import { useMemo } from "react";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
//utils
import { formatAsPercentage } from "../../../../utils";
// store
import { useGetEventFilterOptionsQuery } from "../../../../store/event";
import {
  IFilterQueries,
  IListFilter,
  IRangeFilter,
} from "../../../../store/messageCompose";
import {
  MessageType,
  useGetMessageFilterOptionsQuery,
} from "../../../../store/messageLists";
// constants
import {
  FILTER_MESSAGING_MAP,
  TOP_SHAREHOLDER_LABEL_MAPPING,
} from "../../../../constants";
// components
import { CardWrapper } from "../../../Common";
import { Stat } from "../../Atoms";
import { getCityStateMessaging } from "../../../../utils/messages";

interface IProps {
  messageType: MessageType;
  shareholderCount: number;
  sharesCount: number;
  votedCount?: number;
  votedShares?: number;
  gainedPct?: number;
  query?: IFilterQueries;
  onEdit?: () => void;
  isSentMessage: boolean;
  sentOnDate?: string;
  isLoading?: boolean;
}

const draftCopy =
  "This recipient count is determined by the criteria used for this message. The actual amount of shareholders reached will depend on if these individuals are still shareholders and fit this criteria at the time the message is sent.";

const RecipientsCard = ({
  messageType,
  shareholderCount,
  sharesCount,
  votedCount = 0,
  votedShares = 0,
  gainedPct = 0,
  onEdit,
  query = {},
  isSentMessage,
  sentOnDate,
  isLoading,
}: IProps) => {
  const {
    data: messages,
    isLoading: isMessagesLoading,
    isError: isMessageFilterError,
  } = useGetMessageFilterOptionsQuery();
  const {
    data: events,
    isLoading: isEventsLoading,
    isError: isEventFilterError,
  } = useGetEventFilterOptionsQuery();
  // tags are used by name, so we dont need to pull all tags to get the reference, can generate for the required
  // param type in messaging function
  const tags = useMemo(
    () =>
      (query.tags as IListFilter)?.values
        ? (query.tags as IListFilter).values.map((t) => ({ id: t, name: t }))
        : [],
    [query]
  );
  const autoCompleteOptions = {
    qasParticipated: events,
    tags,
    messagesReceived: messages,
  };

  // snackbar for if any apis fail
  useSuccessErrorSnacks({
    errorMsg: "Some data failed to load, please refresh and try again.",
    isError: isMessageFilterError || isEventFilterError,
  });

  const isApiLoading = isMessagesLoading || isEventsLoading || isLoading;

  /**
   * Parse currently selected query, and based on query type get appropriate messaging
   * query: {
      qasParticipated: { range: { gte: 2, lte: 5 }}
    }
   */
  const filterMessages = useMemo(() => {
    if (isApiLoading) {
      return [];
    }

    return Object.keys(query).map((q) => {
      if (q === "state" || q === "city") {
        return getCityStateMessaging(query, q);
      } else if (q === "topShareholders") {
        return `Shareholders in the ${TOP_SHAREHOLDER_LABEL_MAPPING[
          (query[q] as IListFilter).values[0] as string
        ].toLowerCase()}`;
      } else if ("range" in query[q]) {
        return minMaxMessaging(
          (query[q] as IRangeFilter).range?.gte?.toString(),
          (query[q] as IRangeFilter).range?.lte?.toString(),
          FILTER_MESSAGING_MAP[q].chipStartWord,
          FILTER_MESSAGING_MAP[q].chipEndWord,
          FILTER_MESSAGING_MAP[q]?.isPercent
        );
      } else if ((query[q] as IListFilter)?.values[0] === true) {
        // Shares Status Filter
        return "Shareholders who hold shares at record date, but no longer hold";
      } else {
        // List Filters
        return selectListMessaging(
          (query[q] as IListFilter).values as (string | number)[],
          autoCompleteOptions[q],
          FILTER_MESSAGING_MAP[q].chipStartWord,
          `${FILTER_MESSAGING_MAP[q].chipEndWord}s`
        );
      }
    });
  }, [isApiLoading, query]);

  // generate filter text elements
  const filterElements = useMemo(
    () =>
      filterMessages.length ? (
        filterMessages.map((msg) => (
          <Text key={msg} variant="body1">
            {msg}
          </Text>
        ))
      ) : (
        <Text variant="body1">
          {isApiLoading ? undefined : "All shareholders"}
        </Text>
      ),
    [filterMessages]
  );

  // reached stats
  const shareholdersReached = nFormatter(shareholderCount);
  const sharesReached = nFormatter(sharesCount);
  const shareholdersReachedStr = pluralize(
    "shareholder",
    "shareholders",
    shareholderCount
  );
  const sharesReachedStr = pluralize("share", "shares", sharesCount);
  const recipientString = `${shareholdersReached} ${shareholdersReachedStr} · ${sharesReached} ${sharesReachedStr} represented`;

  const deliveryMethod =
    messageType === MessageType.ProxySM ? "Email, in-app message" : "Email";

  return (
    <CardWrapper sx={{ p: 8 }} onEdit={onEdit} cardName="recipients">
      {isSentMessage && (
        <Stack direction="row" flexWrap="wrap">
          <Stat
            isLoading={!!isApiLoading}
            title="Reached"
            tooltipText="Recipient count is determined by the criteria used. The actual shareholders reached depends on if these shareholders still meet criteria at the time the message is sent."
            largeStat={sharesReached}
            subStat={sharesReachedStr}
            subText={`${shareholdersReached} ${shareholdersReachedStr}`}
            sx={{ width: "50%", mb: 6 }}
          />
          {messageType === MessageType.ProxySM && (
            <>
              <Stat
                isLoading={!!isApiLoading}
                title="Voted"
                largeStat={nFormatter(votedShares)}
                subStat={pluralize("share", "shares", votedShares)}
                subText={`${nFormatter(votedCount)} ${pluralize(
                  "shareholder",
                  "shareholders",
                  votedCount
                )}`}
                sx={{ width: "50%", mb: 6 }}
              />
              <Stat
                isLoading={!!isApiLoading}
                title="Gained"
                tooltipText="Incremental amount of shares voted from your messages, relative to all shares voted so far."
                largeStat={formatAsPercentage(gainedPct)}
                subText="shares voted"
                sx={{ width: "50%", mb: 6 }}
              />
            </>
          )}
        </Stack>
      )}
      <Stack spacing={6}>
        {!isSentMessage && (
          <>
            <Text variant="subtitle2" loadingProps={{ sx: { width: "80px" } }}>
              {isApiLoading ? undefined : "Summary"}
            </Text>
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center">
                <Text
                  variant="body2"
                  emphasis="medium"
                  mr={1}
                  loadingProps={{ sx: { width: "80px" } }}
                >
                  {isApiLoading ? undefined : "Recipients"}
                </Text>
                {!isApiLoading && (
                  <TooltipInfoIcon
                    title={draftCopy}
                    iconColor={COLORS.P_MED_LOW_EMPHASIS}
                    placement="top"
                  />
                )}
              </Stack>
              <Text variant="body1">
                {isApiLoading ? undefined : recipientString}
              </Text>
            </Stack>
          </>
        )}
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={2} width="50%">
            <Text
              variant="body2"
              emphasis="medium"
              loadingProps={{ sx: { width: "80px" } }}
            >
              {isApiLoading ? undefined : "Criteria used"}
            </Text>
            {filterElements}
          </Stack>
          {isSentMessage && sentOnDate && (
            <Stack spacing={2} width="50%">
              <Text
                variant="body2"
                emphasis="medium"
                loadingProps={{ sx: { width: "80px" } }}
              >
                {isApiLoading ? undefined : "Sent on"}
              </Text>
              <Text variant="body1">
                {isApiLoading ? undefined : sentOnDate}
              </Text>
            </Stack>
          )}
        </Stack>
        {!isSentMessage && (
          <Stack spacing={2}>
            <Text
              variant="body2"
              emphasis="medium"
              loadingProps={{ sx: { width: "80px" } }}
            >
              {isApiLoading ? undefined : "Delivery method"}
            </Text>
            <Text variant="body1">
              {isApiLoading ? undefined : deliveryMethod}
            </Text>
          </Stack>
        )}
      </Stack>
      {isApiLoading ? (
        <Text
          variant="body3"
          loadingProps={{ sx: { width: "248px", mt: 2 } }}
        />
      ) : null}
    </CardWrapper>
  );
};

export default RecipientsCard;
