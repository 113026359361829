import { useState, useCallback, useMemo } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
// components
import { Stack } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { TableV2 as Table, Text, IconButton } from "@asayinc/component-library";
import { ExportFeedback, FeedbackSummary } from "./components";
// Redux/Data
import { useGetEventFeedbackQuery } from "../../../../store/eventStats";
import {
  initialState as initialEventState,
  useGetEventQuery,
} from "../../../../store/event";
import { useGetSettingsQuery } from "../../../../store/settings";
// constants
import {
  DELETED_USER_ID,
  ROW_OPTIONS,
  SORT,
  SORT_SYMBOL,
  URL_PARAMS,
} from "../../../../constants";
import { COLUMNS } from "./constants";
// utils
import { useTableSearchParams } from "../../../../hooks";
import { track } from "../../../../analytics";
import { trackOrderingChange, trackOpenDialog } from "../../utils";
import { getColumnData, getRowData } from "./factories";
// types
import { TableEventData } from "../../../../types/Table";
import { ISettings } from "../../../../store/settings/types";

const AllEventFeedback = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // current event
  const { eventSlug } = useParams() as { eventSlug: string };

  /**
   * callback for tracking when sort occurs
   */
  const sortCallback = (sortValue: string) => {
    trackOrderingChange(sortValue, rawApiObject, eventSlug);
  };

  const {
    paramObj,
    rawApiObject,
    getSort,
    sortFn,
    handlePageChange,
    handleRowsChange,
  } = useTableSearchParams({
    sortCallback,
    defaultOrdering: `${SORT_SYMBOL.desc}${SORT.sharesOwned}`,
  });

  const apiParams = {
    limit: paramObj.limit,
    page: paramObj.page,
    offset: String((parseInt(paramObj.page) - 1) * parseInt(paramObj.limit)),
    filter: paramObj.filter,
    ordering: paramObj.ordering,
  };
  // settings
  const { data: settings = {} } = useGetSettingsQuery();
  const { name: companyName } = settings as ISettings;
  // event
  const { data: eventData = initialEventState } = useGetEventQuery(eventSlug);

  const {
    data = { count: 0, results: [], ids: [] },
    isFetching,
    isLoading,
  } = useGetEventFeedbackQuery({
    eventSlug,
    params: apiParams,
  });
  const { results: feedbackList, count } = data;

  // controls which dialog to display
  const [dialog, setDialog] = useState<"download" | "">("");

  // go back to last page
  const goBack = () => {
    navigate(`/qa/${eventSlug}/overview`);
  };

  /**
   * Display shareholder drawer
   * @param data TableEventData
   */
  const goToShareholder = useCallback(
    (tableData: unknown) => {
      const { globalUserId } = tableData as TableEventData;
      if (!globalUserId || globalUserId === DELETED_USER_ID) {
        return;
      }
      track({
        name: "Participants Profile Selected",
        client: companyName,
        qaEvent: eventSlug,
        eventStatus: eventData?.status,
        listOrder: 0,
        shareholderProfileUrl: `/shareholders/${globalUserId}`,
      });
      searchParams.set(URL_PARAMS.sid, globalUserId);
      setSearchParams(searchParams, { state: { goBackText: "Q&A" } });
    },
    [searchParams, companyName]
  );

  /**
   * Column data for table
   */
  const columnData = useMemo(
    () => getColumnData({ getSort, sortFn, goToShareholder }),
    [getSort, sortFn, goToShareholder]
  );

  /**
   * Each rows specific data for table
   */
  const rowData = getRowData({
    feedbackList,
  });

  /**
   * Toggle the dialog for exporting or tagging questions in bulk
   */
  const closeDialog = (
    type?: "download" | "bulkTag" | "singleTag",
    _?: React.MouseEvent<HTMLElement>,
    _2?: unknown,
    didSave?: boolean
  ) => {
    setDialog("");
    if (type === "download") {
      track({
        name: "Export Modal Action",
        action: didSave ? "Export" : "Cancel",
        slug: eventSlug,
        totalItems: count,
      });
    }
  };

  /**
   * Toggle the dialog for exporting or tagging quesitons
   */
  const openDialog = (type: "download") => {
    setDialog(type);
    trackOpenDialog(type, apiParams, eventSlug, "Feedback List");
  };

  const downloadProps = {
    active: !!count,
    action: () => openDialog("download"),
    tooltip: "Export to CSV",
  };

  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count,
    page: parseInt(apiParams.page as string),
    rowsPerPage: parseInt(apiParams.limit as string),
    rowOptions: ROW_OPTIONS,
  };

  return (
    <Stack
      p={10}
      width="100%"
      maxWidth={1440}
      m="0 auto"
      gap={8}
      data-testid="event-feedback-page"
    >
      <ExportFeedback
        open={dialog === "download"}
        handleClose={closeDialog}
        count={count}
      />
      <Stack>
        <Stack mb={3} alignItems="flex-start">
          <Stack
            direction="row"
            onClick={goBack}
            alignItems="center"
            data-testid="go-back-cta"
            sx={{ cursor: "pointer" }}
          >
            <IconButton
              sx={{ height: "20px", width: "20px", mr: 2, ml: "-3.5px" }}
            >
              <ArrowBack />
            </IconButton>
            <Text loadingProps={{ sx: { width: "100px" } }} variant="subtitle2">
              {eventData?.title}
            </Text>
          </Stack>
        </Stack>
        <Text variant="h5">Shareholder feedback</Text>
      </Stack>
      <FeedbackSummary />
      {!isLoading && count === 0 ? (
        <Text
          variant="body3"
          emphasis="medium"
          data-testid="feedback-empty-text"
        >
          Once your shareholders have submitted feedback, they would be shown
          here.
        </Text>
      ) : (
        <Table
          isFetching={isFetching}
          isLoading={isLoading}
          rows={rowData}
          memoCells
          toolbarInside
          columnData={columnData}
          tableMinWidth={1198}
          testid="feedback-table"
          tableLayout="auto"
          title={`${count} Replies`}
          columns={COLUMNS}
          downloadProps={downloadProps}
          paginateProps={paginateProps}
          count={count}
        />
      )}
    </Stack>
  );
};

export default AllEventFeedback;
