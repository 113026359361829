import { useCallback, useMemo, useState } from "react";
// dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { Link, useNavigate } from "react-router-dom";
// types
import { Event, EventStatusOptions } from "../../../../../types/Events";
// components
import {
  ButtonV2,
  Text,
  CircularProgress,
  COLORS,
  Card,
  Tooltip,
  IButtonAction,
  IconButton,
  ActionablePopover,
} from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MiniDialog, StatusBadge } from "../../../../../components/Common";
// constants
import {
  EventStatusButtonMapping,
  EventCardButtons,
  RightSectionHeaders,
} from "./constants";
// utils
import { getRightSectionData } from "./utils";
// data / redux
import { useSuccessErrorSnacks } from "../../../../../hooks/useSuccessErrorSnacks";
import { useDeleteDraftEventMutation } from "../../../../../store/event";

dayjs.extend(relativeTime);
dayjs.extend(utc);

interface IProps {
  event?: Event;
  isLoading?: boolean;
}

const EventCard = ({ event, isLoading = false }: IProps) => {
  const navigate = useNavigate();
  const [confirmOpen, setConfirm] = useState(false);
  const [removeDraft, { isSuccess, isError, isLoading: isDeleteLoading }] =
    useDeleteDraftEventMutation({
      fixedCacheKey: "shared-delete-event",
    });
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleAnchorEl = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  // close delete draft dialog
  const closeDialog = useCallback(() => {
    setConfirm(false);
  }, []);

  // open delete dialog
  const openDialog = useCallback(() => {
    setConfirm(true);
  }, []);

  // show success/error snackbar on api callback
  useSuccessErrorSnacks({
    successMsg: "Event successfully deleted.",
    errorMsg: "Failed to delete event please try again.",
    errorAction: closeDialog,
    successAction: closeDialog,
    isSuccess: isSuccess,
    isError: isError,
  });

  const deleteDraft = async () => {
    setAnchorEl(null);
    removeDraft((event as Event).slug);
  };

  // dialogButton Actions
  const dialogButtonActions = [
    {
      onClick: deleteDraft,
      label: "Delete",
      variant: "warning",
      disabled: isDeleteLoading,
    },
    {
      onClick: closeDialog,
      label: "Cancel",
      variant: "secondary",
    },
  ] as IButtonAction[];

  const status = event?.status || "";
  const buttonAction = EventStatusButtonMapping[status];
  const rightSectionHeader = RightSectionHeaders[status];
  const rightSectionData = getRightSectionData(event);

  // go to edit page
  const goToEdit = () => {
    navigate(`/qa/${event?.slug}/edit`);
  };
  // go to questions page
  const goToQuestions = () => {
    navigate(`/qa/${event?.slug}/questions`);
  };

  const showTooltip = (event?.title?.length || 0) > 30;

  // determine which popover actions to show
  const popoverActions = useMemo(() => {
    switch (status) {
      case EventStatusOptions.DRAFT:
        return {
          testid: "delete-btn",
          text: "Delete",
          onClick: openDialog,
        };
      case EventStatusOptions.ACTIVE:
      case EventStatusOptions.COMPLETED:
        return {
          testid: "questions-btn",
          text: "Questions",
          onClick: goToQuestions,
        };
      case EventStatusOptions.APPROVED:
      case EventStatusOptions.PUBLISHED:
      case EventStatusOptions.PENDING:
      default:
        return { testid: "edit-btn", text: "Edit", onClick: goToEdit };
    }
  }, [event?.slug, status]);

  return (
    <Card
      hasHoverBg={false}
      elevation={5}
      sx={{
        p: 8,
        overflow: "visible",
      }}
    >
      <Stack minWidth={330} minHeight={190} data-testid={`card-${event?.slug}`}>
        {!isLoading && <StatusBadge messageStatus={event?.status} />}
        <Stack direction="row" justifyContent="space-between" mt={3}>
          <Tooltip
            placement="top"
            title={isLoading || !showTooltip ? "" : (event as Event).title}
          >
            <Text
              mb={6}
              variant="subtitle1"
              sx={{
                width: "286px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              loadingProps={{ sx: { width: "286px" } }}
            >
              {isLoading ? undefined : event?.title}
            </Text>
          </Tooltip>
          <ActionablePopover
            anchorEl={anchorEl}
            handleClose={handleClose}
            actions={[popoverActions]}
          />
          {event?.status === EventStatusOptions.DRAFT && (
            <MiniDialog
              open={confirmOpen}
              title="Delete Q&A Draft?"
              content={
                <Box>
                  Are you sure you want to delete? This action can&apos;t be
                  undone.
                </Box>
              }
              buttonActions={dialogButtonActions}
              close={closeDialog}
            />
          )}
        </Stack>
        <Stack direction="row" mb={6} gap={8}>
          <Stack flexBasis="100%">
            <Text emphasis="medium" variant="body2" mb={1}>
              {isLoading ? undefined : "Event date and time"}
            </Text>
            <Text
              variant="body2"
              loadingProps={{ sx: { mt: 1 } }}
              sx={{ minHeight: "40px" }}
            >
              {isLoading
                ? undefined
                : `${
                    dayjs(event?.eventStartDatetime).isValid()
                      ? dayjs(event?.eventStartDatetime).format(
                          "MMMM D, YYYY h:mm A"
                        )
                      : ""
                  }`}
            </Text>
          </Stack>
          <Stack flexBasis="100%">
            <Text emphasis="medium" variant="body2" mb={1}>
              {isLoading ? undefined : rightSectionHeader}
            </Text>
            <Text variant="body2" loadingProps={{ sx: { mt: 1 } }}>
              {isLoading ? undefined : rightSectionData}
            </Text>
          </Stack>
        </Stack>
        <Stack marginTop="auto">
          {isLoading ? (
            <ButtonV2 variant="secondary" size="mini">
              <CircularProgress
                size="18px"
                sx={{ color: COLORS.SAY_COBALT, verticalAlign: "middle" }}
              />
            </ButtonV2>
          ) : (
            <Stack direction="row" gap={3} alignItems="center">
              {buttonAction === EventCardButtons.ViewDetails && (
                <Link
                  to={`/qa/${event?.slug}/overview`}
                  style={{ textDecoration: "none" }}
                >
                  <ButtonV2 variant="secondary" size="small">
                    View details
                  </ButtonV2>
                </Link>
              )}
              {buttonAction === EventCardButtons.Edit && (
                <Link
                  to={`/qa/${event?.slug}/edit`}
                  style={{ textDecoration: "none" }}
                >
                  <ButtonV2 variant="secondary" size="small">
                    Edit
                  </ButtonV2>
                </Link>
              )}
              <Box>
                <IconButton
                  data-testid="event-card-menu-btn"
                  onClick={toggleAnchorEl}
                  size="medium"
                  variant="ctaAlt"
                  sx={{ mt: -1, mr: -1 }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default EventCard;
