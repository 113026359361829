import ShareholderDrawer from "../../../ShareholderDrawer";
import QuestionDrawer from "../../../QuestionDrawer";
import TopicDrawer from "../../../TopicDrawer";

const Drawers = () => {
  return (
    <>
      <ShareholderDrawer />
      <QuestionDrawer />
      <TopicDrawer />
    </>
  );
};

export default Drawers;
