import { COLORS } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import {
  Legend,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceDot,
} from "recharts";
// components
import { ProxyDetailGraphTooltip, MailingPopover } from "./components";
import {
  ActiveDot,
  CustomReferenceDot,
  CustomizedDot,
  XAxisTick,
  YAxisTick,
} from "../../../../../../../../../components/Graphs";
// types
import {
  IGraphDotStat,
  IGraphDotStats,
} from "../../../../../../../../../components/Graphs/types";
// utils
import {
  renderLegend,
  renderLoadingLegend,
} from "../../../../../../../../../components/Graphs";

export interface IGraphData {
  name: string;
  shareholders?: number;
  shares?: number;
}

export interface IActiveDotState extends IGraphDotStat {
  anchorEl: SVGSVGElement;
}

interface IProps {
  graphData: IGraphData[];
  shareholderMax: number;
  graphEventStats: IGraphDotStats;
  sharesMax: number;
  isLoading: boolean;
}

const ProxyDetailGraph = ({
  graphData,
  shareholderMax,
  graphEventStats,
  sharesMax,
  isLoading,
}: IProps) => {
  const [activeMailing, setActiveMailing] = useState<IActiveDotState | null>(
    null
  );
  // get data for ongoing event indicator dots
  const referenceDotData = useMemo(() => {
    const isEventActive =
      !isLoading &&
      graphData.length > 0 &&
      // use presence of empty filler days to determine if event is active
      !graphData[graphData.length - 1].shares;

    if (!isEventActive) {
      return { isEventActive };
    }

    // find last entry with defined data
    let lastGraphDataPoint = 0;
    for (let i = 0; i < graphData.length; i++) {
      if (graphData[i].shares === undefined) {
        break;
      }
      lastGraphDataPoint = i;
    }
    const ongoingShares = graphData[lastGraphDataPoint].shares;
    const ongoingShareholders = graphData[lastGraphDataPoint].shareholders;

    return {
      isEventActive,
      ongoingShares,
      ongoingShareholders,
      lastGraphDataPoint,
    };
  }, [graphData]);

  const {
    isEventActive,
    ongoingShares,
    ongoingShareholders,
    lastGraphDataPoint,
  } = referenceDotData;

  // display popover when mousing over customized dot
  const handleMouseEnter = (
    event: React.MouseEvent<SVGSVGElement>,
    dotStats: IGraphDotStat
  ) => {
    setActiveMailing({
      ...dotStats,
      anchorEl: event.currentTarget,
    });
  };

  // close popover
  const handleClose = () => {
    setActiveMailing(null);
  };

  const open = Boolean(activeMailing?.anchorEl);

  // graph tries using whole numbers and messes up due to the shareholderMax domain
  // create our own ticks instead equidistant
  const ticks = Array(5)
    .fill(0)
    .map((_, i) => (i * shareholderMax * 2) / 4);

  return (
    <Box height="352px" sx={{ svg: { overflow: "visible" } }}>
      <MailingPopover
        open={open}
        handleClose={handleClose}
        activeMailing={activeMailing}
        sharesMax={sharesMax}
        shareholderMax={shareholderMax}
      />

      <ResponsiveContainer width="100%" height="95%">
        <LineChart
          data={graphData}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          key={graphData?.length}
        >
          <Legend
            iconType="circle"
            iconSize={isLoading ? 0 : 8}
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
            align="right"
            height={50}
            payload={[
              {
                value: "Shares",
                type: "circle",
                id: "shares",
                color: COLORS.DATA_VIZ_DARK_GREEN,
              },
              {
                value: "Shareholders",
                type: "circle",
                id: "shares",
                color: COLORS.DATA_VIZ_LIGHT_GREEN,
              },
            ]} // manually hardcode to force order to be shares first, this order is based on jsx order of lines below
            // however we cannot change line order as we need to ensure the line with icons sits on top to the user
            formatter={isLoading ? renderLoadingLegend : renderLegend}
            verticalAlign="top"
          />
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            padding={{ left: 20, right: isLoading ? 20 : 0 }}
            axisLine={false}
            tickLine={false}
            tick={<XAxisTick isLoading={isLoading} />}
            domain={isLoading ? [1, 6] : undefined}
            ticks={isLoading ? [1, 2, 3, 4, 5, 6] : undefined}
            type={isLoading ? "number" : undefined}
            tickCount={isLoading ? 6 : undefined}
            interval={isLoading ? undefined : 6}
            tickMargin={15}
          />
          <YAxis
            yAxisId="left"
            axisLine={false}
            tick={
              <YAxisTick
                color={COLORS.DATA_VIZ_DARK_GREEN}
                anchor="end"
                isLoading={isLoading}
              />
            }
            tickLine={false}
            tickMargin={12}
            domain={isLoading ? [1, 5] : undefined}
            ticks={isLoading ? [1, 2, 3, 4, 5] : undefined}
            width={50}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            tickMargin={12}
            type="number"
            tickCount={5}
            allowDataOverflow
            ticks={isLoading ? [1, 2, 3, 4, 5] : ticks}
            tickLine={false}
            tick={
              <YAxisTick
                color={COLORS.DATA_VIZ_LIGHT_GREEN}
                anchor="start"
                isLoading={isLoading}
                rightTick={true}
              />
            }
            axisLine={false}
            domain={isLoading ? [1, 5] : [0, shareholderMax * 2]}
          />
          <Tooltip
            content={<ProxyDetailGraphTooltip />}
            position={{ y: -39 }}
            wrapperStyle={{ left: -90 }}
          />
          <Line
            yAxisId="right"
            strokeWidth={3}
            dot={false}
            activeDot={<ActiveDot />}
            type="monotone"
            dataKey="shareholders"
            stroke={COLORS.DATA_VIZ_LIGHT_GREEN}
          />
          <Line
            yAxisId="left"
            type="monotone"
            dot={
              <CustomizedDot
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleClose}
                graphEventStats={graphEventStats}
              />
            }
            activeDot={<ActiveDot graphEventStats={graphEventStats} />}
            dataKey="shares"
            stroke={COLORS.DATA_VIZ_DARK_GREEN}
            strokeWidth={3}
          />
          {/* Active Event Indicator Dots */}
          {isEventActive && (
            <>
              <ReferenceDot
                isFront
                x={lastGraphDataPoint}
                y={ongoingShares}
                yAxisId="left"
                ifOverflow="visible"
                fill={COLORS.DATA_VIZ_DARK_GREEN}
                shape={CustomReferenceDot}
                r={20}
              />
              <ReferenceDot
                isFront
                x={lastGraphDataPoint}
                y={ongoingShareholders}
                yAxisId="right"
                ifOverflow="visible"
                fill={COLORS.DATA_VIZ_LIGHT_GREEN}
                shape={CustomReferenceDot}
                r={20}
              />
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ProxyDetailGraph;
