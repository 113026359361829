import { IButtonAction, pluralize } from "@asayinc/component-library";
// redux
import { useExportQuestionsMutation } from "../../../../../store/questions";
// router
import { useParams } from "react-router-dom";

import { BasicOverlay } from "../../../../../components/Common";
interface Props {
  open: boolean;
  handleClose: (
    type?: "download" | "bulkTag",
    _?: undefined,
    _2?: unknown,
    didSave?: boolean,
    tagCount?: number
  ) => void;
  questionIds: string[];
}

const ExportQuestions = ({
  open,
  handleClose,
  questionIds,
}: Props): JSX.Element => {
  const [getExportedQuestions] = useExportQuestionsMutation();
  const { eventSlug } = useParams() as { eventSlug: string };

  /**
   * call api to download the csv
   */
  const exportQuestions = () => {
    getExportedQuestions({
      questionIds,
      eventSlug,
    });

    handleClose("download", undefined, undefined, true);
  };

  const actions = [
    {
      onClick: handleClose,
      label: "Cancel",
      variant: "secondary",
    },
    {
      onClick: exportQuestions,
      label: "Export",
    },
  ] as IButtonAction[];

  const allQuestionsContent = "Export all questions to a CSV?";
  const selectedQuestionsContent = `Export ${questionIds.length} ${pluralize(
    "question",
    "questions",
    questionIds.length
  )} to a CSV?`;
  const content =
    questionIds.length === 0 ? allQuestionsContent : selectedQuestionsContent;

  return (
    <BasicOverlay
      open={open}
      buttonActions={actions}
      handleClose={handleClose}
      ctaOrientation="horizontal"
      title="Export Questions"
      content={content}
    />
  );
};

export default ExportQuestions;
