import { EventStatusOptions } from "../../../../../types/Events";

export enum EventCardButtons {
  Edit = "Edit",
  ViewDetails = "View Details",
  Questions = "Questions",
}

export const EventStatusButtonMapping = {
  [EventStatusOptions.DRAFT]: EventCardButtons.Edit,
  [EventStatusOptions.PENDING]: EventCardButtons.ViewDetails,
  [EventStatusOptions.APPROVED]: EventCardButtons.ViewDetails,
  [EventStatusOptions.PUBLISHED]: EventCardButtons.ViewDetails,
  [EventStatusOptions.ACTIVE]: EventCardButtons.ViewDetails,
};

export const RightSectionHeaders = {
  [EventStatusOptions.DRAFT]: "Last edit",
  [EventStatusOptions.PENDING]: "Submitted",
  [EventStatusOptions.APPROVED]: "Approved",
  [EventStatusOptions.PUBLISHED]: "Published",
  [EventStatusOptions.ACTIVE]: "Currently asked",
};
