import { Text } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { sanitize } from "dompurify";
import dayjs from "dayjs";

interface IProps {
  messageText?: string;
  companyName?: string;
  isStacked?: boolean;
}

const MessageScreen = ({ messageText, companyName, isStacked }: IProps) => {
  const messageDisplay =
    messageText ||
    `${companyName} is holding their 2023 Annual Meeting on May 24, 2023. You're invited to vote and participate in the meeting because you owned ${companyName} stock on March 30, 2023.<br /><br />Vote on key company decisions by May 23, 2023 through the link below.`;
  return (
    <Box
      width={isStacked ? 375 : 248}
      height={isStacked ? 812 : 537}
      position="relative"
      sx={{
        backgroundImage: `url('${process.env.REACT_APP_ASSETS}images/ir-portal/ios-message-screen.jpg')`,
        backgroundSize: "contain",
      }}
    >
      <Stack
        position="absolute"
        top={isStacked ? "6%" : "5%"}
        alignItems="center"
        width="100%"
        gap={0.5}
      >
        <img
          src="https://assets.say.rocks/images/qa-events/companies/drafts/76b2aa8b-6ec28e30-logo.png"
          width={isStacked ? 42 : 27}
          height={isStacked ? 42 : 27}
        />
        <Text variant="body3" fontSize={isStacked ? 15 : 10}>
          {companyName}
        </Text>
      </Stack>
      <Stack position="absolute" top="19%" left={isStacked ? 24 : 16}>
        <Box textAlign="center">
          <Text
            sx={{ color: "rgb(106, 114, 120)" }}
            fontSize={isStacked ? 13 : "8.5px"}
            lineHeight={isStacked ? "20px" : "13px"}
            mb={isStacked ? 3 : 2}
            variant="body2"
          >
            {dayjs().format("MMM D")} {"\u00B7"} {dayjs().format("h:mmA")}
          </Text>
        </Box>
        <Stack direction="row" alignItems="flex-end" gap={1.25}>
          <img
            src="https://assets.say.rocks/images/qa-events/companies/drafts/76b2aa8b-6ec28e30-logo.png"
            width={isStacked ? 32 : 21}
            height={isStacked ? 32 : 21}
          />
          <Box
            bgcolor="rgba(245, 248, 250, 1)"
            py={isStacked ? 3 : 2}
            px={isStacked ? 4 : 2.75}
            borderRadius="8px 8px 8px 0"
            width={isStacked ? 255 : 169}
          >
            <Text
              variant="body3"
              whiteSpace="pre-wrap"
              fontSize={isStacked ? 15 : 10}
              lineHeight={isStacked ? "18px" : "14.5px"}
              dangerouslySetInnerHTML={{
                __html: sanitize(messageDisplay),
              }}
            />
            <Stack
              py={isStacked ? 2.5 : 1.75}
              border="1px solid black"
              borderRadius={6}
              mt={3}
              width="100%"
              alignItems="center"
              sx={{
                cursor: "pointer",
              }}
            >
              <Text
                variant="subtitle2"
                fontSize={isStacked ? 15 : 10}
                lineHeight={isStacked ? "18px" : "14px"}
              >
                Vote now
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default MessageScreen;
