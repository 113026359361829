import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//components
import {
  DATE_FORMAT,
  TableV2 as Table,
  useIsMounted,
} from "@asayinc/component-library";
//constants
import {
  ROW_OPTIONS,
  SORT,
  SORT_SYMBOL,
  URL_PARAMS,
} from "../../../../../constants";
//utils
import { getColumnData, getRowData } from "./factories";
import { getNoResultsProps } from "../../../utils";
import { paginationAction, track } from "../../../../../analytics";
// data
import { useEventsPageData, useHasAnEvent } from "../../../hooks";
import { useSearchFns } from "../../../../../hooks/useSearchFns";
import { useTableSearchParams } from "../../../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  selectIsDraftCreated,
  setDraftCreated,
} from "../../../../../store/event";
import { TableEventData } from "@asayinc/component-library/dist/components/Organisms/Table/types";
import dayjs from "dayjs";
import { COLUMNS } from "../../../constants";

const AllEventsTable = () => {
  const { hasCompletedEvent, loading: apisLoading } = useHasAnEvent();
  const searchFns = useSearchFns(URL_PARAMS.search);
  const didMount = useRef<boolean>(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();

  // need to use state due to tooltip rendering too soon causes it to be misplaced
  const [showTooltip, setShowTooltip] = useState(false);
  const isDraftCreated = useAppSelector(selectIsDraftCreated);
  const { getSort, sortFn, ordering } = useTableSearchParams({
    defaultOrdering: `${SORT_SYMBOL.desc}${SORT.startTime}`,
  });
  const {
    searchParams,
    page,
    search,
    setPage,
    limit,
    setLimit,
    completedEvents,
    count,
    isLoading,
    isFetching,
  } = useEventsPageData({ ordering });

  const params = Object.fromEntries(searchParams);

  // component mounted
  useEffect(() => {
    didMount.current = true;
  }, []);

  // after 10 seconds if component is still mounted, hide tooltip
  const hideTooltip = () => {
    setTimeout(() => {
      dispatch(setDraftCreated(false));
    }, 10000);
  };

  // once page loads, start timer to hide tooltip
  useEffect(() => {
    if (!apisLoading && isDraftCreated) {
      setTimeout(() => {
        if (isMounted()) {
          setShowTooltip(true);
          hideTooltip();
        }
      }, 1000);
    }
  }, [apisLoading]);

  /**
   * Track search
   */
  useEffect(() => {
    if (search && didMount.current) {
      track({
        name: "Event Searched",
        searchApplied: Boolean(search),
        searchTerm: search,
      });
    }
  }, [search]);

  /**
   * Track event clicked
   */
  const trackEventClicked = (id: string) => {
    const event = completedEvents.find((event) => event.slug === id);
    track({
      name: "Q&A Event Opened",
      slug: event?.slug,
      eventDate: dayjs(event?.eventStartDatetime).format(DATE_FORMAT),
      status: event?.eventState,
    });
  };

  /**
   * pull questions for the given page number
   */
  const handlePageChange = (newPage: number) => {
    paginationAction("Page", "Events", newPage, count, params);
    setPage(newPage.toString());
  };

  /**
   * pull questions with new rows per page, reset page to 0
   */
  const handleRowsChange = (newRows: number) => {
    paginationAction("Row", "Events", newRows, count, params);
    setPage("1");
    setLimit(newRows.toString());
  };

  /**
   * Navigate to QA Event
   */
  const goToEvent = useCallback(
    (data: unknown) => {
      const { id } = data as TableEventData;
      trackEventClicked(id);
      navigate(`${pathname}/${id}/overview`);
    },
    [pathname, completedEvents]
  );

  /**
   * Column data for table
   */
  const columnData = useMemo(
    () => getColumnData({ goToEvent, getSort, sortFn }),
    [getSort, sortFn, goToEvent]
  );

  /**
   * Each rows specific data for table
   */
  const rowData = getRowData({ completedEvents });

  /**
   * content for when there are no results
   */
  const noResultsData = getNoResultsProps(params);

  // searchbar props leveraging useSearchFns
  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search for a Q&A",
  };
  // render completed table for loading state */

  if (!hasCompletedEvent && !isLoading) {
    return null;
  }

  return (
    <Table
      titleTooltipProps={{
        title: "Your past event recaps can be viewed from here.",
        open: !apisLoading && showTooltip && isDraftCreated,
        placement: "right",
      }}
      rows={rowData}
      isLoading={isLoading}
      isFetching={isFetching}
      tableLayout="auto"
      columnData={columnData}
      title={`Completed (${count})`}
      columns={COLUMNS}
      count={count}
      searchBarProps={searchBarProps}
      tableMinWidth={600}
      testid="allevents-table"
      memoCells
      noResultsData={noResultsData}
      paginateProps={{
        onChangePage: handlePageChange,
        onChangeRows: handleRowsChange,
        count: count,
        page: parseInt(page),
        rowsPerPage: parseInt(limit),
        rowOptions: ROW_OPTIONS,
      }}
    />
  );
};

export default AllEventsTable;
