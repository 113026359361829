import { IGraphDotStat } from "../../types";

interface IProps {
  cx: number;
  cy: number;
  onMouseEnter: (
    e: React.MouseEvent<SVGSVGElement>,
    stats: IGraphDotStat
  ) => void;
  onMouseLeave: () => void;
  dotStats: IGraphDotStat;
}

const LocalPostOfficeDot = ({
  cx,
  cy,
  onMouseEnter,
  onMouseLeave,
  dotStats,
}: IProps) => {
  // pass back this dots stats to the graph to be displayed
  const handleMouseEnter = (e: React.MouseEvent<SVGSVGElement>) => {
    onMouseEnter(e, dotStats);
  };

  return (
    <svg
      x={cx}
      y={cy}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
      width="52"
      height="58"
      data-testid="icon-CalendarDot"
      viewBox="0 0 52 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_511_4263)">
        <rect x="12" y="12" width="28" height="28" rx="4" fill="white" />
        <path
          d="M27.6667 29.9999C27.2 29.9999 26.8056 29.8388 26.4833 29.5166C26.1611 29.1944 26 28.7999 26 28.3333C26 27.8666 26.1611 27.4721 26.4833 27.1499C26.8056 26.8277 27.2 26.6666 27.6667 26.6666C28.1333 26.6666 28.5278 26.8277 28.85 27.1499C29.1722 27.4721 29.3333 27.8666 29.3333 28.3333C29.3333 28.7999 29.1722 29.1944 28.85 29.5166C28.5278 29.8388 28.1333 29.9999 27.6667 29.9999ZM21.3333 32.6666C20.9667 32.6666 20.6527 32.5361 20.3913 32.2753C20.1304 32.0139 20 31.6999 20 31.3333V21.9999C20 21.6333 20.1304 21.3195 20.3913 21.0586C20.6527 20.7973 20.9667 20.6666 21.3333 20.6666H22V19.3333H23.3333V20.6666H28.6667V19.3333H30V20.6666H30.6667C31.0333 20.6666 31.3473 20.7973 31.6087 21.0586C31.8696 21.3195 32 21.6333 32 21.9999V31.3333C32 31.6999 31.8696 32.0139 31.6087 32.2753C31.3473 32.5361 31.0333 32.6666 30.6667 32.6666H21.3333ZM21.3333 31.3333H30.6667V24.6666H21.3333V31.3333Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_511_4263"
          x="0"
          y="0"
          width="52"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_511_4263"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_511_4263"
            result="effect2_dropShadow_511_4263"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_511_4263"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LocalPostOfficeDot;
