import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
// components
import { Stack } from "@mui/material";
import {
  ButtonV2,
  CircularProgress,
  COLORS,
  LoadingSkeleton,
  Text,
  DATE_FORMAT,
  useScreenSize,
} from "@asayinc/component-library";
import { CardWrapper } from "../../../../../../../components/Common";
import { EventIcon, VotingStat } from "./components";
import { LifecyclePill } from "../../../../../components";
// data
import { useGetAllProxyEventsQuery } from "../../../../../../../store/proxyEvents";
import { useGetProxyTimelineTrendQuery } from "../../../../../../../store/proxyEvent";
// types
import {
  IProxyEvent,
  IProxyEventStats,
} from "../../../../../../../store/proxyEvents/types";
// utils
import {
  getEventIconProps,
  getLifecyclePillProps,
  getTrendDataProps,
} from "./utils";

const dateTextLoadingProps = { sx: { width: "141px" } };

const OngoingEvent = () => {
  const { data: events = [] as IProxyEvent[], isLoading } =
    useGetAllProxyEventsQuery(true);
  const event = events.length ? events[0] : {};

  const eventId = (event as IProxyEvent)?.id || null;
  const { data: trendData } = useGetProxyTimelineTrendQuery(
    eventId ? eventId : skipToken
  );

  const votingStatTrendData = useMemo(
    () => getTrendDataProps(trendData),
    [trendData]
  );
  const {
    sharesVotedChartData = [],
    shareholdersVotedChartData = [],
    sharesVotedBadgeText = "",
    shareholdersVotedBadgeText = "",
  } = votingStatTrendData;

  const {
    proxyEventStats,
    voteSolicitations = [],
    additionalMailings = [],
    meeting = { meetingDate: "" },
    recordDate = "",
    voteCutoff = "",
    title,
    id,
  } = event as IProxyEvent;

  const statsObject = proxyEventStats ? proxyEventStats : {};
  const { sharesVoted, totalShares, shareholdersVoted, totalCustomers } =
    statsObject as IProxyEventStats;
  const formattedMeetingDate = isLoading
    ? undefined
    : dayjs(meeting.meetingDate).format(DATE_FORMAT);
  const formattedRecordDate = isLoading
    ? undefined
    : dayjs(recordDate).format(DATE_FORMAT);

  /**
   * navigate to event
   */
  const navigate = useNavigate();
  const goToEvent = useCallback(() => {
    navigate(`/vote/${id}/`);
  }, [id]);

  const lifeCyclePillProps = useMemo(
    () => getLifecyclePillProps(meeting.meetingDate, voteCutoff),
    [event]
  );

  const eventIconProps = useMemo(
    () => getEventIconProps(voteSolicitations, additionalMailings),
    [event]
  );

  const { isMedium } = useScreenSize();

  if (!isLoading && events.length === 0) {
    return (
      <CardWrapper cardName="no-active-event" sx={{ py: 16 }}>
        <Stack spacing={3} alignItems="center">
          <img
            width={100}
            src={`${process.env.REACT_APP_ASSETS}images/ir-portal/empty_ongoing_proxy_event.png`}
          />
          <Text variant="subtitle1">No active voting event</Text>
          <Text variant="body2">
            Your active voting event will be displayed here
          </Text>
        </Stack>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper cardName="ongoing-event">
      <Stack
        direction={!isMedium ? "column" : "row"}
        spacing={!isMedium ? 8 : 0}
      >
        <Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            {isLoading ? (
              <LoadingSkeleton sx={{ width: "112px", height: "24px" }} />
            ) : (
              <>
                <LifecyclePill {...lifeCyclePillProps} />
                <EventIcon {...eventIconProps} />
              </>
            )}
          </Stack>
          <Text variant="h4" mt={4} loadingProps={{ sx: { width: "286px" } }}>
            {title}
          </Text>
          <Stack direction="row" spacing={12} mt={4}>
            <Stack spacing={2}>
              <Text
                variant="body2"
                emphasis="medium"
                loadingProps={dateTextLoadingProps}
              >
                {isLoading ? undefined : "Meeting date"}
              </Text>
              <Text
                variant="body2"
                emphasis="high"
                loadingProps={dateTextLoadingProps}
              >
                {formattedMeetingDate}
              </Text>
            </Stack>
            <Stack spacing={2}>
              <Text
                variant="body2"
                emphasis="medium"
                loadingProps={dateTextLoadingProps}
              >
                {isLoading ? undefined : "Record date"}
              </Text>
              <Text
                variant="body2"
                emphasis="high"
                loadingProps={dateTextLoadingProps}
              >
                {formattedRecordDate}
              </Text>
            </Stack>
          </Stack>
          {isMedium && (
            <ButtonV2
              data-testid="ongoing-event-view-details-med"
              sx={{
                width: isLoading ? "96px" : "155px",
                mt: "auto",
              }}
              variant="secondary"
              onClick={goToEvent}
            >
              {isLoading ? (
                <CircularProgress
                  size="18px"
                  sx={{ color: COLORS.SAY_COBALT, verticalAlign: "middle" }}
                />
              ) : (
                "View details"
              )}
            </ButtonV2>
          )}
        </Stack>
        <Stack
          direction="row"
          spacing={6}
          ml={!isMedium ? "0" : "auto"}
          py={isMedium ? 2 : 0}
        >
          <VotingStat
            isLoading={isLoading}
            title="Shares voted"
            tooltipText="The total number of shares that Say will submit votes for on behalf of the brokers and banks they service"
            votedCount={sharesVoted}
            total={totalShares}
            badgeText={sharesVotedBadgeText}
            chartData={sharesVotedChartData}
            lineColor={COLORS.DATA_VIZ_DARK_GREEN}
          />
          <VotingStat
            isLoading={isLoading}
            title="Shareholders voted"
            tooltipText="The total number of shareholders that voted their shares at the brokers and banks that Say services"
            votedCount={shareholdersVoted}
            total={totalCustomers}
            badgeText={shareholdersVotedBadgeText}
            chartData={shareholdersVotedChartData}
            lineColor={COLORS.DATA_VIZ_LIGHT_GREEN}
          />
        </Stack>
        {!isMedium && (
          <ButtonV2
            data-testid="ongoing-event-view-details-sm"
            sx={{
              width: isLoading ? "96px" : "155px",
              mt: "auto",
            }}
            variant="secondary"
            onClick={goToEvent}
          >
            {isLoading ? (
              <CircularProgress
                size="18px"
                sx={{ color: COLORS.SAY_COBALT, verticalAlign: "middle" }}
              />
            ) : (
              "View details"
            )}
          </ButtonV2>
        )}
      </Stack>
    </CardWrapper>
  );
};

export default OngoingEvent;
