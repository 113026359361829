import { nFormatter } from "@asayinc/component-library";

const mapping = {
  25: "Negative",
  45: "Slightly Negative",
  50: "Neutral",
  55: "Slightly Positive",
  75: "Positive",
};

// dont see a way around any without adding types to rechart
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const YAxisTick = (props: any) => {
  const { x, y, payload, color, anchor, isLoading, rightTick, useMapping } =
    props;

  if (isLoading) {
    return (
      <g transform={`translate(${rightTick ? x - 13 : x - 20},${y - 9})`}>
        <rect width="34" height="18" rx="8" fill="#E0E0E0" />
      </g>
    );
  }
  const value = (
    useMapping
      ? mapping?.[payload?.value] || "broken"
      : nFormatter(payload?.value)
  ).split(" ") as string[];

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={anchor === "end" ? 5 : -5}
        fontSize={12}
        y={2}
        fontWeight={600}
        textAnchor={anchor}
        fill={color}
      >
        {value.length > 1
          ? value.map((v, i) => (
              <tspan key={v} x="5" y={`${1.2 * i - 0.4}em`}>
                {v}
              </tspan>
            ))
          : value?.[0]}
      </text>
    </g>
  );
};
export default YAxisTick;
