import { Text, nFormatter } from "@asayinc/component-library";
import { TrendingDown, TrendingUp } from "@mui/icons-material";

interface IProps {
  firstValue: number;
  value: number;
}

const StatPercent = ({ firstValue, value }: IProps) => {
  const percent = ((value - firstValue) / firstValue) * 100;
  const color = percent >= 0 ? "#29886B" : "#E74C3C";

  return (
    <>
      <Text variant="body3">(</Text>
      {percent >= 0 ? (
        <TrendingUp sx={{ width: "13px", height: "13px", color }} />
      ) : (
        <TrendingDown sx={{ width: "13px", height: "13px", color }} />
      )}
      <Text variant="body3" sx={{ color }}>
        {nFormatter(percent)}%
      </Text>
      <Text variant="body3">)</Text>
    </>
  );
};

export default StatPercent;
