import { QuestionFilterStatusType, ShareholderType } from "../types/Questions";
import { EventStatusOptions } from "../types/Events";
import { InvestorType } from "../types/Shareholder";

export enum URL_PARAMS {
  page = "page",
  voted = "voted",
  investorType = "investor_type",
  tags = "tag_ids",
  search = "search",
  limit = "limit",
  ordering = "ordering",
  filter = "filter",
  rows = "rows",
  query = "query",
  agg = "agg",
  pageSize = "page_size",
  status = "status",
  offset = "offset",
  type = "type",
  replied = "replied",
  qid = "qid", // question ID
  sid = "sid", //  shareholder id
  eid = "eid", // event id
  tid = "tid", // topic id
  symbol = "symbol",
  location = "location",
  // questions table filter params
  sharesUpvotedMin = "shares_upvoted_min",
  sharesUpvotedMax = "shares_upvoted_max",
  upvoteCountMin = "upvote_count_min",
  upvoteCountMax = "upvote_count_max",
  submittedBefore = "submitted_before",
  submittedAfter = "submitted_after",
  isAnswered = "is_answered",
  shareholderTagIds = "shareholder_tag_ids",
  shareholdersNotTagged = "shareholders_not_tagged",
  specificCategoryIds = "specific_category_ids",
  // participants table filter params
  sharesOwnedMin = "shares_owned_min",
  sharesOwnedMax = "shares_owned_max",
  questionsAskedMin = "questions_asked_min",
  questionsAskedMax = "questions_asked_max",
  questionsAnsweredMin = "questions_answered_min",
  questionsAnsweredMax = "questions_answered_max",
  questionsUpvotedMin = "questions_upvoted_min",
  questionsUpvotedMax = "questions_upvoted_max",
  lastParticipatedBefore = "last_participated_before",
  lastParticipatedAfter = "last_participated_after",
  // upvotes table filter params
  numShareholderTagsMax = "num_shareholder_tags_max",
  // Shareholder Network filter params
  ticker = "ticker",
  country = "country",
  state = "state",
  includesReply = "includes_reply",
}

export const ROW_OPTIONS = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
];

export const ROW_VALUES = ROW_OPTIONS.map((r) => r.value);

export const QUESTION_STATUS_OPTIONS = [
  {
    label: "Is tagged",
    value: QuestionFilterStatusType.Tagged,
  },
  {
    label: "Not tagged",
    value: QuestionFilterStatusType.NotTagged,
  },
  {
    label: "Contains notes",
    value: QuestionFilterStatusType.HasNotes,
  },
  {
    label: "No notes",
    value: QuestionFilterStatusType.NoNotes,
  },
  {
    label: "Favorited",
    value: QuestionFilterStatusType.Favorited,
  },
  {
    label: "Answered",
    value: QuestionFilterStatusType.Answered,
  },
  {
    label: "Published",
    value: QuestionFilterStatusType.Published,
  },
];

export enum RepliedFilterStatusType {
  DidReply = "True",
  DidNotReply = "False",
}

export const REPLIED_STATUS_OPTIONS = [
  {
    label: "Did reply",
    value: RepliedFilterStatusType.DidReply,
  },
  {
    label: "Did not reply",
    value: RepliedFilterStatusType.DidNotReply,
  },
];

export const QUESTION_STATUS_VALUES = QUESTION_STATUS_OPTIONS.map(
  (s) => s.value
);

export const EVENT_STATUS_OPTIONS = [
  {
    label: "Draft",
    value: EventStatusOptions.DRAFT,
  },
  {
    label: "Pending Approval",
    value: EventStatusOptions.PENDING,
  },
  {
    label: "Approved",
    value: EventStatusOptions.APPROVED,
  },
  {
    label: "Published",
    value: EventStatusOptions.PUBLISHED,
  },
  {
    label: "Active",
    value: EventStatusOptions.ACTIVE,
  },
  {
    label: "Completed",
    value: EventStatusOptions.COMPLETED,
  },
];

export const EVENT_STATUS_VALUES: {
  [key: string]: string;
} = EVENT_STATUS_OPTIONS.reduce(
  (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
  {}
);

export const TYPE_OPTIONS = [
  {
    label: "All",
    value: ShareholderType.All,
  },
  {
    label: "Retail",
    value: ShareholderType.Retail,
  },
  {
    label: "Institutional",
    value: ShareholderType.Institutional,
  },
  {
    label: "Analyst",
    value: ShareholderType.Analyst,
  },
];

export const TYPE_VALUES = TYPE_OPTIONS.map((t) => t.value);

export const INVESTOR_TYPE_OPTIONS = [
  {
    label: "Retail",
    value: InvestorType.RETAIL,
  },
  {
    label: "Institutional",
    value: InvestorType.INSTITUTIONAL,
  },
];
