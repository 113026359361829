import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useNavigate,
  useParams,
  BlockerFunction,
  useBlocker,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// data/redux
import { useGetSettingsQuery } from "../store/settings";
import {
  IShareholderComposeMessage,
  initialState,
  useCreateMessageMutation,
  useGetFilterStatsQuery,
  useGetMessageQuery,
  useUpdateMessageMutation,
  useGetTemplateDataQuery,
  selectMessageComposeData,
} from "../store/messageCompose";
import { useGetLeadersQuery } from "../store/companyLeadership";
import { useAppSelector } from "../store/hooks";
// utils
import { useSuccessErrorSnacks } from "./useSuccessErrorSnacks";
import { track } from "../analytics";
import { getDefaultValues, parseForm } from "../utils/messages";
import { parseJSON } from "../utils/parseJson";
// types
import { ISettings } from "../store/settings/types";
import { MessageStep } from "../types/Messages";
import { DuplicateError } from "../pages/Messages/subPages/CreateAndEdit/types";
// constants
import { CREATION_STEP_MAPPING } from "../pages/Messages/subPages/CreateAndEdit/constants";
import { MessageType } from "../store/messageLists";

interface ISessionData {
  id: string;
  step: string;
}

interface IProps {
  isCreate?: boolean;
  sessionKey: string;
  stepMapping: MessageStep[];
}

export const useGetMessageData = ({
  isCreate,
  sessionKey,
  stepMapping,
}: IProps) => {
  const { proxyEventId, messageId = "" } = useParams() as {
    proxyEventId: string;
    messageId: string;
  };

  const navigate = useNavigate();
  // gave saved session data in scenario where users still on same page
  const savedSessionData =
    parseJSON(window.sessionStorage.getItem(sessionKey)) ||
    ({ id: "0", step: "0" } as ISessionData);
  // set initial step in event of a page refresh on same id
  const initialStep =
    savedSessionData?.id === messageId ? parseInt(savedSessionData?.step) : 0;
  // set initial step
  const [currentStep, setStepState] = useState<number>(initialStep || 0);
  const [firstRender, setFirstRender] = useState(true);

  // name of current step for business logic
  const currentStepName = stepMapping[currentStep];

  // company profile data
  const { data: settings = {}, isSuccess: settingsQueryisSuccess } =
    useGetSettingsQuery();
  const { name: companyName = "" } = settings as ISettings;
  // message data
  const {
    data = initialState,
    isLoading: isMessageLoading,
    isSuccess: isMessageSuccess,
    isError: isGetMessageError,
  } = useGetMessageQuery(isCreate ? skipToken : messageId);
  const composeData = data as IShareholderComposeMessage;
  // leadership data
  const {
    data: leaders = [],
    isLoading: isLeadersLoading,
    isSuccess: isLeadersSuccess,
    isError: isGetLeaderError,
  } = useGetLeadersQuery();

  // template data
  const { templateId } = useAppSelector(selectMessageComposeData);
  const { data: templateData } = useGetTemplateDataQuery(
    templateId ? templateId : skipToken
  );
  const templateSubject = templateData?.suggestions?.subject[0];

  const [postMessage, postResult] = useCreateMessageMutation();
  const [updateMessage, updateResult] = useUpdateMessageMutation();

  // useform
  const methods = useForm({
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onTouched",
  });

  const { isValid, isDirty, errors } = methods.formState;

  // get current query from choose recipients
  const query = methods.watch("filterMap.query");

  // parse query to what api understands
  const parsedFormData = useMemo(
    () => parseForm({ filterMap: { query } }, MessageStep.ChooseRecipients),
    [JSON.stringify(query)]
  );

  // get current shareholder count to determine if user can save/go to next page
  const {
    data: { numShareholders } = {
      numShareholders: 0,
    },
    isFetching: isStatsLoading,
    isError: isFilterStatsError,
  } = useGetFilterStatsQuery(
    // dont pull data until after first render and on recipient step to give useWatch a chance to run
    !firstRender && currentStep === 1
      ? {
          query: JSON.stringify(parsedFormData?.filterMap?.query || {}),
          proxyEventId,
        }
      : skipToken
  );

  const isLoading = isLeadersLoading || isMessageLoading;
  const isMessageLoadingOrUpdating =
    isLoading ||
    isStatsLoading ||
    updateResult.isLoading ||
    postResult.isLoading;

  const isSuccess = isMessageSuccess && isLeadersSuccess;

  const getRequestErrorAction = useCallback(() => {
    if (proxyEventId) {
      navigate(`/vote/${proxyEventId}/messages/`);
    } else {
      navigate("/messages");
    }
  }, [proxyEventId]);

  // show success/error snackbar on mutation api callbacks
  const errorMsg = useMemo(
    () =>
      (updateResult?.error as DuplicateError)?.data?.duplicate ||
      (postResult?.error as DuplicateError)?.data?.duplicate
        ? "Please use a campaign name you haven't used yet."
        : "Failed to save message, please try again.",
    [updateResult?.error, postResult?.error]
  );

  useSuccessErrorSnacks({
    successMsg: "Message successfully saved.",
    errorMsg,
    isSuccess: postResult.isSuccess,
    isError: postResult.isError,
  });

  useSuccessErrorSnacks({
    successMsg: "Message successfully saved.",
    errorMsg,
    isSuccess: updateResult.isSuccess,
    isError: updateResult.isError,
  });

  // error snackbars for get api's
  useSuccessErrorSnacks({
    errorMsg: "Failed to load message draft, please try again.",
    isError: isGetMessageError || isGetLeaderError || isFilterStatsError,
    errorAction: getRequestErrorAction,
  });

  /**
   * Reset step to 0 when navigating away from compose flow pages
   */
  useEffect(() => {
    document.title = "Edit Message | Say Issuer Portal";
    setFirstRender(false);
  }, []);

  /**d
   * No id exists on net new messages so update on step/id change instead
   */
  useEffect(() => {
    if (messageId) {
      window.sessionStorage.setItem(
        sessionKey,
        JSON.stringify({ step: currentStep, id: messageId })
      );
    }
    window.scrollTo({ top: 0 });
  }, [currentStep, messageId]);

  /**
   * edit page load analytics
   */
  useEffect(() => {
    if (!isCreate && settingsQueryisSuccess) {
      track({
        name: "Message Campaign Edited",
        client: companyName,
        campaign: composeData.campaignName,
        campaignStatus: composeData.status,
        composeStep: CREATION_STEP_MAPPING[currentStep],
      });
    }
  }, [settingsQueryisSuccess]);

  /**
   * set default values after retrieving message data or after currentStep changes
   */
  useEffect(() => {
    if (!isLoading && isSuccess) {
      methods.reset(getDefaultValues(composeData, currentStepName, leaders), {
        keepErrors: true,
      });
    }
  }, [isLoading, isSuccess, currentStep, leaders.length]);

  /**
   * set default values after posting initial message data and
   * update URL to edit path
   */
  useEffect(() => {
    const { isLoading, isSuccess, data } = postResult;
    if (!isLoading && isSuccess) {
      methods.reset(getDefaultValues(data, currentStepName, leaders), {
        keepErrors: true,
      });
      if (data.type === MessageType.SM) {
        navigate(`/messages/${data.id}/edit`, { replace: true });
      } else {
        navigate(`/vote/${data.proxyEventId}/messages/${data.id}/edit`, {
          replace: true,
        });
      }
    }
  }, [postResult]);

  /**
   * set default values after updating message data
   */
  useEffect(() => {
    const { isLoading, isSuccess, data } = updateResult;
    if (!isLoading && isSuccess) {
      methods.reset(getDefaultValues(data, currentStepName, leaders), {
        keepErrors: true,
      });
    }
  }, [updateResult]);

  // are there any errors
  const hasError = Object.keys(errors).length > 0;

  const disableNext =
    (hasError && !isValid) ||
    isMessageLoadingOrUpdating ||
    // on choose recipients step
    (numShareholders === 0 && currentStep === 1);
  const disableSave = !isDirty || disableNext;

  /**
   * make request to API only when form values have changed
   */
  const updateOrPostMessage = useCallback(
    (nextStep?: number) => {
      // account for 0
      const isNextStep = typeof nextStep === "number";

      // Skip hitting api in below circumstances
      // on choose recipient step but current selections result in 0
      // form is invalid
      // form is not dirty UNLESS on choose recipient step, we need to save that the step was completed
      if (
        (!isDirty && !(currentStep === 1 && !composeData?.filterMap?.query)) ||
        !isValid ||
        (currentStep === 1 && numShareholders === 0)
      ) {
        typeof isNextStep && setStepState(nextStep as number);
        return;
      }
      const formData = methods.getValues();
      const { content } = data;
      const parsedFormDataForSubmit = parseForm(
        formData,
        currentStepName,
        content
      );
      const messageExists = !!messageId.length;

      if (messageExists) {
        updateMessage({ message: parsedFormDataForSubmit, id: messageId })
          .unwrap()
          .then(() => isNextStep && setStepState(nextStep))
          .catch(() => {
            console.error("error updating message");
          });
      } else {
        postMessage({
          ...parsedFormDataForSubmit,
          type: proxyEventId ? MessageType.ProxySM : MessageType.SM,
          proxyEventId,
          // Include template ID and subject in post when templateID is available
          ...(templateId
            ? {
                templateId,
                content: { subject: templateSubject },
              }
            : {}),
        })
          .unwrap()
          .then(() => isNextStep && setStepState(nextStep))
          .catch(() => {
            console.error("error creating message");
          });
      }
    },
    [
      data,
      messageId,
      currentStep,
      isDirty,
      disableSave,
      isValid,
      templateId,
      templateSubject,
    ]
  );

  const goToStep = useCallback(
    (step: number) => {
      updateOrPostMessage(step);
    },
    [updateOrPostMessage]
  );

  const cancel = () => {
    navigate(-1);
  };

  const previous = () => {
    updateOrPostMessage(currentStep - 1);
  };

  const next = () => {
    updateOrPostMessage(currentStep + 1);
  };

  // block navigation
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      // allow navigation from create to edit paths
      if (
        currentLocation.pathname.includes("/create") &&
        nextLocation.pathname.includes("/edit")
      ) {
        return false;
      }
      // allow navigation when message can't be retrieved
      if (isGetMessageError) {
        return false;
      }
      return isDirty && currentLocation.pathname !== nextLocation.pathname;
    },
    [isDirty, isGetMessageError]
  );
  const blocker = useBlocker(shouldBlock);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === "blocked" && !isDirty) {
      blocker.reset();
    }
  }, [blocker, isDirty]);

  return {
    blocker,
    composeData,
    currentStep,
    setStepState,
    isMessageLoadingOrUpdating,
    isLoading,
    methods,
    disableSave,
    disableNext,
    updateOrPostMessage,
    goToStep,
    previous,
    next,
    cancel,
  };
};
