import { memo, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
// components
import { Stack } from "@mui/material";
import { AutocompleteFilter } from "../../../../../../../components/Common";
// redux / data
import { useGetQuestionCategoriesQuery } from "../../../../../../../store/questionCategories";
// constants
import { URL_PARAMS } from "../../../../../../../constants";

const FilterList = () => {
  const { eventSlug } = useParams() as { eventSlug: string };
  const { data: categoryOptions = [], isLoading: isCategoryDataLoading } =
    useGetQuestionCategoriesQuery(eventSlug);

  const options = useMemo(
    () =>
      categoryOptions.map(({ id, name }) => ({
        name,
        id,
        value: id,
      })),
    [categoryOptions]
  );

  const methods = useForm({ criteriaMode: "all" });

  return (
    <Stack direction="row" mt={2} width="100%" justifyContent="flex-end">
      <FormProvider {...methods}>
        <AutocompleteFilter
          filterParam={URL_PARAMS.specificCategoryIds}
          options={options}
          optionsLimit={categoryOptions.length}
          fieldName="questionCategories"
          chipLabel="Question topics"
          groupLabel="Question topics"
          isLoading={isCategoryDataLoading}
        />
      </FormProvider>
    </Stack>
  );
};

export default memo(FilterList);
