import { IButtonAction, pluralize } from "@asayinc/component-library";
// redux
import { useExportTopicsMutation } from "../../../../../../../store/sentiment";
// router
import { useParams } from "react-router-dom";
import { BasicOverlay } from "../../../../../../../components/Common";

interface Props {
  open: boolean;
  handleClose: (
    type?: "download",
    _?: undefined,
    _2?: unknown,
    didSave?: boolean,
    tagCount?: number
  ) => void;
  topicIds: string[];
}

const ExportTopics = ({ open, handleClose, topicIds }: Props): JSX.Element => {
  const [getExportedTopics] = useExportTopicsMutation();
  const { eventSlug } = useParams() as { eventSlug: string };

  /**
   * call api to download the csv
   */
  const exportTopics = () => {
    getExportedTopics({
      topicIds,
      eventSlug,
    });

    handleClose("download", undefined, undefined, true);
  };

  const actions = [
    {
      onClick: handleClose,
      label: "Cancel",
      variant: "secondary",
    },
    {
      onClick: exportTopics,
      label: "Export",
    },
  ] as IButtonAction[];

  const allTopicsContent = "Export all topics to a CSV?";
  const selectedTopicsContent = `Export ${topicIds.length} ${pluralize(
    "topic",
    "topics",
    topicIds.length
  )} to a CSV?`;
  const content =
    topicIds.length === 0 ? allTopicsContent : selectedTopicsContent;

  return (
    <BasicOverlay
      open={open}
      buttonActions={actions}
      handleClose={handleClose}
      ctaOrientation="horizontal"
      title="Export Topics"
      content={content}
    />
  );
};

export default ExportTopics;
