import { useParams } from "react-router-dom";
import { initialState, useGetEventQuery } from "../../store/event";
import { EventStatusOptions } from "../../types/Events";

interface QuestionPageData {
  publishDatetime: string | null;
  title: string;
  eventStartDatetime: string | null;
  status: EventStatusOptions;
  isLoading: boolean;
}

export const useQuestionsPageData = (): QuestionPageData => {
  const { eventSlug } = useParams() as {
    eventSlug: string;
  };
  const { data: event = initialState, isLoading } = useGetEventQuery(eventSlug);
  const { title, publishDatetime, eventStartDatetime, status } = event;

  return {
    publishDatetime,
    eventStartDatetime,
    title,
    status,
    isLoading,
  };
};
