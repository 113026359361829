import { Box } from "@mui/material";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CustomCursor, ShareholderTrendGraphTooltip } from "./components";
import {
  ActiveDot,
  CustomReferenceDot,
  CustomizedDot,
  XAxisTick,
  renderLoadingLegend,
  renderLegend,
  YAxisTick,
} from "../../../../../../../components/Graphs";
import { COLORS } from "@asayinc/component-library";
import {
  IGraphDotStat,
  IGraphDotStats,
} from "../../../../../../../components/Graphs/types";
import { useMemo, useState } from "react";
import { EventPopover } from "../../Molecules";
import { IGraphDataPoint } from "./types";

interface IProps {
  isLoading: boolean;
  graphData: IGraphDataPoint[];
  timeframe: "quarter" | "month";
  graphEvents: IGraphDotStats;
}

export interface IActiveDotState extends IGraphDotStat {
  anchorEl: SVGSVGElement;
}

const TrendGraph = ({
  isLoading,
  graphData,
  timeframe,
  graphEvents,
}: IProps) => {
  // check for if last day of timeframe so that we dont show reference dots if last day of the timeframe
  const isGraphFull = !!graphData?.[graphData.length - 1]?.top50;
  const [activeEvent, setActiveEvent] = useState<IActiveDotState | null>(null);
  const open = Boolean(activeEvent?.anchorEl);
  // either show 7 or 14 day increments
  const ticks = graphData
    .filter((_, index) => index % (timeframe === "quarter" ? 14 : 7) == 0)
    .map((entry) => entry.name);

  // display popover when mousing over customized dot
  const handleMouseEnter = (
    event: React.MouseEvent<SVGSVGElement>,
    dotStats: IGraphDotStat
  ) => {
    setActiveEvent({
      ...dotStats,
      anchorEl: event.currentTarget,
    });
  };

  // close popover
  const handleClose = () => {
    setActiveEvent(null);
  };

  // get first value set with non null values
  const firstValueSet = useMemo(() => {
    const validValue = graphData?.find((itm) => !!itm?.top50);
    return {
      "Top 50": validValue?.top50,
      "Top 100": validValue?.top100,
      "Top 500": validValue?.top500,
    };
  }, [graphData]);

  // get data needed to plot the animated circle at the end of each line
  const { ongoingTop50, ongoingTop100, ongoingTop500, lastGraphDataPoint } =
    useMemo(() => {
      if (isLoading) {
        return {};
      }
      // find last entry with defined data
      let lastGraphDataPoint = 0;
      for (let i = graphData.length - 1; i >= 0; i--) {
        lastGraphDataPoint = i;
        if (!!graphData[i]?.top500) {
          break;
        }
      }
      const ongoingTop50 = graphData[lastGraphDataPoint]?.top50 as number;
      const ongoingTop100 = graphData[lastGraphDataPoint]?.top100 as number;
      const ongoingTop500 = graphData[lastGraphDataPoint]?.top500 as number;

      return {
        ongoingTop50,
        ongoingTop100,
        ongoingTop500,
        lastGraphDataPoint: graphData[lastGraphDataPoint]?.name,
      };
    }, [graphData, isLoading]);

  return (
    <Box
      height="352px"
      data-testid="trend-graph"
      sx={{ svg: { overflow: "visible" } }}
    >
      <EventPopover
        open={open}
        handleClose={handleClose}
        activeEvent={activeEvent}
      />
      <ResponsiveContainer width="100%" height="95%">
        <LineChart
          data={graphData}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          key={graphData?.length}
        >
          <Legend
            iconType="circle"
            iconSize={isLoading ? 0 : 8}
            margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
            align="right"
            height={50}
            formatter={isLoading ? renderLoadingLegend : renderLegend}
            verticalAlign="top"
          />
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            padding={{ left: 20, right: isLoading ? 20 : 0 }}
            axisLine={false}
            tickLine={false}
            tick={<XAxisTick isLoading={isLoading} />}
            domain={isLoading ? [1, 6] : undefined}
            ticks={isLoading ? [1, 2, 3, 4, 5, 6] : ticks}
            type={isLoading ? "number" : undefined}
            tickMargin={15}
          />
          <YAxis
            yAxisId="left"
            axisLine={false}
            tick={
              <YAxisTick
                color={COLORS.P_MED_LOW_EMPHASIS}
                anchor="end"
                isLoading={isLoading}
              />
            }
            tickLine={false}
            tickMargin={12}
            domain={isLoading ? [1, 5] : undefined}
            ticks={isLoading ? [1, 2, 3, 4, 5] : undefined}
            width={50}
          />
          <Tooltip
            content={
              <ShareholderTrendGraphTooltip firstValueSet={firstValueSet} />
            }
            cursor={<CustomCursor />}
            position={{ y: -60 }}
            wrapperStyle={{ left: -90 }}
          />
          <Line
            yAxisId="left"
            type="monotone"
            activeDot={<ActiveDot graphEventStats={graphEvents} />}
            dot={
              <CustomizedDot
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleClose}
                graphEventStats={graphEvents}
              />
            }
            dataKey="top50"
            name="Top 50"
            connectNulls
            stroke={COLORS.DATA_VIZ_LIGHT_GREEN}
            strokeWidth={3}
          />
          <Line
            yAxisId="left"
            strokeWidth={3}
            activeDot={<ActiveDot graphEventStats={graphEvents} />}
            dot={
              <CustomizedDot
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleClose}
                graphEventStats={graphEvents}
              />
            }
            type="monotone"
            dataKey="top100"
            connectNulls
            name="Top 100"
            stroke={COLORS.DATA_VIZ_GIGAS}
          />
          <Line
            yAxisId="left"
            strokeWidth={3}
            activeDot={<ActiveDot graphEventStats={graphEvents} />}
            dot={
              <CustomizedDot
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleClose}
                graphEventStats={graphEvents}
              />
            }
            type="monotone"
            dataKey="top500"
            connectNulls
            name="Top 500"
            stroke={COLORS.DATA_VIZ_DARK_GREEN}
          />
          {!isLoading && !isGraphFull ? (
            <>
              <ReferenceDot
                isFront
                x={lastGraphDataPoint}
                y={ongoingTop50}
                yAxisId="left"
                ifOverflow="visible"
                fill={COLORS.DATA_VIZ_LIGHT_GREEN}
                shape={CustomReferenceDot}
                r={20}
              />

              <ReferenceDot
                isFront
                x={lastGraphDataPoint}
                y={ongoingTop100}
                yAxisId="left"
                ifOverflow="visible"
                fill={COLORS.DATA_VIZ_GIGAS}
                shape={CustomReferenceDot}
                r={20}
              />
              <ReferenceDot
                isFront
                x={lastGraphDataPoint}
                y={ongoingTop500}
                yAxisId="left"
                ifOverflow="visible"
                fill={COLORS.DATA_VIZ_DARK_GREEN}
                shape={CustomReferenceDot}
                r={20}
              />
            </>
          ) : null}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default TrendGraph;
