const CustomReferenceDot = (props: {
  cx: number;
  cy: number;
  fill: string;
}) => (
  <circle cx={props.cx} r="10" cy={props.cy} fill={props.fill} opacity={0.2}>
    <animate
      attributeName="r"
      from="4"
      to="10"
      dur="2s"
      begin="0s"
      repeatCount="indefinite"
    />
  </circle>
);

export default CustomReferenceDot;
