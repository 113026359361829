import { useState } from "react";
import { ButtonV2, IButtonAction, Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useRequestMoreInfo } from "../../../../../hooks";
import { UiEventActions } from "../../../../../store/consumerUser/types";
import { ViewSampleDialog } from "../../../../../components/Common";

interface IProps {
  count: number;
}

const NoResults = ({ count }: IProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  // track request more info
  const { requestMoreInfo } = useRequestMoreInfo({
    action: UiEventActions.RequestInfoQA,
  });

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const requestInfo = () => {
    toggleDialog();
    requestMoreInfo();
  };

  // dialog button actions
  const buttonActions = [
    {
      label: "Request info",
      onClick: requestInfo,
      variant: "primary",
    },
  ] as IButtonAction[];

  return (
    <Stack
      height="100%"
      alignItems="center"
      justifyContent="center"
      flex={1}
      pb={10}
    >
      <img
        width={400}
        src={`${process.env.REACT_APP_ASSETS}images/broker-portal/empty_no_results.svg`}
      />
      <Text variant="subtitle1" sx={{ mb: 3 }}>
        {count
          ? "Host two Q&A events to reveal Trends"
          : "No Q&As Hosted Recently"}
      </Text>
      <Text variant="body1" mb={6}>
        {count
          ? "Uncover shareholder trends like popular topics by hosting 1 more Q&A event"
          : "Uncover shareholder trends like popular topics by hosting Q&A events"}
      </Text>
      <ButtonV2 data-testid="fab-view-sample" onClick={toggleDialog}>
        View Sample
      </ButtonV2>
      <ViewSampleDialog
        open={isDialogOpen}
        title="Shareholder trends - sample"
        description="View a sample of shareholder trends, after your host Q&A events on Say"
        img="imgs/samples/example-hp-trend.png"
        toggleDialog={toggleDialog}
        buttonActions={buttonActions}
        footerText="For each Q&A event, Say automatically categorizes questions by topic and creates an AI-based sentiment score based on the question tone. With trends, you can see these topics' sentiment change over time."
      />
    </Stack>
  );
};

export default NoResults;
