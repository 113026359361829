export enum Emojis {
  ThumbsUp = "&#128077;",
  ThumbsDown = "&#128078;",
  GrinningFace = "&#128512;",
  CryingFace = "&#128546;",
  ClappingHands = "&#128079;",
  RedHeart = "&#10084;",
  ThinkingFace = "&#129300;",
  PoutingFace = "&#128545;",
  SlightlySmilingFace = "&#128578",
  NeutralFace = "&#128528",
  WorriedFace = "&#128543",
  DizzyFace = "&#128565",
}

export const FEEDBACK_EMOJI_MAP = {
  "very-satisfied": {
    emoji: Emojis.GrinningFace,
    text: "Very satisfied",
  },
  satisfied: {
    emoji: Emojis.SlightlySmilingFace,
    text: "Satisfied",
  },
  neutral: {
    emoji: Emojis.NeutralFace,
    text: "Neutral",
  },
  dissatisfied: {
    emoji: Emojis.WorriedFace,
    text: "Dissatisfied",
  },
  "very-dissatisfied": { emoji: Emojis.DizzyFace, text: "Very dissatisfied" },
};
