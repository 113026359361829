// dont see a way around any without adding types to rechart

import { Text } from "@asayinc/component-library";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const XAxisTick = (props: any) => {
  const { x, y, payload, isLoading } = props;

  if (isLoading) {
    return (
      <g transform={`translate(${x - 20},${y - 4})`}>
        <rect width="44" height="18" rx="8" fill="#E0E0E0" />
      </g>
    );
  }

  // foreignObject instead of <text to support text wrapping
  return (
    <g transform={`translate(${x},${y})`}>
      <foreignObject x={-40} y={0} width={80} height={65}>
        <Text
          variant="subtitle3"
          emphasis="medium_low"
          component="p"
          sx={{ wordWrap: "break-word" }}
          textAlign="center"
        >
          {payload.value}
        </Text>
      </foreignObject>
    </g>
  );
};
export default XAxisTick;
