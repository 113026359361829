import { useEffect } from "react";
// material
import { Stack } from "@mui/material";
// components
import QABarChart from "./QABarChart";
import { COLORS, Text } from "@asayinc/component-library";
import { useGetQATrendsQuery } from "../../../../../store/eventStats";

export const TabValues = {
  totalSharesUpvoted: {
    title: "Total Shares",
    description: "by shareholders who participated in a given event",
  },
  totalViews: {
    title: "Total Views",
    description: "by shareholders for each event, since August 10, 2022",
  },
  questionsAsked: {
    title: "Total Questions Asked",
    description: "Total number of questions asked for each event",
  },
};

const QAOverTime = (): JSX.Element | null => {
  useEffect(() => {
    document.title = "Events | Say Issuer Portal";
  }, []);

  const { data, isLoading } = useGetQATrendsQuery();

  const show = !!data?.length && !isLoading;

  return (
    <>
      <Stack maxWidth={1440} m={"0 auto"} height="100%" gap={2}>
        <Text variant="subtitle2" mb={4}>
          Q&As Over Time
        </Text>
        {!show && (
          <Text variant="body2" mt={1} mb={3}>
            * No Q&amp;A&apos;s available at this time
          </Text>
        )}
        {show && (
          <Stack gap={8}>
            <QABarChart
              graphData={data ? data : []}
              barColor={COLORS.DATA_VIZ_AZURE}
              field="totalSharesUpvoted"
            />
            <QABarChart
              graphData={data ? data : []}
              barColor={COLORS.DATA_VIZ_BLUE}
              field="totalViews"
            />
            <QABarChart
              graphData={data ? data : []}
              barColor={COLORS.DATA_VIZ_DARK_GREEN}
              field="questionsAsked"
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default QAOverTime;
