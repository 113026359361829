import { HookImageUploadAndCrop } from "@asayinc/component-library";
import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormSetError,
  UseFormRegister,
} from "react-hook-form";
import { postImage } from "../../../../store/commonReducer";
import { useAppDispatch } from "../../../../store/hooks";
import { setLoading } from "../../../../store/commonReducer";

interface Props {
  name: string;
  type: "company" | "leader" | "asset";
  size?: "small" | "medium" | "large";
  width: number;
  height: number;
  description?: string;
  circularCrop?: boolean;
  control?: Control<FieldValues>;
  fileNameField: string;
  disabled?: boolean;
  defaultValue?: string;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
}

const FormImageUpload = ({
  name,
  type,
  setValue,
  setError,
  register,
  required = true,
  fileNameField,
  defaultValue,
  ...rest
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  /**
   * upload image and update hook-form with image path response
   */
  /* istanbul ignore next */ // ignored in tests for now
  const upload = async (image: Blob) => {
    try {
      dispatch(setLoading(true));
      const {
        data: { filename, url },
      } = await postImage(image, `${name}.png`, type);
      dispatch(setLoading(false));
      // update main field to use cdn img address
      setValue(name, url);
      // update form to use filename for posting an updated value
      setValue(fileNameField, filename, { shouldDirty: true });
    } catch (err) {
      dispatch(setLoading(false));
      // update main field to be empty due to failure to upload
      setValue(name, "");
      // update filename to be empty due to failure to upload
      setValue(fileNameField, "", { shouldDirty: true });
      setError(name, {
        type: "api",
        message: "Failed to process image, please re-upload",
      });
    }
  };

  return (
    <>
      <input type="hidden" {...register(fileNameField)} />
      <HookImageUploadAndCrop
        name={name}
        callback={upload}
        defaultValue={defaultValue}
        whiteFill
        rules={{
          required: {
            value: required,
            message: "Image is required.",
          },
        }}
        {...rest}
      />
    </>
  );
};

export default FormImageUpload;
