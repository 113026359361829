import { INoboCheck, IUiEvent, IUser, UiEventActions } from "./types";
import { rootApi } from "../rootApi";

export const consumerUserApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /** check if nobo requested */
    getNoboStatus: builder.query<INoboCheck, void>({
      query: () => `nobo-check/`,
      providesTags: ["NoboCheck"],
    }),
    /** get user info */
    getUserInfo: builder.query<IUser, void>({
      query: () => `${process.env.REACT_APP_CONSUMER_API}v3/users/`,
      providesTags: ["UserInfo"],
    }),
    /** track user event */
    trackEvent: builder.mutation<void, IUiEvent>({
      query: (event) => ({
        url: "/ui-events/",
        method: "POST",
        body: event,
      }),
      invalidatesTags: (_response, _err, arg) => {
        const invalidateActions = [
          // modals
          UiEventActions.CloseModalUpsellGeneral,
          UiEventActions.CloseModalUpsellMessages,
          UiEventActions.CloseModalUpsellShareholderNetwork,
          UiEventActions.CloseModalUpsellVoteMessages,
          // tabs
          UiEventActions.TabClickMessages,
          UiEventActions.TabClickVoteMessages,
          UiEventActions.TabClickShareholderNetwork,
        ];
        const invalidateUserInfo = invalidateActions.includes(arg.action);
        if (invalidateUserInfo) {
          ["UserInfo"];
        } else if (arg.action === UiEventActions.AcceptNobo) {
          return ["NoboCheck"];
        }
        return [];
      },
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useTrackEventMutation,
  useGetNoboStatusQuery,
} = consumerUserApi;
