import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// Say
import {
  setAuthToken,
  LogIn as SayLogIn,
  isLoggedIn,
  REFRESH_TOKEN,
} from "@asayinc/component-library";
// redux/data
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectPrevPage } from "../../store/commonReducer";
import { useAttemptLoginMutation } from "../../store/ssoUser";
// analytics
import { identify, track } from "../../analytics";
import { rootApi } from "../../store/rootApi";
import { useTrackEventMutation } from "../../store/consumerUser";
import { UiEventActions, UiEventTypes } from "../../store/consumerUser/types";

interface FormFields {
  email: string;
  password: string;
}

/**
 * Maps RTK Query status to string values used in Say Login Component
 */
const loginStatusMap = {
  uninitialized: "idle",
  pending: "loading",
  rejected: "rejected",
};

const Login = (): JSX.Element => {
  const [trackEvent] = useTrackEventMutation();
  const isUserLoggedIn = !!isLoggedIn();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const prevPage = useAppSelector(selectPrevPage);
  const [login, loginResult] = useAttemptLoginMutation();
  const loginStatus = loginStatusMap[loginResult.status];

  const redirectUrl = prevPage || "/";

  useEffect(() => {
    if (loginResult.isSuccess) {
      const { user, token, refreshToken } = loginResult.data;
      window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
      setAuthToken({ value: token });
      identify({
        id: user.pk,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
      });
      trackEvent({
        action: UiEventActions.Login,
        type: UiEventTypes.Click,
        sourceUrl: location.href,
        targetUrl: null,
      });
      track({ name: "Login Attempted", success: true });
    } else if (loginResult.isError) {
      track({ name: "Login Attempted", success: false });
    }
  }, [loginResult]);

  useEffect(() => {
    document.title = "Sign In | Say Issuer Portal";
    // invalidate all cache whenever someone lands on login page
    dispatch(rootApi.util.resetApiState());
  }, []);

  /**
   * handle when forgot password is clicked
   */
  const handleForgotPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate("/forgot-password");
  };

  /**
   * use form data to hit login api
   */
  const attemptLogin = (data: FormFields) => {
    login(data);
  };

  if (isUserLoggedIn || loginResult.isSuccess) {
    return <Navigate to={redirectUrl} replace />;
  }

  return (
    <SayLogIn
      login={attemptLogin}
      loginState={loginStatus}
      title="Sign in to Issuer Portal"
      goToForgotPassword={handleForgotPassword}
      bgColor="none"
    />
  );
};

export default Login;
