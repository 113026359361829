export interface ISecurityPrice {
  price: number;
}

export interface IEventOverViewStat {
  totalEvents: number;
  totalSharesParticipated: number;
  avgSharesParticipated: number;
}

export interface IMessageOverViewStat {
  totalSharesOpened: number;
  totalMessages: number;
  avgSharesOpened: number;
}
export interface IOverviewStats {
  qaEvents: IEventOverViewStat;
  messages: IMessageOverViewStat;
}

export enum AlertType {
  TopicTrending = "topic_trending",
  TopicSentimentDecrease = "topic_sentiment_decrease",
  TopicSentimentIncrease = "topic_sentiment_increase",
  TopicNewPopular = "topic_new_popular",
  QATopSay = "q_and_a_top_say",
}

export interface IAlert {
  text: string;
  type: AlertType;
  id: string;
  expiresAt?: string;
  relativeLink: string;
}
