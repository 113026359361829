import { Text } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { track } from "../../../../analytics";
import { CREATION_STEP_MAPPING } from "../../../../pages/Messages/subPages/CreateAndEdit/constants";
import {
  initialState,
  useGetMessageQuery,
} from "../../../../store/messageCompose";
import { useParams } from "react-router-dom";
import { ISettings } from "../../../../store/settings/types";
import { useGetSettingsQuery } from "../../../../store/settings";
import { ImportantLinksEditor } from "./components";

const ImportantLinks = () => {
  const { messageId } = useParams();

  // pull company name
  const { data: settings = {} } = useGetSettingsQuery();
  const { name: companyName = "" } = settings as ISettings;
  const { data: { campaignName } = initialState, isLoading } =
    useGetMessageQuery(messageId as string);

  /** ANALYTICS TRACKING */
  const onAddLink = () => {
    track({
      name: "Add Important Link",
      client: companyName,
      campaign: campaignName,
      composeStep: CREATION_STEP_MAPPING[2],
    });
  };

  const onEditLink = () => {
    track({
      name: "Edit Important Link",
      client: companyName,
      campaign: campaignName,
      composeStep: CREATION_STEP_MAPPING[2],
    });
  };
  /** END ANALYTICS */

  return (
    <Stack>
      <Box>
        <Text variant="subtitle1" mb={2}>
          Important Links
        </Text>
        <Text variant="body2">
          Add additional information for your shareholders in question and
          answer format. (Optional)
        </Text>
      </Box>
      <Box mt={6}>
        <ImportantLinksEditor
          isLoading={isLoading}
          addCallback={onAddLink}
          editCallback={onEditLink}
        />
      </Box>
    </Stack>
  );
};

export default ImportantLinks;
