// components
import { Link, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import {
  COLORS,
  ChipV2 as Chip,
  SayIcon,
  Text,
  Tooltip,
} from "@asayinc/component-library";
import TopicCard from "./TopicCard";
import { Carousel } from "../../../../../../../components/Common";
import { SwiperSlide } from "swiper/react";
// redux / data
import { useGetSentimentQuery } from "../../../../../../../store/sentiment";
import { SORT, SORT_SYMBOL } from "../../../../../../../constants";
import { AI_TOOLTIP_TITLE } from "../../../../../../../constants/text";

const initialState = {
  results: [],
  count: 0,
  next: null,
  previous: null,
  ids: [],
};

const TrendingTopics = () => {
  const { eventSlug } = useParams() as { eventSlug: string };
  const { data = initialState } = useGetSentimentQuery({
    eventSlug,
    params: {
      limit: "5",
      ordering: `${SORT_SYMBOL.desc}${SORT.sharesOwned}`,
    },
  });

  if (!data?.results?.length) {
    return null;
  }
  return (
    <Stack gap={6}>
      <Stack direction="row" alignItems="center">
        <Text variant="subtitle1">Trending topics</Text>
        <Tooltip title={AI_TOOLTIP_TITLE}>
          <Chip
            icon={
              <SayIcon name="Sparkles" size="14px" color={COLORS.SAY_COBALT} />
            }
            variant="secondary"
            selected={true}
            label="Powered by AI"
            sx={{ ml: 2.5 }}
          />
        </Tooltip>
        <Link
          to={`/qa/${eventSlug}/topics`}
          style={{ marginLeft: "auto", textDecoration: "none" }}
        >
          <Text variant="subtitle2" shade="say" sx={{ ml: "auto" }}>
            See all
          </Text>
        </Link>
      </Stack>
      <Carousel slidesPerClick={1}>
        {data?.results?.map(
          ({ id, name, sharesOwned, sentiment, bestQuestion }, i) => (
            <SwiperSlide key={id}>
              <TopicCard
                rank={`#${i + 1}`}
                id={id}
                name={name}
                sharesOwned={sharesOwned}
                bestQuestion={bestQuestion}
                sentiment={sentiment}
              />
            </SwiperSlide>
          )
        )}
      </Carousel>
    </Stack>
  );
};

export default TrendingTopics;
