import { HookChipGroup, Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { EVENT_SORT_ORDER_OPTIONS } from "../../../../../constants";

interface IProps {
  isLoading?: boolean;
}

const FIELD_NAME = "sortOrder";

const SortOrderForm = ({ isLoading }: IProps) => {
  return (
    <Stack>
      <Stack>
        <Text variant="subtitle1" sx={{ mb: 2 }}>
          Shareholder question default sort order
        </Text>
        <Text variant="body2">
          Select your preferred way to display the questions on your Q&amp;A
          page. Say&apos;s default is the Recommended sort option.
        </Text>
      </Stack>
      <HookChipGroup
        disabled={isLoading}
        options={EVENT_SORT_ORDER_OPTIONS}
        fieldName={FIELD_NAME}
        listStyles={{ flexDirection: "row", flexWrap: "wrap" }}
        containerStyles={{ mt: 6, mr: 3 }}
      />
    </Stack>
  );
};

export default SortOrderForm;
