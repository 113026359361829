import { Stack } from "@mui/material";
import { Person, InsertInvitation, Email } from "@mui/icons-material";
import { Text, nFormatter } from "@asayinc/component-library";
import { StatCardVariant2 } from "../../../../../components/Common";
import {
  useGetAllShareholderStatsQuery,
  useGetTopShareholderTrendsQuery,
} from "../../../../../store/allShareholderStats";
import { useGetOverviewStatsQuery } from "../../../../../store/commonApi";
import { useGetSettingsQuery } from "../../../../../store/settings";
import { ISettings } from "../../../../../store/settings/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import dayjs from "dayjs";
import { useIsShareholdersEnabled } from "../../../../Shareholders/useIsShareholdersEnabled";

// style for all stat cards
const sx = { flex: "1 1 0px", minWidth: "none" };

const IssuerSummaryCards = () => {
  // is shareholder feature enabled
  const { isFeatureEnabled: isShareholderEnabled } = useIsShareholdersEnabled();
  const { data: settings = {} } = useGetSettingsQuery();
  // get top shareholder data to see most recent date
  const { data: topData, isLoading: isTopShareholderLoading } =
    useGetTopShareholderTrendsQuery(isShareholderEnabled ? {} : skipToken);
  const { name: companyName = "" } = settings as ISettings;
  // get overview data for QA events and messages
  const { data: overviewData, isFetching: isOverviewFetching } =
    useGetOverviewStatsQuery();
  // get stat data
  const { data: stats, isFetching: isStatsFetching } =
    useGetAllShareholderStatsQuery({
      aggregators: "percentiles,value_count,sum",
    });

  // as of date for shareholder data
  const dateString =
    isShareholderEnabled && topData?.top50?.[0]?.recordDate
      ? dayjs(topData?.top50?.[0]?.recordDate).format("MMMM D")
      : "last proxy filing";

  return (
    <Stack gap={6} pt={6}>
      <Text variant="subtitle1">{companyName}&apos;s retail engagement</Text>
      <Stack direction="row" gap={8} pb={12}>
        <StatCardVariant2
          cardName="homepage-stat"
          id="qa-events-stat"
          icon={<InsertInvitation />}
          isLoading={isOverviewFetching}
          title="Q&A Events"
          tooltipText="Average of all shares who viewed or participated, per event on Say"
          leftStatTitle="Total events"
          leftStat={nFormatter(overviewData?.qaEvents?.totalEvents || 0)}
          rightStatTitle="Average shares reached"
          rightStat={nFormatter(
            overviewData?.qaEvents?.avgSharesParticipated || 0
          )}
          sx={sx}
        />
        <StatCardVariant2
          icon={<Email />}
          id="messages-stat"
          isLoading={isOverviewFetching}
          cardName="homepage-stat"
          title="Messages"
          tooltipText="Average opened shares across all messages to shareholders"
          leftStatTitle="Total sent"
          leftStat={nFormatter(overviewData?.messages?.totalMessages || 0)}
          rightStatTitle="Average opened shares"
          rightStat={nFormatter(overviewData?.messages?.avgSharesOpened || 0)}
          sx={sx}
        />
        <StatCardVariant2
          icon={<Person />}
          id="shareholders-stat"
          isLoading={isStatsFetching || isTopShareholderLoading}
          cardName="homepage-stat"
          title="Shareholders & Shares"
          tooltipText={
            isTopShareholderLoading
              ? ""
              : `Current Value as of ${dateString}: $${nFormatter(
                  stats?.sumValue || 0
                )}`
          }
          leftStatTitle="Shareholders"
          leftStat={nFormatter(stats?.valueCount || 0)}
          rightStatTitle="Share count"
          rightStat={nFormatter(stats?.sum || 0)}
          sx={sx}
        />
      </Stack>
    </Stack>
  );
};

export default IssuerSummaryCards;
