// components
import {
  CartesianGrid,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  Rectangle,
} from "recharts";
import { COLORS, Text } from "@asayinc/component-library";
import { XAxisTick, YAxisTick } from "../../../../../components/Graphs";
import CustomTooltip from "./CustomTooltip";
import { IParsedEventTrends } from "../../../../../store/eventStats";
import { lighten } from "@mui/system";
import { Stack } from "@mui/material";
import { TabValues } from "./QAOverTime";

interface IProps {
  graphData: IParsedEventTrends[];
  barColor: string;
  field: string;
}

const QAInsightsLine = ({
  graphData,
  barColor,
  field,
}: IProps): JSX.Element => {
  return (
    <Stack height="500px" direction="row" sx={{ svg: { overflow: "visible" } }}>
      <Stack gap={2} width={240} minWidth={240} flexGrow={1}>
        <Text variant="subtitle2">{TabValues[field].title}</Text>
        <Text variant="body3" width={176}>
          {TabValues[field].description}
        </Text>
      </Stack>
      <ResponsiveContainer width="100%" height="95%">
        <BarChart
          data={graphData}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          key={graphData?.length}
        >
          <Tooltip content={<CustomTooltip />} wrapperStyle={{ left: -90 }} />
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <Bar
            dataKey={field}
            maxBarSize={20}
            fill={barColor}
            activeBar={
              <Rectangle fill={lighten(barColor, 0.2)} stroke={barColor} />
            }
            yAxisId="left"
          />
          <XAxis
            dataKey="name"
            padding={{ left: 20, right: 0 }}
            axisLine={false}
            tickLine={true}
            tick={<XAxisTick isLoading={false} />}
            tickMargin={7}
            tickCount={6}
            interval={0}
          />

          <YAxis
            yAxisId="left"
            orientation="left"
            tickMargin={12}
            type="number"
            tickCount={10}
            tickLine={false}
            tick={
              <YAxisTick
                color={COLORS.P_MED_LOW_EMPHASIS}
                anchor="end"
                isLoading={false}
                rightTick={true}
              />
            }
            axisLine={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
};

export default QAInsightsLine;
