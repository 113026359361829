import { HookChipGroup, Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { EVENT_TYPE_SELECT_OPTIONS } from "../../../../../constants";
import { useGetSettingsQuery } from "../../../../../store/settings";
import { ISettings } from "../../../../../store/settings/types";
import { EventTypeOptions } from "../../../../../types/Events";
import { removeSpecialChars } from "../../../helpers/getMetaData";
import {
  EarningsCallFields,
  InvestorDayFields,
  ProductLaunchFields,
  ShareholderMeetingFields,
  WebinarFields,
} from "../../Molecules";

interface IProps {
  isLoading?: boolean;
  isDraft?: boolean;
}

const FIELD_NAME = "eventType";

const slugLimit = 50;

const EventTypeForm = ({ isLoading, isDraft }: IProps) => {
  const { data: settings = {} } = useGetSettingsQuery();
  const { name: companyName = "" } = settings as ISettings;

  // character limit for custom input fields for generating a slug.
  // slugs are `${companyName}-${customText}`
  // max is limit (50) - 1 (dash) - companyName.length
  const charLimit = useMemo(
    () => slugLimit - 1 - removeSpecialChars(companyName).length,
    [companyName]
  );

  const eventType = useWatch({
    name: FIELD_NAME,
  });

  /**
   * Based on eventType selection, different associated fields display
   */
  const associatedComponent = useMemo(() => {
    switch (eventType) {
      case EventTypeOptions.EARNINGS_CALL:
        return <EarningsCallFields isDisabled={isLoading || !isDraft} />;
      case EventTypeOptions.INVESTOR_DAY:
        return (
          <InvestorDayFields
            isDisabled={isLoading || !isDraft}
            charLimit={charLimit}
            companyName={companyName}
          />
        );
      case EventTypeOptions.PRODUCT_LAUNCH:
        return (
          <ProductLaunchFields
            isDisabled={isLoading || !isDraft}
            charLimit={charLimit}
            companyName={companyName}
          />
        );
      case EventTypeOptions.SHAREHOLDER_MEETING:
        return <ShareholderMeetingFields isDisabled={isLoading || !isDraft} />;
      case EventTypeOptions.WEBINAR:
        return (
          <WebinarFields
            isDisabled={isLoading || !isDraft}
            charLimit={charLimit}
            companyName={companyName}
          />
        );
      default:
        return null;
    }
  }, [eventType, isLoading, isDraft]);

  return (
    <Stack
      direction={["column", "row"]}
      alignItems="center"
      justifyContent="center"
      height="100%"
      py={12}
      px={20}
    >
      <Stack width={["100%", "50%"]} alignItems="center">
        <Stack maxWidth={400} height={["auto", 240]}>
          <Text variant="subtitle1">
            What type of event will you be using this Q&A for?
          </Text>
          <HookChipGroup
            registerOptions={{
              required: {
                value: true,
                message: "Event Type is required.",
              },
            }}
            disabled={isLoading || !isDraft}
            options={EVENT_TYPE_SELECT_OPTIONS}
            fieldName={FIELD_NAME}
            listStyles={{ flexDirection: "row", flexWrap: "wrap" }}
            containerStyles={{ mt: 6, mr: 3 }}
          />
        </Stack>
      </Stack>
      <Stack width={["100%", "50%"]} alignItems="center">
        <Stack maxWidth={400} width="100%" spacing={6} height={["auto", 240]}>
          {isLoading ? (
            <>
              <Text />
              <Text />
              <Text />
            </>
          ) : (
            associatedComponent
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EventTypeForm;
