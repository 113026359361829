import { Stack } from "@mui/material";
import { EventSummaryCard } from "../../Organisms";

interface IProps {
  allEventSlugs: string[];
}

const EventSummaryCards = ({ allEventSlugs }: IProps) => {
  return (
    <Stack gap={8} mb={8}>
      {allEventSlugs.map((slug) => (
        <EventSummaryCard key={slug} slug={slug} />
      ))}
    </Stack>
  );
};

export default EventSummaryCards;
