import { SwiperSlide } from "swiper/react";
import { Box, Fade, Stack } from "@mui/material";
import { ButtonV2 as Button, Text } from "@asayinc/component-library";
import { Carousel } from "../../../../../components/Common";
import { AlertCard } from "../../Molecules";
import {
  AlertType,
  IAlert,
  commonApi,
  useGetAlertsQuery,
  useRemoveAllAlertsMutation,
} from "../../../../../store/commonApi";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../store/hooks";

interface IProps {
  dismissAllEnabled?: boolean;
}

const AlertCarousel = ({ dismissAllEnabled }: IProps) => {
  const dispatch = useAppDispatch();
  const [isDismissing, setIsDismissing] = useState(false);
  const [removeAllAlerts] = useRemoveAllAlertsMutation();
  const { data, isLoading } = useGetAlertsQuery();
  const [items, setItems] = useState<IAlert[]>([]);

  // set items into state when loaded as we'll be using animations and manage the removal locally
  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  // when component unmounts invalidate tags so correct alerts show next time homepage is viewed
  useEffect(() => {
    return () => {
      dispatch(commonApi.util.invalidateTags(["Alerts"]));
    };
  }, []);

  // update state to remove alert item
  const removeAlertFromDisplay = (id: string) => {
    // remove alert by idx
    setItems((prev) => prev.filter((a) => a.id !== id));
  };

  // update state with 0 alerts and hit api to remove them all
  const dismissAllAlerts = () => {
    setItems([]);
  };

  // start dismissing all alerts animation
  const beginDismissAll = () => {
    removeAllAlerts();
    setIsDismissing(true);
  };

  const showDismissAll =
    items && items.length > 0 && !isDismissing && dismissAllEnabled;

  return (
    <Stack gap={3} pt={3}>
      <Stack direction="row" gap={3} alignItems="center" height={48}>
        <Text variant="subtitle1">What&apos;s new</Text>
        {showDismissAll && (
          <Button
            variant="text"
            onClick={beginDismissAll}
            data-testid="btn-dismiss-all-alerts"
          >
            Dismiss all
          </Button>
        )}
      </Stack>
      {items && items.length > 0 ? (
        <Fade
          in={!isDismissing}
          appear={false}
          timeout={500}
          onExited={dismissAllAlerts}
        >
          <Box>
            <Carousel>
              {items.map((alert) => (
                <SwiperSlide key={alert.id} style={{ height: "auto" }}>
                  <AlertCard
                    {...alert}
                    removeAlertFromDisplay={removeAlertFromDisplay}
                  />
                </SwiperSlide>
              ))}
            </Carousel>
          </Box>
        </Fade>
      ) : isLoading ? (
        <Carousel>
          {Array(3)
            .fill(true)
            .map((_, i) => (
              <SwiperSlide key={i}>
                <AlertCard
                  isLoading
                  removeAlertFromDisplay={removeAlertFromDisplay}
                  id=""
                  text=""
                  relativeLink=""
                  type={AlertType.QATopSay}
                />
              </SwiperSlide>
            ))}
        </Carousel>
      ) : (
        <Stack justifyContent="center" alignItems="center" mb={5}>
          <img
            src={`${process.env.REACT_APP_ASSETS}images/ir-portal/empty_alerts.svg`}
          />
          <Text variant="subtitle1">You&apos;re all caught up</Text>
        </Stack>
      )}
    </Stack>
  );
};

export default AlertCarousel;
