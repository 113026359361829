/**
 * Assume at some point we have some kind of consistant items on all shareholder related pages
 * These can all be put here
 */

import { Outlet } from "react-router-dom";
import { FeatureIntroDialog } from "../../components/Common";
import { UiEventActions } from "../../store/consumerUser/types";
import { useFeaturesDisabled } from "../../hooks/useFeaturesDisabled";

const Shareholders = () => {
  const { disabledFeatures, isSingleFeature } = useFeaturesDisabled();

  return (
    <>
      <FeatureIntroDialog
        img={`${process.env.REACT_APP_ASSETS}images/ir-portal/ip/shareholders_dialog_illustration.svg`}
        imgAlt="Shareholder Network intro"
        title="Introducing Shareholder Network"
        description="Get to know your shareholders like never before. Shareholder network offers insights about your top shareholders to help you better tell a differentiated story."
        url="/shareholders"
        action={UiEventActions.CloseModalUpsellShareholderNetwork}
        fieldName="portalSnModalUpsell"
        conditional={
          isSingleFeature && disabledFeatures.includes("shareholders")
        }
      />
      <Outlet />
    </>
  );
};

export default Shareholders;
