import { Text, Tooltip, TooltipInfoIcon } from "@asayinc/component-library";
import { Stack, Box, SxProps } from "@mui/material";
import { CardWrapper } from "../../Templates";
import { BLURRY } from "../../../../constants";

interface ISingleStat {
  value?: string;
  tooltip?: string;
  subText?: string;
  subStat?: string;
}

export interface IMultiStat {
  title?: string;
  value?: string;
}

interface IProps {
  title: string;
  icon: React.ReactNode;
  singleStat?: ISingleStat;
  multiStats?: IMultiStat[];
  isLoading?: boolean;
  width?: string;
  minWidth?: string;
  openDialog?: () => void;
  blurStat?: boolean;
  tooltip?: string;
  subText?: string;
  sx?: SxProps;
}

const MultiLoadingState = () => (
  <>
    <Stack direction="row" justifyContent="space-between">
      <Text variant="subtitle1" width="70%" />
      <Text variant="subtitle1" width="10%" />
    </Stack>
    <Stack direction="row" justifyContent="space-between">
      <Text variant="subtitle1" width="70%" />
      <Text variant="subtitle1" width="10%" />
    </Stack>
    <Stack direction="row" justifyContent="space-between">
      <Text variant="subtitle1" width="70%" />
      <Text variant="subtitle1" width="10%" />
    </Stack>
  </>
);

const StatCard = ({
  title,
  icon,
  singleStat,
  multiStats,
  isLoading,
  width = "auto",
  minWidth = "none",
  openDialog,
  blurStat,
  tooltip,
  subText,
  sx,
}: IProps) => {
  return (
    <CardWrapper
      cardName={title}
      sx={{ minWidth, width, minHeight: "212px", flexShrink: 0, ...sx }}
    >
      <Stack justifyContent="space-between" height="100%">
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          {icon}
        </Stack>
        <Box>
          <Stack
            mb={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack alignItems="center" gap={1} direction="row">
              <Text variant="subtitle2">{title}</Text>
              {tooltip && <TooltipInfoIcon iconColor="black" title={tooltip} />}
            </Stack>
            {openDialog && !blurStat && (
              <Text
                tabIndex={0}
                variant="body2"
                emphasis={isLoading ? "disabled" : "high"}
                shade={isLoading ? "dark" : "say"}
                data-testid="cta-open-dialog"
                onClick={isLoading ? undefined : openDialog}
                sx={{ cursor: "pointer" }}
              >
                See all
              </Text>
            )}
          </Stack>
          {singleStat ? (
            <Stack>
              <Stack direction="row" alignItems="flex-end" flexWrap="wrap">
                <Tooltip title={singleStat?.tooltip}>
                  <Text
                    variant="h4"
                    loadingProps={{ sx: { width: 80 } }}
                    width={isLoading ? "50%" : "auto"}
                    mr={2}
                    sx={blurStat ? BLURRY : undefined}
                  >
                    {singleStat?.value === "0" ? (
                      <>&mdash;</>
                    ) : (
                      singleStat?.value
                    )}
                  </Text>
                </Tooltip>
                {!!singleStat?.subText && (
                  <Text variant="subtitle2">{singleStat.subText}</Text>
                )}
              </Stack>
              {!!singleStat.subStat && (
                <Text variant="body2" width={isLoading ? "50%" : "auto"} mt={2}>
                  {singleStat.subStat}
                </Text>
              )}
            </Stack>
          ) : multiStats ? (
            <Stack gap={1} mt={2}>
              {!isLoading ? (
                multiStats.map((stat) => (
                  <Stack
                    direction="row"
                    key={stat.title}
                    justifyContent="space-between"
                  >
                    <Text
                      variant="subtitle1"
                      sx={{
                        textOverflow: "ellipsis",
                        overflow: blurStat ? undefined : "hidden",
                        whiteSpace: "nowrap",
                        ...(blurStat ? { ...BLURRY } : {}),
                      }}
                      mr={1}
                    >
                      {stat.title}
                    </Text>
                    <Text
                      variant="subtitle1"
                      sx={blurStat ? BLURRY : undefined}
                    >
                      {stat.value}
                    </Text>
                  </Stack>
                ))
              ) : (
                <MultiLoadingState />
              )}
            </Stack>
          ) : null}
          {subText && (
            <Text
              emphasis="medium_low"
              variant="subtitle1"
              mt={6}
              loadingProps={{ sx: { width: 30 } }}
            >
              {subText}
            </Text>
          )}
        </Box>
      </Stack>
    </CardWrapper>
  );
};

export default StatCard;
