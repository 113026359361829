import { useForm, FormProvider } from "react-hook-form";
// components
import { Stack } from "@mui/material";
import {
  RangeFilter,
  CountryStateFilter,
} from "../../../../../../../components/Common";
// constants
import { URL_PARAMS } from "../../../../../../../constants";

const FilterList = (): JSX.Element => {
  const methods = useForm({ criteriaMode: "all" });

  return (
    <Stack direction="row" justifyContent="flex-end" gap={4} width="100%">
      <FormProvider {...methods}>
        <RangeFilter
          disabled={process.env.REACT_APP_ENV === "sandbox"}
          fieldName="sharesRange"
          minParam={URL_PARAMS.sharesOwnedMin}
          maxParam={URL_PARAMS.sharesOwnedMax}
          chipLabel="Shares owned"
        />
        <CountryStateFilter
          updateUrl
          disabled={process.env.REACT_APP_ENV === "sandbox"}
        />
      </FormProvider>
    </Stack>
  );
};

export default FilterList;
