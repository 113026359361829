import { Curve, CurveProps } from "recharts";

interface ICursorProps extends CurveProps {
  payload?: {
    value: number;
  }[];
}
// custom cursor is the line that displays when u hover a portion of the graph
// for line charts thats a "Curve", when payloads empty do not show the curve
const CustomCursor = (props: ICursorProps) => {
  const { payload, ...curveProps } = props;
  if (payload?.[0]?.value) {
    return <Curve {...curveProps} />;
  }
  return null;
};

export default CustomCursor;
