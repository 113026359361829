import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useParams } from "react-router-dom";
// components
import { Stack, Box } from "@mui/material";
import { Text, nFormatter } from "@asayinc/component-library";
import { CardWrapper } from "../../../../../../../components/Common";
// redux / data
import { useGetParticipantsQuery } from "../../../../../../../store/participants";
import {
  useGetEventSummaryQuery,
  initialSummaryState,
} from "../../../../../../../store/eventStats";
// types
import { IEventParticipantsAgg } from "../../../../../../../types/Participants";

const headlineLoadingProps = { sx: { width: "150px" } };
const subheadlineLoadingProps = { sx: { width: "75px" } };

const baseParams = {
  limit: "1",
  offset: "0",
};

const Summary = () => {
  const { eventSlug } = useParams() as { eventSlug: string };
  const { data: aggMedianData = {}, isLoading: isAggMedianDataLoading } =
    useGetParticipantsQuery({
      eventSlug,
      params: {
        ...baseParams,
        agg: "median",
      },
    });

  const {
    data: summaryData = initialSummaryState,
    isLoading: isSummaryDataLoading,
  } = useGetEventSummaryQuery(eventSlug ? eventSlug : skipToken);
  const {
    stats: { totalShares, totalShareholders },
  } = summaryData;

  const { sharesOwned: medianPosition } =
    aggMedianData as IEventParticipantsAgg;

  const isLoading = isSummaryDataLoading || isAggMedianDataLoading;

  return (
    <CardWrapper cardName="questions-summary">
      <Text variant="subtitle2" mb={6}>
        Participants summary
      </Text>
      <Stack direction="row" flexWrap="wrap" ml={8}>
        <Box width="33%">
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Shareholders"}
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {isLoading ? undefined : nFormatter(totalShareholders)}
          </Text>
        </Box>
        <Box width="33%">
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Shares represented"}
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {isLoading ? undefined : nFormatter(totalShares)}
          </Text>
        </Box>
        <Box width="33%">
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Median position size"}
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {isLoading ? undefined : nFormatter(medianPosition)}
          </Text>
        </Box>
      </Stack>
    </CardWrapper>
  );
};

export default Summary;
