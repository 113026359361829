import { IconButton, Text } from "@asayinc/component-library";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { marked } from "marked";
import { Box, Drawer, Stack } from "@mui/material";
import { useAppDispatch } from "../../../../store/hooks";
import { setSnackbar } from "../../../../store/commonReducer";
import { CardWrapper } from "../../../Common";

interface IProps {
  open: boolean;
  onClose: () => void;
  text: string;
  title?: string;
}

const ExampleDrawer = ({
  open,
  onClose,
  text,
  title = "Example email body",
}: IProps) => {
  const dispatch = useAppDispatch();

  // copy example text to clipboard and close
  const copyToClip = async () => {
    try {
      await navigator.clipboard.writeText(
        text
          .replaceAll("<br>", "\n")
          .replaceAll("<br/>", "\n")
          .replaceAll("<br />", "\n")
      );
      dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Example copied to clipboard",
        })
      );
      onClose();
    } catch {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Failed to copy to clipboard",
        })
      );
    }
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      ModalProps={{
        BackdropProps: {
          sx: { backgroundColor: "transparent" },
        },
      }}
      onClose={onClose}
      data-testid="example-message-drawer"
      PaperProps={{ elevation: 5 }}
      sx={{
        ".MuiDrawer-paper.MuiPaper-root": {
          width: "600px",
          backgroundColor: "#F0F0F0",
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 3px 18px rgba(0, 0, 0, 0.1)",
          p: 10,
        },
      }}
    >
      <Box>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          mb={8}
        >
          <Text variant="h5">Examples</Text>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <CardWrapper cardName="message-examples-drawer">
          <Box>
            <Stack
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              mb={6}
            >
              <Text variant="subtitle1">{title}</Text>
              <IconButton onClick={copyToClip}>
                <ContentCopyIcon />
              </IconButton>
            </Stack>
            <Box>
              <Text
                variant="body1"
                dangerouslySetInnerHTML={{
                  __html: marked.parse(text || "", {
                    breaks: true,
                    mangle: false,
                    headerIds: false,
                  }),
                }}
              />
            </Box>
          </Box>
        </CardWrapper>
      </Box>
    </Drawer>
  );
};
export default ExampleDrawer;
