// components
import {
  getInitials,
  nFormatter,
  Row,
  CustomTypographyProps,
} from "@asayinc/component-library";
// factories
import { createColumnData } from "../../../../../../../utils";
// types
import { TableEventData } from "../../../../../../../types/Table";
import { IShareholder } from "../../../../../../../store/shareholderList/types";
// constants
import { DELETED_USER_ID } from "../../../../../../../constants/settings";
import { InsertInvitationOutlined } from "@mui/icons-material";

interface ColumnDataProps {
  goToShareholder?: (data: unknown) => void;
  blurData: boolean;
  price: number;
}

const tdHeadProps = {
  textProps: { variant: "body3", weight: "normal" } as CustomTypographyProps,
};

const blurredBodyProps = {
  textProps: {
    sx: {
      color: "transparent",
      textShadow: "0px 0px 8px rgba(0,0,0,0.4)",
    },
  },
};

export const getColumnData = ({
  goToShareholder,
  blurData,
  price,
}: ColumnDataProps) => ({
  firstName: createColumnData({
    title: "Name",
    style: blurData ? "text" : "user",
    clickHandler: goToShareholder,
    tdHeadProps,
    width: "300px",
    tdBodyProps: blurData ? blurredBodyProps : undefined,
  }),
  shares: createColumnData({
    title: "Shares owned",
    style: "text",
    width: "200px",
    clickHandler: goToShareholder,
    tdHeadProps,
    tdBodyProps: blurData ? blurredBodyProps : undefined,
  }),
  shareValue: createColumnData({
    title: "Current share value",
    style: "text",
    tooltip: `Current price ${new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price)}`,
    width: "200px",
    clickHandler: goToShareholder,
    tdHeadProps,
    tdBodyProps: blurData ? blurredBodyProps : undefined,
  }),
  ticker: createColumnData({
    title: "Ticker",
    style: "text",
    width: "200px",
    clickHandler: goToShareholder,
    tdHeadProps,
    tdBodyProps: blurData ? blurredBodyProps : undefined,
  }),
  location: createColumnData({
    title: "Location",
    style: "text",
    width: "300px",
    clickHandler: goToShareholder,
    tdHeadProps,
    tdBodyProps: blurData ? blurredBodyProps : undefined,
  }),
});

interface CreateShareholdersRowData {
  id: string;
  data: TableEventData;
  name: string;
  sharesOwned: number;
  shareValue: number;
  profileColor: string;
  participantSlugsCount?: number;
  location: string;
  ticker: string;
  highlighted?: boolean;
  blurData: boolean;
}

export const createRowData = ({
  id,
  data,
  name,
  sharesOwned,
  shareValue,
  profileColor,
  participantSlugsCount,
  location,
  ticker,
  highlighted,
  blurData,
}: CreateShareholdersRowData): Row => ({
  rowData: {
    firstName: blurData
      ? // render text only for blurred data
        { content: name }
      : {
          Icon: participantSlugsCount ? InsertInvitationOutlined : undefined,
          iconSize: "20px",
          tooltip: "User has participated in a Q&A.",
          userCellData: {
            userName: name,
            initials: getInitials(name),
            profileColor: profileColor,
            isDeletedUser: id === DELETED_USER_ID,
          },
        },
    shares: { content: nFormatter(sharesOwned) },
    shareValue: {
      content: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(shareValue),
    },
    ticker: { content: ticker },
    location: { content: location },
  },
  id,
  data,
  highlighted,
});

export const getRowData = ({
  shareholders,
  drawerShareholderId,
  blurData,
}: {
  shareholders: IShareholder[];
  drawerShareholderId: string | null;
  blurData: boolean;
}) =>
  shareholders.map(
    (
      {
        globalShareholderId,
        firstName,
        lastName,
        profileColor,
        sharesOwned,
        shareValue,
        location,
        ticker,
        issuerQaEvents,
      },
      idx
    ) =>
      createRowData({
        id: globalShareholderId,
        data: {
          id: globalShareholderId,
          idx,
        },
        name: `${firstName} ${lastName}`,
        sharesOwned,
        shareValue,
        profileColor,
        participantSlugsCount: issuerQaEvents?.participantSlugsCount,
        location,
        ticker,
        highlighted: drawerShareholderId === globalShareholderId,
        blurData,
      })
  );
