import { useNavigate } from "react-router";
import { useEffect } from "react";
// components
import { Stack } from "@mui/material";
import { PastEventsTable, OngoingEvent } from "./components";
import { IntroBanner } from "../../../../components/Common";
// redux
import { useGetAllProxyEventsQuery } from "../../../../store/proxyEvents";
// types
import { IProxyEvent } from "../../../../store/proxyEvents/types";
import { COLORS, IButtonAction } from "@asayinc/component-library";
import { useRequestMoreInfo } from "../../../../hooks";
import { UiEventActions } from "../../../../store/consumerUser/types";

const AllProxyVoting = () => {
  useEffect(() => {
    document.title = "Voting | Say Issuer Portal";
  }, []);

  const navigate = useNavigate();
  const { data: events = [] as IProxyEvent[] } =
    useGetAllProxyEventsQuery(true);
  const activeProxyId = events.length ? events[0]?.id : null;

  // track request more info
  const { requestMoreInfo } = useRequestMoreInfo({
    action: UiEventActions.RequestInfoVoteMessages,
  });

  const bannerButtons = [
    ...(activeProxyId
      ? [
          {
            label: "Try for free",
            variant: "primary",
            onClick: () => {
              navigate(`/vote/${activeProxyId}/create`);
            },
          } as IButtonAction,
        ]
      : []),
    {
      label: "Request more info",
      variant: "text",
      onClick: requestMoreInfo,
    },
  ] as IButtonAction[];

  return (
    <Stack spacing={12} mt={6}>
      <IntroBanner
        page="voteSolicitation"
        img={`${process.env.REACT_APP_ASSETS}images/ir-portal/ip/vote_banner_illustration.svg`}
        bgcolor={COLORS.LIGHT_YELLOW}
        header="Welcome to voting messages"
        description="Share your company's story directly with retail shareholders using Messages - let shareholders know about product launches, partnership announcements, events and more, right to their inbox. Don't worry, you won't be charged until the message is sent."
        buttons={bannerButtons}
      />
      <OngoingEvent />
      <PastEventsTable />
    </Stack>
  );
};

export default AllProxyVoting;
