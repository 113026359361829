import { Text } from "@asayinc/component-library";
import { Box } from "@mui/material";

interface IProps {
  title: string;
  value?: string;
}

const EventSummaryStat = ({ title, value }: IProps) => {
  return (
    <Box>
      <Text variant="body2" emphasis="medium" mb={1}>
        {title}
      </Text>
      <Text variant="body2" loadingProps={{ sx: { width: "100px" } }}>
        {value}
      </Text>
    </Box>
  );
};

export default EventSummaryStat;
