import { IEventStats } from "../../types/Events";
import { IEventSummary } from "./types";

export const initialSummaryState: IEventSummary = {
  stats: {
    uniqueViews: 0,
    sharesViewed: 0,
    totalShareholders: 0,
    totalShares: 0,
    questionsAnsweredCount: 0,
    questionsAnsweredShares: 0,
    questionsAsked: 0,
    questionsAskedShares: 0,
  },
  feedback: {
    dissatisfied: 0,
    neutral: 0,
    satisfied: 0,
    veryDissatisfied: 0,
    verySatisfied: 0,
  },
  feedbackSent: 0,
  csat: 0,
  neutralPlus: 0,
  replies: 0,
};

export const initialStatsState: IEventStats = {
  isEventOpen: false,
  questionsAsked: 0,
  upvoteCount: 0,
  questionsAnsweredCount: 0,
  averageUpvotesPerQuestion: 0,
  medianPositionSize: 0,
  totalShareholders: 0,
  shareholdersAddressedPercent: 0,
  trends: {
    companyAverageQuestionsAsked: 0,
    companyAverageQuestionsAskedDiff: 0,
    companyAverageUpvoteCount: 0,
    companyAverageUpvoteCountDiff: 0,
    companyAverageUpvotesPerQuestion: 0,
    companyAverageUpvotesPerQuestionDiff: 0,
    companyAverageMedianPositionSize: 0,
    companyAverageMedianPositionSizeDiff: 0,
    companyAverageQuestionsAnsweredCount: 0,
    companyAverageQuestionsAnsweredCountDiff: 0,
    companyAverageShareholdersAddressedPercent: 0,
    companyAverageShareholdersAddressedPercentDiff: 0,
    companyAverageTotalShareholders: 0,
    companyAverageTotalShareholdersDiff: 0,
  },
};
