import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  IconButton,
  Text,
  Tooltip,
  nFormatter,
} from "@asayinc/component-library";
import { CardWrapper } from "../../../../../../../../../components/Common";
import { ProxyDetailGraph } from "../../Organisms";
import { SHORT_TIME_FORMAT } from "../../../../../../../constants";
// hooks
import { useGetProxyEventDetailsQuery } from "../../../../../../../../../store/proxyEvent";
import { useGraphData } from "./hooks/useGraphData";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const ProxyDetailGraphCard = () => {
  const { proxyEventId } = useParams() as { proxyEventId: string };
  const { data: detailsData } = useGetProxyEventDetailsQuery(proxyEventId);

  const {
    graphData,
    totalShareholdersInGraph,
    graphEventStats,
    isLoading,
    fullTimelineData,
  } = useGraphData();

  const trendTooltipText = fullTimelineData.length
    ? `Last updated ${dayjs(
        fullTimelineData[fullTimelineData.length - 1].modified
      ).format(SHORT_TIME_FORMAT)} ${dayjs(
        fullTimelineData[fullTimelineData.length - 1].modified
      )
        .local()
        .fromNow()}`
    : "Timeline not yet updated";

  const proxyStats = detailsData?.proxyEventStats;
  // get largest value for setting max value in shareholder y axis

  return (
    <CardWrapper cardName="proxy-graph" sx={{ width: "100%" }}>
      <Stack>
        <Stack>
          <Stack direction="row" alignItems="center" mb={2}>
            <Text variant="subtitle2" loadingProps={{ sx: { width: 100 } }}>
              {isLoading ? undefined : "Voting trend"}
            </Text>
            {isLoading ? null : (
              <Tooltip title={trendTooltipText}>
                <IconButton size="small">
                  <AccessTimeIcon style={{ fontSize: "16px" }} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <Stack direction="row" alignItems="flex-end" mb={-5}>
            <Text variant="h5" mr={1} loadingProps={{ sx: { width: 60 } }}>
              {proxyStats
                ? nFormatter(parseFloat(proxyStats.sharesVoted))
                : undefined}
            </Text>
            <Text
              variant="subtitle3"
              emphasis="medium"
              mr={8}
              loadingProps={{ sx: { width: 80 } }}
            >
              {proxyStats
                ? `/ ${nFormatter(proxyStats.totalShares)} shares voted`
                : undefined}
            </Text>
            <Text variant="h5" mr={1} loadingProps={{ sx: { width: 60 } }}>
              {proxyStats
                ? nFormatter(parseFloat(proxyStats.shareholdersVoted))
                : undefined}
            </Text>
            <Text
              variant="subtitle3"
              emphasis="medium"
              loadingProps={{ sx: { width: 80 } }}
            >
              {proxyStats
                ? `/ ${nFormatter(
                    proxyStats.totalCustomers
                  )} shareholders voted`
                : undefined}
            </Text>
          </Stack>
        </Stack>
        <ProxyDetailGraph
          graphData={graphData}
          shareholderMax={totalShareholdersInGraph}
          isLoading={isLoading}
          graphEventStats={graphEventStats}
          sharesMax={parseFloat(
            detailsData?.proxyEventStats?.sharesVoted || "0"
          )}
        />
      </Stack>
    </CardWrapper>
  );
};

export default ProxyDetailGraphCard;
