// import { useState } from "react";
import { useState, useEffect } from "react";
// material
import { Box, Stack } from "@mui/material";
// say
import {
  AutocompleteTagging,
  AutoCompleteOption,
  Dialog,
  TagColor,
  Text,
  IButtonAction,
  CircularProgress,
} from "@asayinc/component-library";
// redux / data
import { useAppDispatch } from "../../../../../store/hooks";
// types
import { Tag } from "../../../../../types/Common";
import { setSnackbar } from "../../../../../store/commonReducer";

interface Props {
  open: boolean;
  handleClose: (didSave: boolean, totalTags: number) => void;
  rowIds: string[];
  options: Tag[];
  newTag?: Tag;
  isLoading: boolean;
  isError: boolean;
  createTag: (newTag: { name: string; color: string }) => void;
  dialogTitle: string;
  addTags: (tagData: { rowIds: string[]; tagIds: number[] }) => void;
}

const AddTagsDialog = ({
  open,
  handleClose,
  rowIds,
  createTag,
  addTags,
  options,
  newTag,
  isLoading,
  isError,
  dialogTitle,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [tags, setTags] = useState<AutoCompleteOption[]>([]);

  // if api errors
  useEffect(() => {
    if (isError) {
      dispatch(
        setSnackbar({
          message: `Failed to add tags.`,
          open: true,
          severity: "error",
        })
      );
    }
  }, [isError]);

  // replaces ID of new tag in state with ID provided by API
  useEffect(() => {
    if (newTag) {
      const updatedTags = tags.map((tag) => {
        if (tag.name.toLowerCase() === newTag.name.toLowerCase()) {
          return newTag as AutoCompleteOption;
        }
        return tag;
      });
      setTags(updatedTags);
    }
  }, [newTag]);

  const closeDialog = (_?: undefined, _2?: undefined, didSave?: boolean) => {
    handleClose(!!didSave, tags.length);
    setTags([]);
  };

  const handleChange = ({
    allValues: allTags,
    addedValue: newlyAddedTag,
  }: {
    allValues: AutoCompleteOption[];
    addedValue?: AutoCompleteOption;
  }) => {
    if (newlyAddedTag?.new) {
      createTag({
        color: newlyAddedTag.color as TagColor,
        name: newlyAddedTag.name,
      });
    }
    setTags(allTags);
  };

  const save = async () => {
    const tagIds = tags.map((tag) => tag.id as number);
    await addTags({ rowIds, tagIds });
    // so that setTags true runs
    closeDialog(undefined, undefined, true);
  };

  const actions = [
    {
      onClick: closeDialog,
      label: "Cancel",
      variant: "secondary",
      size: "small",
    },
    {
      onClick: save,
      disabled: !tags.length,
      label: "Save",
      size: "small",
      variant: "contained",
    },
  ] as IButtonAction[];

  const getContent = () => {
    if (isLoading) {
      return (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "300px",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Stack>
      );
    }
    return (
      <Box maxWidth="684px" pb={6}>
        <Box px={8} py={4}>
          <Text>{dialogTitle}</Text>
        </Box>
        <AutocompleteTagging
          options={options}
          variant="inline"
          buttonProps={{ defaultIcon: true }}
          asPopover={false}
          onChange={handleChange}
          value={tags}
          ctaTitle="Tag"
        />
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      handleClose={closeDialog}
      fullBleed
      ctaOrientation="horizontal"
      data-testid="dialog-bulk-add-tags"
      buttonActions={actions}
      content={getContent()}
    />
  );
};

export default AddTagsDialog;
