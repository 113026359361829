import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithCamelize } from "../apiTools";

export const rootApi = createApi({
  baseQuery: baseQueryWithCamelize,
  reducerPath: "rootApi",
  tagTypes: [
    "Alerts",
    "AllEvents",
    "Disclaimer",
    "Event",
    "Features",
    "LeadershipTags",
    "NoboCheck",
    "Question",
    "QuestionTagged",
    "Questions",
    "Settings",
    "ShareholderMessage",
    "ShareholderMessagesList",
    "ShareholderSentMessage",
    "ShareholderTags",
    "UserInfo",
  ],
  endpoints: () => ({}),
});

export const {
  util: { getRunningQueriesThunk },
} = rootApi;
