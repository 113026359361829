import { IButtonAction } from "@asayinc/component-library";
// redux
import { useExportFeedbackDataMutation } from "../../../../../store/eventStats";
// router
import { useParams } from "react-router-dom";
import { BasicOverlay } from "../../../../../components/Common";
import { useSuccessErrorSnacks } from "../../../../../hooks/useSuccessErrorSnacks";

interface Props {
  open: boolean;
  handleClose: (
    type?: "download" | "bulkTag",
    _?: undefined,
    _2?: unknown,
    didSave?: boolean,
    tagCount?: number
  ) => void;
  count: number;
}

const ExportFeedback = ({ open, handleClose, count }: Props): JSX.Element => {
  const [getExportedFeedback, { isSuccess, isError }] =
    useExportFeedbackDataMutation();
  const { eventSlug } = useParams() as { eventSlug: string };

  useSuccessErrorSnacks({
    isSuccess,
    isError,
    successMsg: `Feedback for event ${eventSlug} exported!`,
    errorMsg: "Failed to parse CSV data",
  });

  /**
   * call api to download the csv
   */
  const closeDialog = () => {
    handleClose("download", undefined, undefined, false);
  };

  /**
   * call api to download the csv
   */
  const exportFeedback = () => {
    getExportedFeedback(eventSlug);

    handleClose("download", undefined, undefined, true);
  };

  const actions = [
    {
      onClick: closeDialog,
      label: "Cancel",
      variant: "secondary",
    },
    {
      onClick: exportFeedback,
      label: "Export",
    },
  ] as IButtonAction[];

  return (
    <BasicOverlay
      open={open}
      buttonActions={actions}
      handleClose={handleClose}
      ctaOrientation="horizontal"
      title="Export Feedback"
      content={`Export ${count} feedback to a CSV?`}
    />
  );
};

export default ExportFeedback;
