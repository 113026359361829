import {
  Row,
  ColumnType,
  CustomTypographyProps,
  nFormatter,
  DATE_FORMAT,
  getInitials,
} from "@asayinc/component-library";
import { CreateFeedbackRowData } from "./types";
import {
  DELETED_USER_ID,
  EMOJI_FONT_FAMILY,
  FEEDBACK_EMOJI_MAP,
  SORT,
} from "../../../../constants";
import { createColumnData } from "../../../../utils";
import { IEventFeedback } from "../../../../store/eventStats";
import dayjs from "dayjs";

/**
 * factory for generating row data
 * @param rowData key for each column
 * @param data object returned with callback functions
 * @param id id of the row
 */
const valign = "top";

export const createRowData = ({
  shareholder: { authorName, profileColor, globalShareholderId, tags },
  sharesOwned,
  feedbackEmoji,
  created,
  feedbackText,
  id,
  data,
}: // areRowsSelected,
CreateFeedbackRowData): Row => ({
  rowData: {
    authorName: {
      valign,
      userCellData: {
        userName: authorName,
        initials: getInitials(authorName),
        profileColor: profileColor,
        isDeletedUser: globalShareholderId === DELETED_USER_ID,
      },
      tagsData: {
        tags,
        tagsToShow: 2,
      },
    },
    created: { content: created, valign },
    feedbackText: { content: feedbackText, valign },
    feedbackEmoji: {
      content: `<div style='font-family:${EMOJI_FONT_FAMILY}; display:flex; align-items:center'><span style="font-size: 24px">${FEEDBACK_EMOJI_MAP?.[feedbackEmoji]?.emoji}</span><span style="margin-left: 10px; font-weight: 600;">${FEEDBACK_EMOJI_MAP?.[feedbackEmoji]?.text}</span></div>`,
      valign,
      asHTML: true,
    },
    sharesOwned: { content: nFormatter(sharesOwned), valign },
  },
  data,
  id,
});

interface ColumnDataProps {
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
  goToShareholder: (data: unknown) => void;
}

const tdHeadProps = {
  textProps: { variant: "body3", weight: "normal" } as CustomTypographyProps,
};

/**
 * Column data for table
 */
export const getColumnData = ({
  getSort,
  sortFn,
  goToShareholder,
}: ColumnDataProps): {
  checkbox: ColumnType;
  feedbackText: ColumnType;
  sharesOwned: ColumnType;
  feedbackEmoji: ColumnType;
  authorName: ColumnType;
  created: ColumnType;
} => ({
  checkbox: createColumnData({
    title: "",
    style: "popoverMenu",
    width: "36px",
  }),
  feedbackText: createColumnData({
    title: "Feedback",
    style: "text",
    width: "400px",
    align: "left",
    tdHeadProps,
    tdBodyProps: {
      textProps: {
        variant: "subtitle2",
      },
    },
  }),
  sharesOwned: createColumnData({
    title: "Shares owned",
    style: "text",
    width: "200px",
    sort: getSort(SORT.sharesOwned),
    sortFn,
    align: "left",
    tdHeadProps,
  }),
  feedbackEmoji: createColumnData({
    title: "Satisfaction",
    style: "text",
    sort: getSort(SORT.feedbackEmoji),
    sortFn,
    align: "left",
    width: "200px",
    tdHeadProps,
  }),
  authorName: createColumnData({
    title: "Shareholder",
    style: "user",
    width: "200px",
    clickHandler: goToShareholder,
    align: "left",
    tdHeadProps,
  }),
  created: createColumnData({
    title: "Submitted",
    style: "text",
    sort: getSort(SORT.created),
    sortFn,
    width: "200px",
    align: "left",
    tdHeadProps,
  }),
});

interface RowDataProps {
  feedbackList: IEventFeedback[];
}

/**
 * Each rows specific data for table
 */
export const getRowData = ({ feedbackList }: RowDataProps): Row[] =>
  feedbackList.map(({ id, created, ...feedback }, idx) =>
    createRowData({
      ...feedback,
      created: dayjs(created).format(DATE_FORMAT),
      id,
      data: {
        globalUserId: feedback.shareholder.globalShareholderId,
        id: feedback.shareholder.globalShareholderId,
        idx,
      },
      idx,
    })
  );
