import { Stack } from "@mui/material";
import { Text, nFormatter } from "@asayinc/component-library";
import {
  CardWrapper,
  Emoji,
  ShareholderInitials,
} from "../../../../../../../components/Common";
import { IEventFeedback } from "../../../../../../../store/eventStats";
import { FEEDBACK_EMOJI_MAP } from "../../../../../../../constants";

const ShareholderFeedbackCard = ({
  id,
  feedbackText,
  feedbackEmoji,
  sharesOwned,
  shareholder: { authorName, profileColor },
}: IEventFeedback) => {
  const emoji = FEEDBACK_EMOJI_MAP?.[feedbackEmoji] || null;

  return (
    <CardWrapper cardName={`${id}-feedback`} sx={{ width: 376 }}>
      <Stack justifyContent="space-between" minHeight={296}>
        <Stack>
          <Text variant="subtitle2" mb={3}>
            A shareholder said
          </Text>
          <Text
            mb={6}
            variant="body2"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              lineClamp: 10,
              WebkitLineClamp: 10,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
            }}
          >
            &ldquo;{feedbackText}&rdquo;
          </Text>
        </Stack>
        <Stack direction="row" alignItems="center">
          <ShareholderInitials
            authorName={authorName}
            profileColor={profileColor}
            size={40}
            textVariant="subtitle2"
          />
          <Stack ml={3}>
            <Text variant="subtitle2">{authorName}</Text>

            <Stack direction="row" gap={1} alignItems="center">
              {emoji && (
                <Emoji
                  emoji={emoji.emoji}
                  styles={{ fontSize: "16px", lineHeight: "20px" }}
                />
              )}
              <Text variant="body2">
                {emoji && emoji.text} · {nFormatter(sharesOwned)} shares
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </CardWrapper>
  );
};

export default ShareholderFeedbackCard;
