import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Stack, Box } from "@mui/material";
// Components
import {
  AdditionalDetailsTemplate,
  ReviewDetailsTemplate,
  CreateQaFooter,
  BasicInfoTemplate,
  Header,
  EventTypeTemplate,
} from "./components";
// helpers
import { getDefaultValues } from "./helpers/getDefaultValues";
import { parseFormData } from "./helpers/parseFormData";
// store
import {
  emptyInitialState,
  eventApi,
  setDraftCreated,
  useCreateEventMutation,
  useDeleteDraftEventMutation,
  useUpdateEventMutation,
} from "../../store/event";
import { useCreateQAData } from "./hooks";
import { useAppDispatch } from "../../store/hooks";
import { parseSubmittedEventData } from "./helpers/parseFormData/parseFormData";
import { useIsMounted } from "@asayinc/component-library";
import { track } from "../../analytics";
import { CREATION_STEP_MAPPING } from "./constants";
import { useGetFeaturesQuery } from "../../store/settings";
import { setSnackbar } from "../../store/commonReducer";
import { useUploadCsvQuery } from "./hooks/useUploadCsvQuery";
import { EventStatusOptions, IAudience } from "../../types/Events";

interface Props {
  isCreate?: boolean;
}

const CreateAndEditEvent = ({ isCreate }: Props): JSX.Element => {
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [updateEvent, { isLoading: updateEventLoading }] =
    useUpdateEventMutation();
  const [removeDraft, { isLoading: removeDraftLoading }] =
    useDeleteDraftEventMutation();
  const [createEvent, { isLoading: createEventLoading }] =
    useCreateEventMutation();
  const {
    event,
    isSuccess: getDataSuccess,
    companyName,
    allCompanyLeaders,
    isLoading: getDataLoading,
    leadersQueryIsLoading,
  } = useCreateQAData(isCreate);

  const isDraft = event.status === "draft";

  const {
    postCsvQuery,
    deleteCsv,
    isLoading: isCsvLoading,
  } = useUploadCsvQuery(isDraft);

  const { data: features, isSuccess: isFeaturesSuccess } =
    useGetFeaturesQuery();

  // if user tries to reach this page with feature disabled, redirect to root QA page
  useEffect(() => {
    if (isFeaturesSuccess && !features?.qAndA) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Q&A is not enabled for your account",
        })
      );
      navigate("/qa");
    }
  }, [isFeaturesSuccess]);

  const isLoading =
    getDataLoading ||
    createEventLoading ||
    removeDraftLoading ||
    updateEventLoading ||
    isCsvLoading;

  const [creationStep, setCreationStep] = useState(0);

  const methods = useForm({
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onTouched",
  });

  const { isValid } = methods.formState;

  /**
   * pull event data to preset the form
   */
  useEffect(() => {
    if (isCreate) {
      document.title = `Create Q&A | Say Issuer Portal`;
    } else {
      document.title = `Edit Q&A | Say Issuer Portal`;
    }
    return () => {
      if (!isCreate && !isDraft) {
        dispatch(
          eventApi.util.invalidateTags([{ type: "Event", id: event.slug }])
        );
      }
    };
  }, []);

  /**
   * set default values after pulling eventData
   */
  useEffect(() => {
    if (!getDataLoading && getDataSuccess) {
      methods.reset(
        getDefaultValues(event, allCompanyLeaders, creationStep, isDraft),
        {
          keepErrors: true,
        }
      );
    }
  }, [getDataLoading]);

  /**
   * When step changes, reset default values
   */
  useEffect(() => {
    if (creationStep < 3) {
      methods.reset(
        getDefaultValues(
          isCreate ? emptyInitialState : event,
          allCompanyLeaders,
          creationStep,
          isDraft
        )
      );
    }
    window.scrollTo({ top: 0 });
  }, [creationStep]);

  /**
   * Go to specific step in creation flow
   * whenever step changes the current draft state should be saved
   */
  const goToStep = (step: number) => {
    if (methods.formState.isDirty) {
      triggerUpdateEvent();
    }
    setCreationStep(step);
  };

  /**
   * Delete draft form
   */
  const deleteDraftEvent = () => {
    const slug = event.slug;
    if (isCreate) {
      navigate("/qa");
    } else {
      removeDraft(event.slug)
        .unwrap()
        .then(() => {
          // invalidate after navigation to prevent re-pull of deleted draft
          dispatch(eventApi.util.invalidateTags([{ type: "Event", id: slug }]));
        })
        .catch(() => {
          console.error("error deleting draft");
        });
      navigate("/qa", { replace: true });
    }
  };

  /**
   * memoize the steps within an array
   */
  const steps = useMemo(
    () => [
      <EventTypeTemplate
        key={1}
        isDraft={isDraft || isCreate}
        isLoading={isLoading}
      />,
      <BasicInfoTemplate
        key={2}
        isDraft={isDraft || isCreate}
        isLoading={isLoading}
      />,
      <AdditionalDetailsTemplate key={3} isLoading={isLoading} />,
      <ReviewDetailsTemplate
        key={4}
        isDraft={isDraft || isCreate}
        goToStep={goToStep}
      />,
    ],
    [isDraft, isCreate, isLoading, leadersQueryIsLoading]
  );

  // check if user has updated the slug by changing event type details
  const hasSlugUpdated = (newSlug: string) =>
    creationStep === 0 && event.slug && event.slug !== newSlug;

  /**
   * Save draft state of form
   */
  const triggerUpdateEvent = async (nextStep?: number) => {
    const isNextStep = typeof nextStep === "number";
    const formData = methods.getValues();
    let audience: IAudience | false | null = false;
    if (creationStep === 2 && methods.formState.dirtyFields?.audience) {
      if (formData.audience) {
        audience = await postCsvQuery(
          formData.audience,
          event.slug,
          methods.setError
        );
      } else {
        audience = await deleteCsv(event.slug);
      }
      if (audience === false) {
        return;
      }
    }
    const parsedFormData = parseFormData(formData, creationStep, companyName);
    const newEventData = {
      ...event,
      ...parsedFormData,
      ...(isValid &&
      // update stepsComplete when it's undefined or the user is on a later step
      (creationStep >= event.draftStep || !event.draftStep)
        ? { draftStep: creationStep + 1 }
        : {}),
    };

    const slug = newEventData.slug;

    if (isDraft || isCreate) {
      track({
        name: "Q&A Event Edited",
        qaEvent: slug,
        eventStatus: event.status,
        composeStep: CREATION_STEP_MAPPING[creationStep],
        client: companyName,
      });
    }

    if (slug && hasSlugUpdated(slug)) {
      /**
       * If slug params change for a draft (currently also non-draft) event with an existing slug,
       * then attempt to create new draft and delete old one
       */
      createEvent(newEventData)
        .unwrap()
        .then(() => {
          isNextStep && setCreationStep(nextStep);
          removeDraft(event.slug);
        })
        .catch(() => {
          console.error("Error in form");
        });
    } else if (slug) {
      if (isCreate) {
        createEvent(newEventData)
          .unwrap()
          .then(() => isMounted() && isNextStep && setCreationStep(nextStep))
          .catch(() => {
            console.error("error creating draft");
          });
      } else if (isDraft) {
        updateEvent(newEventData)
          .unwrap()
          .then(() => isMounted() && isNextStep && setCreationStep(nextStep))
          .catch(() => {
            console.error("error saving draft");
          });
      } else {
        dispatch(
          eventApi.util.updateQueryData("getEvent", event.slug, (cachedEvent) =>
            Object.assign(cachedEvent, {
              ...newEventData,
              // update audience if its been updated
              ...(audience !== false ? { audience } : {}),
            })
          )
        );
        isNextStep && setCreationStep(nextStep);
      }
    } else {
      // Temporary fix for step 3 when slug is undefined
      isNextStep && setCreationStep(nextStep);
    }
  };

  /**
   * Go to previous step
   */
  const previous = () => {
    goToStep(creationStep - 1);
  };

  /**
   * submit final state of form
   */
  const onSubmit = () => {
    if (creationStep < 3) {
      // even if the formstate isnt dirty we still want to update the step data if its on a step
      // with no required fields (step 2 index 1)
      if (methods.formState.isDirty || event.draftStep - 1 < creationStep) {
        triggerUpdateEvent(creationStep + 1);
      } else {
        setCreationStep((step) => step + 1);
      }
    } else {
      track({
        name: "Q&A Event Edited",
        qaEvent: event.slug,
        eventStatus: event.status,
        composeStep: CREATION_STEP_MAPPING[creationStep],
        client: companyName,
      });
      if (event.status === EventStatusOptions.DRAFT) {
        dispatch(setDraftCreated(true));
      }

      updateEvent({
        slug: event.slug,
        ...parseSubmittedEventData(event),
        submit: true,
        status: event.status,
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{ height: "100%" }}
      >
        <Stack height="100%">
          <Stack
            p={10}
            pb={0}
            maxWidth={1440}
            width="100%"
            flexGrow={1}
            m="0 auto"
            position="relative"
            data-testid={isLoading ? "create-edit-loading" : "create-edit"}
          >
            <Header
              goToStep={goToStep}
              event={event}
              currentStep={creationStep}
            />

            <Box
              sx={{
                flexGrow: 1,
                p: 1.5,
                pt: 0,
                pb: 8,
              }}
            >
              {steps[creationStep]}
            </Box>
          </Stack>
          <CreateQaFooter
            deleteDraftEvent={deleteDraftEvent}
            previous={previous}
            currentStep={creationStep}
            isSubmitStep={creationStep === 3}
            saveEvent={triggerUpdateEvent}
            isLoading={isLoading}
            isDraft={isDraft || isCreate}
          />
        </Stack>
      </form>
    </FormProvider>
  );
};

export default CreateAndEditEvent;
