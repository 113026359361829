import {
  ButtonV2 as Button,
  IconButton,
  IconName,
  SayIcon,
  Text,
} from "@asayinc/component-library";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Fade, Stack } from "@mui/material";
import {
  AlertType,
  IAlert,
  useRemoveAlertMutation,
} from "../../../../../store/commonApi";
import { useNavigate } from "react-router-dom";
import { CardWrapper } from "../../../../../components/Common";
import { useState } from "react";

interface IProps extends IAlert {
  removeAlertFromDisplay: (id: string) => void;
  isLoading?: boolean;
}

export const AlertTypeIconMap: { [key: string]: IconName } = {
  [AlertType.TopicTrending]: "Insights",
  [AlertType.TopicSentimentDecrease]: "Insights",
  [AlertType.TopicSentimentIncrease]: "Insights",
  [AlertType.TopicNewPopular]: "Insights",
  [AlertType.QATopSay]: "CalendarDayOutline",
};

const AlertCard = ({
  text,
  relativeLink,
  type,
  id,
  removeAlertFromDisplay,
  isLoading,
}: IProps) => {
  const [show, setShow] = useState(true);
  const [removeAlert] = useRemoveAlertMutation();
  const navigate = useNavigate();

  // call remove alert api to dismiss the alert
  const dismissAlert = () => {
    removeAlertFromDisplay(id);
    removeAlert({ id });
  };

  // trigger animation on card
  const animateOut = () => {
    setShow(false);
  };

  const clickView = () => {
    dismissAlert();
    navigate(relativeLink);
  };

  return (
    <Fade in={show} appear={false} timeout={500} onExited={dismissAlert}>
      <Box height="100%">
        <CardWrapper
          cardName="message"
          sx={{ pt: 10, pb: 2, width: 350, height: "100%" }}
        >
          <Stack justifyContent="space-between" height="100%">
            <IconButton
              aria-label="close"
              sx={{
                right: (theme) => theme.spacing(3),
                top: (theme) => theme.spacing(3),
                position: "absolute",
              }}
              data-testid={`alert-dismiss-${id}`}
              size="small"
              onClick={animateOut}
            >
              <CloseIcon />
            </IconButton>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              gap={6}
            >
              <Box
                width={24}
                sx={{
                  svg: {
                    display: "block",
                    filter: isLoading ? "blur(4px)" : "none",
                  },
                }}
              >
                <SayIcon
                  name={isLoading ? "Insights" : AlertTypeIconMap[type]}
                />
              </Box>
              <Text
                variant="body1"
                pr={4}
                loadingProps={{ sx: { width: 180 } }}
              >
                {isLoading ? undefined : text}
              </Text>
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                onClick={clickView}
                variant="text"
                data-testid={`button-view-${id}`}
              >
                {isLoading ? undefined : "View"}
              </Button>
            </Stack>
          </Stack>
        </CardWrapper>
      </Box>
    </Fade>
  );
};

export default AlertCard;
