import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
// components
import { Stack } from "@mui/material";
import TryMessagesDialog from "../TryMessagesDialog";
import { nFormatter, Text } from "@asayinc/component-library";
import {
  Carousel,
  StatCardVariant2,
} from "../../../../../../../components/Common";
import { SwiperSlide } from "swiper/react";
// redux / data
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetAllShareholderStatsQuery,
  useGetPercentileShareholderTrendsQuery,
  useGetTopShareholderTrendsQuery,
} from "../../../../../../../store/allShareholderStats";
import { useGetFeaturesQuery } from "../../../../../../../store/settings";
import { useIsShareholdersEnabled } from "../../../../../useIsShareholdersEnabled";
import { useCreateMessageMutation } from "../../../../../../../store/messageCompose";
// types
import { MessageType } from "../../../../../../../store/messageLists";
import { TOP_SHAREHOLDER_LABEL_MAPPING } from "../../../../../../../constants";

const ShareholderGroups = () => {
  const navigate = useNavigate();
  const [dialogType, setDialogType] = useState<string | undefined>(undefined);
  const [postMessage, postResult] = useCreateMessageMutation();
  const { data: features } = useGetFeaturesQuery();
  const isMessagesEnabled = !!features?.messages;
  // get stat data
  const { data: stats, isLoading: isStatsLoading } =
    useGetAllShareholderStatsQuery({
      aggregators: "sum",
    });

  const { isFeatureEnabled } = useIsShareholdersEnabled();

  const { data: topData, isLoading: isTopShareholderLoading } =
    useGetTopShareholderTrendsQuery(isFeatureEnabled ? {} : skipToken);
  const { data: percentileData, isLoading: isPercentileShareholderLoading } =
    useGetPercentileShareholderTrendsQuery(
      isFeatureEnabled ? { percentile: "p99,p90,p75" } : skipToken
    );
  const isLoading =
    isTopShareholderLoading || isPercentileShareholderLoading || isStatsLoading;

  useEffect(() => {
    if (postResult.isSuccess) {
      navigate(`/messages/${postResult.data?.id}/edit`);
    }
  }, [postResult.isSuccess]);

  // on click of message cta in top card
  const clickMessage = (topShareholders: string) => {
    if (isMessagesEnabled) {
      createMessage(topShareholders);
    } else {
      toggleDialog(topShareholders);
    }
  };

  // Create message with default shareholder index selected
  const createMessage = (topShareholders: string) => {
    postMessage({
      type: MessageType.SM,
      campaignName: `${
        TOP_SHAREHOLDER_LABEL_MAPPING[topShareholders]
      } Message - ${dayjs().format("MMMM D YYYY")}`,
      filterMap: {
        query: {
          topShareholders: { values: [topShareholders] },
        },
      },
    });
  };

  // need to pass back the specific btn that was clicked, so use a string to track which btn was clicked and open the dialog
  const toggleDialog = (str?: string) => setDialogType(str);

  const cardData = useMemo(() => {
    const dateString = dayjs(topData?.top50?.[0]?.recordDate).format("MMMM D");

    // number of shares in top 50 and their percent of the total shares
    const top50Shares = topData?.top50?.[0]?.sharesOwned || 0;
    const top50PercentOfTotal = (top50Shares / (stats?.sum || 1)) * 100;
    // number of shares in top 100 and their percent of the total shares
    const top100Shares = topData?.top100?.[0]?.sharesOwned || 0;
    const top100PercentOfTotal = (top100Shares / (stats?.sum || 1)) * 100;
    // number of shares in top 500 and their percent of the total shares
    const top500Shares = topData?.top500?.[0]?.sharesOwned || 0;
    const top500PercentOfTotal = (top500Shares / (stats?.sum || 1)) * 100;

    return [
      {
        title: "Top 50",
        id: "50",
        tooltipText: `Top 50 shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(top50Shares),
        rightStatTitle: "% of total",
        rightStat: `${nFormatter(top50PercentOfTotal)}%`,
        button: {
          label: "Message",
          onClick: () => clickMessage("50"),
          disabled: postResult.isLoading,
        },
      },
      {
        title: "Top 100",
        id: "100",
        tooltipText: `Top 100 shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(top100Shares),
        rightStatTitle: "% of total",
        rightStat: `${nFormatter(top100PercentOfTotal)}%`,
        button: {
          label: "Message",
          onClick: () => clickMessage("100"),
          disabled: postResult.isLoading,
        },
      },
      {
        title: "Top 500",
        id: "500",
        tooltipText: `Top 500 shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(top500Shares),
        rightStatTitle: "% of total",
        rightStat: `${nFormatter(top500PercentOfTotal)}%`,
        button: {
          label: "Message",
          onClick: () => clickMessage("500"),
          disabled: postResult.isLoading,
        },
      },
      {
        title: "Top 1%",
        id: "1%",
        isPercentCard: true,
        tooltipText: `Top 1% shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(
          percentileData?.p99?.sharesOwnedMean?.data?.[0]?.value || 0
        ),
        rightStatTitle: "Shareholder count",
        rightStat: nFormatter(
          percentileData?.p99?.customerCountMean?.data?.[0]?.value || 0
        ),
        button: {
          label: "Message",
          onClick: () => clickMessage("p99"),
          disabled: postResult.isLoading,
        },
      },
      {
        title: "Top 10%",
        id: "10%",
        isPercentCard: true,
        tooltipText: `Top 10% shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(
          percentileData?.p90?.sharesOwnedMean?.data?.[0]?.value || 0
        ),
        rightStatTitle: "Shareholder count",
        rightStat: nFormatter(
          percentileData?.p90?.customerCountMean?.data?.[0]?.value || 0
        ),
        button: {
          label: "Message",
          onClick: () => clickMessage("p90"),
          disabled: postResult.isLoading,
        },
      },
      {
        title: "Top 25%",
        isPercentCard: true,
        id: "25%",
        tooltipText: `Top 25% shareholders by shares owned as of ${dateString}`,
        leftStatTitle: "Shares owned",
        leftStat: nFormatter(
          percentileData?.p75?.sharesOwnedMean?.data?.[0]?.value || 0
        ),
        rightStatTitle: "Shareholder count",
        rightStat: nFormatter(
          percentileData?.p75?.customerCountMean?.data?.[0]?.value || 0
        ),
        button: {
          label: "Message",
          onClick: () => clickMessage("p75"),
          disabled: postResult.isLoading,
        },
      },
    ];
  }, [isLoading]);

  return (
    <Stack>
      <Text variant="subtitle1" mb={6}>
        Shareholder groups & trends
      </Text>
      <Carousel>
        {cardData.map(({ button, ...data }) => (
          <SwiperSlide key={data.id}>
            <StatCardVariant2
              key={data.title}
              {...data}
              iconName="PeopleFilled"
              cardName="shareholder-group"
              isLoading={isLoading}
              isFeatureEnabled={isFeatureEnabled}
              button={isFeatureEnabled ? button : undefined}
            />
          </SwiperSlide>
        ))}
      </Carousel>
      <TryMessagesDialog
        isLoading={postResult.isLoading}
        toggleDialog={toggleDialog}
        dialogType={dialogType}
        createMessage={createMessage}
      />
    </Stack>
  );
};

export default ShareholderGroups;
