import { Stack, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { COLORS, ButtonV2 as Button } from "@asayinc/component-library";
import {
  CardWrapper,
  ShareholderFeedbackSummaryCard,
} from "../../../../../components/Common";
import { EventOverview, TrendingTopics } from "./components";
import { useGetEventQuery } from "../../../../../store/event";
import { useGetSentimentQuery } from "../../../../../store/sentiment";
import {
  initialSummaryState,
  useGetEventSummaryQuery,
} from "../../../../../store/eventStats";

interface IProps {
  slug: string;
}

const EventSummaryCard = ({ slug }: IProps) => {
  const navigate = useNavigate();
  const { data: eventData } = useGetEventQuery(slug);
  const {
    data: eventSummaryData = initialSummaryState,
    isLoading: isSummaryLoading,
  } = useGetEventSummaryQuery(slug);

  const { data: sentimentData } = useGetSentimentQuery({
    eventSlug: slug,
    params: {
      ordering: "-shares_owned",
      limit: "3",
    },
  });

  // go to overview details page of event
  const goToOverview = () => {
    navigate(`${slug}/overview`);
  };

  return (
    <CardWrapper cardName={`event-summary-${slug}`}>
      {/*32 outer */}
      <Stack>
        <Stack
          direction={["column", "row"]}
          justifyContent="space-between"
          alignItems={["flex-start", "center"]}
        >
          <Stack mr={[0, 12]}>
            <EventOverview
              isLoading={isSummaryLoading}
              eventSummary={eventSummaryData}
              eventStatus={eventData?.status}
              eventStartDateTime={eventData?.eventStartDatetime}
              eventTitle={eventData?.title}
              goToOverview={goToOverview}
            />
          </Stack>
          <Stack
            gap={6}
            direction="row"
            flexGrow={1}
            width={["100%", "auto"]}
            justifyContent={["flex-start", "flex-end"]}
            my={[0, 2]}
          >
            <Stack
              border={`1px solid ${COLORS.P_OUTLINE}`}
              p={8}
              borderRadius={2.5}
              width="100%"
              maxWidth={["none", 296]}
            >
              <TrendingTopics sentimentData={sentimentData?.results} />
            </Stack>
            <Stack
              border={`1px solid ${COLORS.P_OUTLINE}`}
              p={8}
              borderRadius={2.5}
              width="100%"
              maxWidth={["none", 296]}
            >
              <ShareholderFeedbackSummaryCard
                csat={eventSummaryData.csat}
                neutralPlus={eventSummaryData.neutralPlus}
                replies={eventSummaryData.replies}
                sent={eventSummaryData.feedbackSent}
                isLoading={isSummaryLoading}
              />
            </Stack>
          </Stack>
        </Stack>
        <Box mt={8} display={["block", "none"]}>
          <Button
            variant="secondary"
            onClick={goToOverview}
            data-testid="button-mobile-viewdetails"
          >
            View Details
          </Button>
        </Box>
      </Stack>
    </CardWrapper>
  );
};

export default EventSummaryCard;
