import { Card, Text, nFormatter } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { TooltipProps } from "recharts";
import { TabValues } from "./QAOverTime";

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  // reverse array to put "Shares" before "Shareholders"
  const sortedPayload = [...(payload || [])].sort(
    (a, b) => (b.value || 1) - (a.value || 1)
  );

  if (active && sortedPayload && sortedPayload.length) {
    return (
      <Card
        data-testid="graph-tooltip"
        elevation={8}
        sx={{ px: 4, py: 3, borderRadius: 2 }}
      >
        <Text variant="body3" emphasis="medium_high" mb={1.5}>
          {label}
        </Text>
        {sortedPayload.map((itm) => (
          <Stack key={itm.name} direction="row" alignItems="center" mb={1}>
            <Box
              bgcolor={itm.color}
              width={8}
              height={8}
              borderRadius="100%"
              mr={2}
            />
            <Text variant="body3">
              {nFormatter(itm.value || 0)}{" "}
              {TabValues?.[itm.dataKey as keyof typeof TabValues]?.title}
            </Text>
          </Stack>
        ))}
      </Card>
    );
  }

  return null;
};

export default CustomTooltip;
