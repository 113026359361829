import { COLORS, Text, TooltipInfoIcon } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { TrendingTopic } from "../Atoms";
import { ITrendingCategory } from "../../../../../../../store/sentiment";

interface IProps {
  sentimentData?: ITrendingCategory[];
}

const TrendingTopics = ({ sentimentData }: IProps) => {
  // for displaying loading state
  const loadingData = [1, 2, 3];
  return (
    <Stack>
      <Stack direction="row" alignItems="center" gap={1} mb={2}>
        <Text variant="subtitle2">Trending Topics</Text>
        <TooltipInfoIcon
          title="Shareholders asked most about these topics during your event"
          iconColor={COLORS.BLACK}
        />
      </Stack>
      <Text variant="h4" mb={6}>
        Top 3
      </Text>
      <Stack gap={4}>
        {!sentimentData
          ? loadingData.map((id) => <TrendingTopic key={id} />)
          : sentimentData.map((category) => (
              <TrendingTopic
                key={category.id}
                title={category.name}
                sentiment={category.sentiment}
              />
            ))}
      </Stack>
    </Stack>
  );
};

export default TrendingTopics;
