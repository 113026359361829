import { useCallback, useMemo } from "react";
// components
import FilterListIcon from "@mui/icons-material/FilterList";
import { TableV2 as Table } from "@asayinc/component-library";
import { useSearchParams } from "react-router-dom";
import FilterList from "../FilterList";
// utils
import { getNoResultsProps, createQueryObj } from "./utils";
import { getColumnData, getRowData } from "./factories";
import { useTableSearchParams } from "../../../../../../../hooks";
// redux/data
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useIsShareholdersEnabled } from "../../../../../useIsShareholdersEnabled";
import {
  initialState as initialAppConfigState,
  useGetAppConfigQuery,
} from "../../../../../../../store/appConfig";
import { useGetShareholdersQuery } from "../../../../../../../store/shareholderList";
import { useGetSettingsQuery } from "../../../../../../../store/settings";
import { useGetSecurityPriceQuery } from "../../../../../../../store/commonApi";
// types
import { TableEventData } from "../../../../../../../types/Table";
import { ISettings } from "../../../../../../../store/settings/types";
// constants
import {
  ROW_OPTIONS,
  AGG_OPTIONS,
  URL_PARAMS,
} from "../../../../../../../constants";
import { disabledFeatureData } from "./constants";

const ShareholdersTable = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: { allowIssuerPricing } = initialAppConfigState } =
    useGetAppConfigQuery();
  const { data: priceData } = useGetSecurityPriceQuery();
  const { data: settings = {}, isLoading: isSettingsLoading } =
    useGetSettingsQuery();
  const { securities = [] } = settings as ISettings;
  const hasMultipleSecurities = securities.length > 1;

  const tableColumns = useMemo(
    () => [
      "firstName",
      "shares",
      ...(allowIssuerPricing ? ["shareValue"] : []),
      ...(hasMultipleSecurities ? ["ticker"] : []),
      "location",
    ],
    [hasMultipleSecurities, allowIssuerPricing]
  );

  const { paramObj, limit, page, handlePageChange, handleRowsChange } =
    useTableSearchParams({
      defaultAgg: AGG_OPTIONS[0].value,
    });

  const drawerShareholderId = searchParams.get(URL_PARAMS.sid);

  const apiParams = {
    limit: paramObj.limit,
    offset: String((parseInt(page) - 1) * parseInt(limit)),
  };

  const query = useMemo(() => createQueryObj(paramObj), [paramObj]);

  const { isFeatureEnabled, isFeatureEnabledLoading } =
    useIsShareholdersEnabled();

  const blurData = !isFeatureEnabled && !isFeatureEnabledLoading;

  const {
    data: shareholderData,
    isLoading,
    isFetching,
  } = useGetShareholdersQuery(
    isFeatureEnabled
      ? {
          params: apiParams,
          query,
        }
      : skipToken
  );

  /**
   * Route to question page
   * @param data TableEventData
   */
  const goToShareholder = useCallback(
    (data: unknown) => {
      const { id } = data as TableEventData;
      searchParams.set(URL_PARAMS.sid, id);
      setSearchParams(searchParams, { state: { goBackText: "Shareholders" } });
    },
    [searchParams]
  );

  const noResultsData = getNoResultsProps(searchParams);
  const columnData = useMemo(
    () =>
      getColumnData({
        // disable clicking when feature is disabled
        goToShareholder: blurData ? undefined : goToShareholder,
        blurData,
        price: priceData?.price ?? 0,
      }),
    [goToShareholder, blurData, priceData]
  );

  const rowData = getRowData({
    shareholders:
      isFeatureEnabled || isFeatureEnabledLoading
        ? shareholderData?.results || []
        : disabledFeatureData,
    drawerShareholderId,
    blurData,
  });

  const count = shareholderData?.count || rowData?.length || 0;

  const paginateProps = {
    onChangePage:
      process.env.REACT_APP_ENV === "sandbox"
        ? () => {
            console.warn("Sandbox mode: Disable Page Change");
          }
        : handlePageChange,
    onChangeRows:
      process.env.REACT_APP_ENV === "sandbox"
        ? () => {
            console.warn("Sandbox mode: Disable Rows Change");
          }
        : handleRowsChange,
    count,
    page: parseInt(page),
    rowsPerPage: parseInt(limit),
    rowOptions: ROW_OPTIONS,
  };

  // toolbar dropdown filter list
  const collapseContent = <FilterList />;
  const toolbarCollapse = {
    Icon: FilterListIcon,
    collapseContent,
  };

  return (
    <Table
      title="Top shareholders"
      tableMinWidth={1261}
      tableLayout="auto"
      testid="shareholders-table"
      columns={tableColumns}
      columnData={columnData}
      toolbarCollapse={toolbarCollapse}
      rows={rowData}
      count={count}
      disableHover={blurData}
      memoCells
      paginateProps={paginateProps}
      noResultsData={noResultsData}
      isLoading={isLoading || isSettingsLoading || isFeatureEnabledLoading}
      isFetching={isFetching}
    />
  );
};

export default ShareholdersTable;
