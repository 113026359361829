// columns for events table
export const COLUMNS = [
  "shortTitle",
  "startTime",
  "totalQuestions",
  "answeredCount",
  "totalShareholders",
  "totalShares",
];

export const DEFAULT_LIMIT = 1000;
