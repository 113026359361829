import { sanitize } from "dompurify";
import {
  AGG_OPTIONS,
  EVENT_STATUS_OPTIONS,
  QUESTION_STATUS_OPTIONS,
  ROW_OPTIONS,
  SORT,
  TYPE_OPTIONS,
  URL_PARAMS,
} from "../../constants";
import { INVESTOR_TYPE_OPTIONS } from "../../constants";
import { IURL_PARAMS } from "../../types/Common";
import { EventStatusOptions } from "../../types/Events";
import {
  QuestionFilterStatusType,
  ShareholderType,
} from "../../types/Questions";
import { InvestorType } from "../../types/Shareholder";

// params that have a finite set of possible values
const validParamValues = {
  [URL_PARAMS.agg]: AGG_OPTIONS.map((option) => option.value),
  [URL_PARAMS.filter]: QUESTION_STATUS_OPTIONS.map((option) => option.value),
  [URL_PARAMS.investorType]: INVESTOR_TYPE_OPTIONS.map(
    (option) => option.value
  ),
  [URL_PARAMS.ordering]: [
    ...Object.values(SORT),
    ...Object.values(SORT).map((val) => `-${val}`),
  ],
  [URL_PARAMS.status]: EVENT_STATUS_OPTIONS.map((option) => option.value),
  [URL_PARAMS.type]: TYPE_OPTIONS.map((option) => option.value),
  [URL_PARAMS.limit]: ROW_OPTIONS.map((option) => option.label),
};

/**
 * loop through entire param object and validate every item, returning new object
 */
export const validateAllParams = (params: IURL_PARAMS): IURL_PARAMS => {
  return Object.entries(params).reduce((acc, [key, val]) => {
    acc[key] = validateParam(key as URL_PARAMS, val);
    return acc;
  }, {});
};

/**
 * Validate query param so user cannot enter any value they want to be sent to BE endpoints
 */
export const validateParam = (param: URL_PARAMS, value: string) => {
  switch (param) {
    case URL_PARAMS.agg:
      return validParamValues[URL_PARAMS.agg].includes(value) ? value : "";
    case URL_PARAMS.filter:
      return validParamValues[URL_PARAMS.filter].includes(
        value as QuestionFilterStatusType
      )
        ? value
        : "";
    case URL_PARAMS.investorType:
      return validParamValues[URL_PARAMS.investorType].includes(
        value as InvestorType
      )
        ? value
        : "";
    case URL_PARAMS.limit:
      return isNaN(parseInt(value)) ||
        !validParamValues[URL_PARAMS.limit].includes(value)
        ? "10"
        : value;
    case URL_PARAMS.offset:
      return isNaN(parseInt(value)) ? "0" : value;
    case URL_PARAMS.ordering:
      return validParamValues[URL_PARAMS.ordering].includes(value as SORT)
        ? value
        : "";
    case URL_PARAMS.page:
      return isNaN(parseInt(value)) ? "1" : value;
    case URL_PARAMS.pageSize:
      return isNaN(parseInt(value)) ? "10" : value;
    case URL_PARAMS.query:
      return sanitize(value);
    case URL_PARAMS.rows:
      return isNaN(parseInt(value)) ? "10" : value;
    case URL_PARAMS.search:
      return sanitize(value);
    case URL_PARAMS.status:
      return validParamValues[URL_PARAMS.status].includes(
        value as EventStatusOptions
      )
        ? value
        : "";
    case URL_PARAMS.type:
      return validParamValues[URL_PARAMS.type].includes(
        value as ShareholderType
      )
        ? value
        : "";
    default:
      return value;
  }
};
