import { Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  IntroBanner,
  ListHeader,
  NoboDialog,
  ViewSampleDialog,
} from "../../../../components/Common";
import {
  ShareholderStats,
  ShareholdersTable,
  ShareholderGroups,
  TrendGraphCard,
} from "./components";
import { CollapsingAlert } from "../../../../components/Messages";
import { useGetAllProxyEventsQuery } from "../../../../store/proxyEvents";
import { IProxyEvent } from "../../../../store/proxyEvents/types";
import dayjs from "dayjs";
import { COLORS, DATE_FORMAT, IButtonAction } from "@asayinc/component-library";
import { useGetNoboStatusQuery } from "../../../../store/consumerUser";
import { useIsShareholdersEnabled } from "../../useIsShareholdersEnabled";

const AllShareholders = () => {
  const {
    data,
    isSuccess: isNoboCheckSuccess,
    isLoading,
  } = useGetNoboStatusQuery();
  const hasRequested = isNoboCheckSuccess && !data?.displayNoboModal;
  const [isNoboDialogOpen, setIsNoboDialogOpen] = useState<boolean>(false);
  const [isSampleDialogOpen, setIsSampleDialogOpen] = useState<boolean>(false);
  useEffect(() => {
    document.title = "Shareholders | Say Issuer Portal";
  }, []);

  const { isFeatureEnabled, isGetFeaturesSuccess } = useIsShareholdersEnabled();

  const { data: events = [] as IProxyEvent[] } =
    useGetAllProxyEventsQuery(false);

  // api returns active as well as past events, return only events with a meeting.meetingDate in the past using dayjs
  const proxyEventDate = useMemo(
    () =>
      events.filter((event) =>
        dayjs(event.meeting.meetingDate).isBefore(dayjs())
      )?.[0]?.recordDate,
    [events]
  );

  // toggle sample dialog
  const toggleSampleDialog = () => {
    setIsSampleDialogOpen(!isSampleDialogOpen);
  };

  // toggle nobo dialog
  const toggleNoboDialog = () => {
    setIsNoboDialogOpen(!isNoboDialogOpen);
  };

  const alertActions = [
    {
      label: hasRequested ? "Request submitted" : "Access insights now",
      onClick: toggleNoboDialog,
      disabled: hasRequested,
    },
  ];

  // request more info from sample dialog
  // open nobo dialog to confirm
  const dialogRequestInfo = () => {
    toggleNoboDialog();
    toggleSampleDialog();
  };

  // dialog button actions
  const buttonActions = [
    {
      label: hasRequested ? "Request submitted" : "Access now",
      onClick: dialogRequestInfo,
      variant: "primary",
      disabled: hasRequested,
    },
  ] as IButtonAction[];

  return (
    <Stack
      px={10}
      pb={10}
      width="100%"
      maxWidth={1440}
      m="0 auto"
      data-testid="all-shareholders-page"
    >
      <ListHeader title="Shareholders" sx={{ pb: 8 }} />

      {!isFeatureEnabled && isGetFeaturesSuccess && !!proxyEventDate && (
        <CollapsingAlert
          showAlert={true}
          actions={alertActions}
          showNotificationIcon={false}
          defaultCloseIcon={false}
          message={
            hasRequested
              ? "We've received your request for daily shareholder insights - you'll receive an email when your shareholder information is available."
              : `Below is a snapshot of your shareholders, as of your last proxy event on ${dayjs(
                  proxyEventDate
                ).format(
                  DATE_FORMAT
                )}. To get the latest data on your shareholders, request more info.`
          }
          sx={{
            width: "100%",
            mb: 8,
            px: "32px !important",
          }}
        />
      )}
      <ViewSampleDialog
        open={isSampleDialogOpen}
        img="imgs/samples/example-shareholder-insight.png"
        toggleDialog={toggleSampleDialog}
        footerText="View your top shareholders, their locations, and how their shares change over time with Say's Shareholder Analytics."
        buttonActions={buttonActions}
        title="Shareholder Insights - Sample"
        description="Dive deeper into insights about your top retail shareholders"
      />
      <IntroBanner
        page="shareholders"
        bgcolor={COLORS.LIGHT_PINK}
        img={`${process.env.REACT_APP_ASSETS}images/ir-portal/ip/shareholders_banner_illustration.svg`}
        header="Understand your retail shareholders"
        description="Say's shareholder analytics provide daily insights into your retail shareholders, including how shares are distributed and how top shareholders respond over time."
        subCopyCta={{
          label: "View sample",
          onClick: toggleSampleDialog,
        }}
        buttons={
          hasRequested || isLoading
            ? []
            : [
                {
                  label: "Access now",
                  variant: "primary",
                  onClick: toggleNoboDialog,
                },
              ]
        }
        sx={{ mb: 8 }}
      />
      <NoboDialog open={isNoboDialogOpen} toggleDialog={toggleNoboDialog} />
      <ShareholderStats />
      <ShareholderGroups />
      <TrendGraphCard />
      <ShareholdersTable />
    </Stack>
  );
};

export default AllShareholders;
