import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { useMemo, useState } from "react";
import { GRAPH_DATE_FORMAT } from "../../../../../../../components/Graphs/constants";
import { useGetTopShareholderTrendsQuery } from "../../../../../../../store/allShareholderStats";
import { useGetAllEventsQuery } from "../../../../../../../store/allEvents";
import { EventStatusOptions } from "../../../../../../../types/Events";
import {
  GraphIconType,
  IGraphDotStats,
} from "../../../../../../../components/Graphs/types";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  INIT_FEATURES_STATE,
  useGetFeaturesQuery,
} from "../../../../../../../store/settings";

dayjs.extend(quarterOfYear);

const DATE_FORMAT = "YYYY-MM-DD";

export const useGraphData = (isFeatureEnabled: boolean) => {
  const { data: features = INIT_FEATURES_STATE } = useGetFeaturesQuery();
  const [timeframe, setTimeframe] = useState<"quarter" | "month">("quarter");
  // start and end of graph
  const start = dayjs().startOf(timeframe);

  // start and end of event query, (dont include today as cant map an event from today since stats lag 1 day)
  const startString = start.format(DATE_FORMAT);

  const end = dayjs().subtract(1, "day");
  const endString = end.format(DATE_FORMAT);

  // get events to display as custom icons on trend lines
  const { data: eventList } = useGetAllEventsQuery(
    features.qAndA
      ? {
          event_start_datetime_after: dayjs().isSame(start, "day")
            ? end.startOf(timeframe).format(DATE_FORMAT)
            : startString,
          event_start_datetime_before: endString,
          status: EventStatusOptions.COMPLETED,
        }
      : skipToken
  );
  // get stats to create trend lines
  const {
    data: statTrends,
    isFetching,
    isUninitialized,
  } = useGetTopShareholderTrendsQuery(
    isFeatureEnabled
      ? {
          period: timeframe.charAt(0),
        }
      : skipToken
  );

  // event data to display on trend lines, full date is required for ensuring line exists where plotted dot goes
  const graphEvents = useMemo(
    () =>
      eventList?.results?.reduce(
        (obj, event) => ({
          ...obj,
          [dayjs(event.eventStartDatetime).format(GRAPH_DATE_FORMAT)]: {
            name: event.title,
            type: GraphIconType.CalendarEvent,
            fullDate: event.eventStartDatetime,
          },
        }),
        {}
      ) as IGraphDotStats,
    [eventList]
  );

  /** CORE LINE GRAPH DATA **/
  // parse graph data to be able to be displayed in graph
  const graphData = useMemo(() => {
    if (isFetching || isUninitialized || !statTrends) {
      return [];
    }
    const combinedData = statTrends?.top50.map((t50, i) => ({
      name: dayjs(t50.recordDate).format(GRAPH_DATE_FORMAT),
      fullDate: dayjs(t50.recordDate).format(),
      top50: t50.sharesOwned,
      top100: statTrends?.top100?.[i]?.sharesOwned || null,
      top500: statTrends?.top500?.[i]?.sharesOwned || null,
    }));

    return combinedData;
    // without isFetching, in jest this runs when statTrends exists but isFetching is false but statTrends doesnt yet have data
  }, [statTrends, isFetching]);

  return {
    isFetching: isFetching || isUninitialized,
    graphData,
    timeframe,
    setTimeframe,
    graphEvents,
  };
};
