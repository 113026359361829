import { refreshTokenIfExpired } from "@asayinc/component-library";
import { logout } from "../../api/adapter";
import axios from "axios";

interface IResponse {
  data: {
    filename: string;
    key: string;
    url: string;
  };
}

/**
 * Post image and return a url path of where the image lives
 */
export const postImage = async (
  image: File | Blob,
  requestedFilename: string,
  type: "company" | "leader" | "asset"
): Promise<{ data: { filename: string; key: string; url: string } }> => {
  try {
    const imageFile = new File([image], requestedFilename);

    /**
     * protectedConsumerApi.put does not include file in request. axios.put
     * is being used here as a workaround.
     */
    const token = await refreshTokenIfExpired(logout);
    const {
      data: { filename, key, url },
    }: IResponse = await axios.put(
      `${process.env.REACT_APP_CONSUMER_API}v3/portal/company-profile/upload/`,
      {
        type,
        filename: requestedFilename,
        image: imageFile,
      },
      {
        headers: {
          Authorization: `JWT ${token}`,
          "content-type": "multipart/form-data",
        },
      }
    );

    return { data: { filename, key, url } };
  } catch {
    throw new Error();
  }
};

/**
 * Retrieve an image and return a File for use in postImage
 */
const getImgName = (url: string) =>
  url.slice(url.lastIndexOf("/") + 1).split("?")[0];

export const retrieveImage = async (url: string): Promise<File> => {
  try {
    const res = await axios.get(url, { responseType: "blob" });
    const fileName = getImgName(url);
    const mimeType = res.headers["content-type"];
    return new File([res.data], fileName, { type: mimeType });
  } catch (error) {
    throw new Error();
  }
};
