// router
import { useParams } from "react-router-dom";
import { CardWrapper } from "../../../../../components/Common";
import {
  IEventSummary,
  useGetEventSummaryQuery,
} from "../../../../../store/eventStats";
import { COLORS, Text, TooltipInfoIcon } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { formatAsPercentage } from "../../../../../utils";

const ExportFeedback = (): JSX.Element => {
  const { eventSlug } = useParams() as { eventSlug: string };
  const { data, isLoading } = useGetEventSummaryQuery(eventSlug);
  return (
    <CardWrapper cardName="feedback-summary">
      <Text variant="subtitle2">Feedback summary</Text>
      <Stack gap={12} mt={6} direction="row" px={8}>
        <Stack flexGrow={1} gap={3}>
          <Text variant="subtitle2">Total Replies</Text>
          <Stack direction="row" alignItems="flex-end">
            <Text variant="h5" loadingProps={{ sx: { width: 30 } }}>
              {data?.replies}
            </Text>
            <Text loadingProps={{ sx: { width: 30 } }} variant="subtitle2">
              &nbsp;/ {data?.feedbackSent}
            </Text>
          </Stack>
        </Stack>
        <Stack flexGrow={1} gap={3}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Text variant="subtitle2">CSAT score</Text>
            <TooltipInfoIcon
              iconColor={COLORS.BLACK}
              title="Customer Satisfaction (CSAT) shows the number of satisfied and very satisfied shareholders, out of all shareholders who responded."
            />
          </Stack>
          <Text variant="h5" loadingProps={{ sx: { width: 30 } }}>
            {isLoading
              ? undefined
              : formatAsPercentage((data as IEventSummary).csat)}
          </Text>
        </Stack>
        <Stack flexGrow={1} gap={3}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Text variant="subtitle2">Neutral and above ratings</Text>
            <TooltipInfoIcon
              iconColor={COLORS.BLACK}
              title="% of replies which were neutral, satisfied or very satisfied."
            />
          </Stack>
          <Text variant="h5" loadingProps={{ sx: { width: 30 } }}>
            {isLoading
              ? undefined
              : formatAsPercentage((data as IEventSummary).neutralPlus)}
          </Text>
        </Stack>
      </Stack>
    </CardWrapper>
  );
};

export default ExportFeedback;
