import { Card, Text } from "@asayinc/component-library";
import { Popover } from "@mui/material";
import { IActiveDotState } from "../../Organisms/TrendGraph/TrendGraph";

interface IProps {
  open: boolean;
  handleClose: () => void;
  activeEvent: IActiveDotState | null;
}

const MailingPopover = ({ open, handleClose, activeEvent }: IProps) => {
  return (
    <Popover
      id={"event-popover"}
      open={open}
      anchorEl={activeEvent?.anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        pointerEvents: "none",
      }}
    >
      <Card
        data-testid="graph-tooltip"
        elevation={8}
        sx={{ px: 4, py: 3, borderRadius: 2 }}
      >
        {activeEvent && (
          <Text variant="body3" emphasis="medium_high">
            {activeEvent.name}
          </Text>
        )}
      </Card>
    </Popover>
  );
};

export default MailingPopover;
