import { useGetAllEventsQuery } from "../../../../../store/allEvents";
import EventCardList from "../EventCardList";
import { DEFAULT_LIMIT } from "../../../constants";
import { EventStatusOptions } from "../../../../../types/Events";
import { initialAllEventState } from "../../../../../store/allEvents";
import React from "react";

interface IProps {
  showLoadingState?: boolean;
}

const EventsListContainer = ({ showLoadingState }: IProps): JSX.Element => {
  const { data = initialAllEventState, isLoading } = useGetAllEventsQuery({
    offset: "0",
    limit: String(DEFAULT_LIMIT),
    statuses: `${EventStatusOptions.DRAFT},${EventStatusOptions.PENDING},${EventStatusOptions.APPROVED},${EventStatusOptions.PUBLISHED}`,
  });

  return (
    <EventCardList
      events={data.results}
      count={data.count}
      isLoading={isLoading || !!showLoadingState}
    />
  );
};

export default React.memo(EventsListContainer);
