import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
// components
import { Stack, Box } from "@mui/material";
import {
  ChooseRecipients,
  Footer,
  DraftInAppMessage,
  DraftMessage,
  NameCampaign,
  ReviewMessage,
  Header,
  SaveDialog,
  DeleteDialog,
} from "../../../../components/Messages";
// utils
import { Context } from "../../../../context/Messages";
// types
import { useGetMessageData } from "../../../../hooks/useGetMessageData";
import { MessageType } from "../../../../store/messageLists";
import { CREATION_STEP_MAPPING, SESSION_KEY } from "./constants";
import { useRedirectPastCutoff } from "../../hooks";

interface IProps {
  isCreate?: boolean;
}

const ProxyCreateAndEdit = ({ isCreate }: IProps) => {
  // current message and vote id
  const { messageId, proxyEventId } = useParams() as {
    proxyEventId: string;
    messageId: string;
  };

  useRedirectPastCutoff();

  const {
    currentStep,
    setStepState,
    goToStep,
    isMessageLoadingOrUpdating,
    isLoading,
    composeData: { status },
    methods,
    disableNext,
    disableSave,
    updateOrPostMessage,
    previous,
    next,
    cancel,
    blocker,
  } = useGetMessageData({
    isCreate,
    sessionKey: SESSION_KEY,
    stepMapping: CREATION_STEP_MAPPING,
  });

  const steps = useMemo(
    () => [
      <NameCampaign
        key={1}
        isLoading={isMessageLoadingOrUpdating}
        proxyEventId={proxyEventId}
        type={MessageType.ProxySM}
      />,
      <ChooseRecipients
        key={2}
        isLoading={isMessageLoadingOrUpdating}
        showStatusFilter
      />,
      <DraftMessage key={3} isLoading={isMessageLoadingOrUpdating} />,
      <DraftInAppMessage key={4} isLoading={isMessageLoadingOrUpdating} />,
      <ReviewMessage key={5} stepMapping={CREATION_STEP_MAPPING} />,
    ],
    [isMessageLoadingOrUpdating]
  );

  // context data for changing step
  const contextData = useMemo(
    () => ({
      setStep: setStepState,
      currentStep,
    }),
    [currentStep, messageId]
  );

  return (
    <Context.Provider value={contextData}>
      <FormProvider {...methods}>
        <Stack height="100%">
          <Stack
            p={10}
            pb={0}
            maxWidth={1440}
            width="100%"
            flexGrow={1}
            m="0 auto"
            position="relative"
            data-testid={
              isLoading ? "proxy-create-edit-loading" : "proxy-create-edit"
            }
          >
            <Header
              goToStep={goToStep}
              currentStep={currentStep}
              id={messageId}
              isCreate={isCreate}
              messageStatus={status}
            />
            <Box
              sx={{
                flexGrow: 1,
                pt: 0,
                pb: 8,
              }}
            >
              {steps[currentStep]}
            </Box>
          </Stack>
          <Footer
            isCreate={!!isCreate}
            cancel={cancel}
            save={updateOrPostMessage}
            next={next}
            isMessageLoadingOrUpdating={isMessageLoadingOrUpdating}
            previous={previous}
            disableSave={disableSave}
            currentStepName={CREATION_STEP_MAPPING[currentStep]}
            disableNext={disableNext}
            currentStep={currentStep}
          />
          <DeleteDialog />
          <SaveDialog blocker={blocker} save={updateOrPostMessage} />
        </Stack>
      </FormProvider>
    </Context.Provider>
  );
};

export default ProxyCreateAndEdit;
