import { useCallback, useState } from "react";
import {
  COLORS,
  ButtonV2,
  IButtonAction,
  Tooltip,
  IconButton,
  ActionablePopover,
} from "@asayinc/component-library";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Stack, Box } from "@mui/material";
import { MiniDialog } from "../../../../../components/Common";
import { useEventData } from "../../../hooks";
import { useGetSettingsQuery } from "../../../../../store/settings";
import { ISettings } from "../../../../../store/settings/types";
import { useFormContext, useWatch } from "react-hook-form";
import { generateEventSlug } from "../../../helpers/getMetaData";

interface IProps {
  deleteDraftEvent: () => void;
  previous: () => void;
  currentStep: number;
  isSubmitStep?: boolean;
  saveEvent: () => void;
  isLoading?: boolean;
  isDraft?: boolean;
}

const CreateQaFooter = ({
  deleteDraftEvent,
  previous,
  currentStep,
  isSubmitStep,
  saveEvent,
  isLoading,
  isDraft,
}: IProps): JSX.Element => {
  const [confirmOpen, setConfirm] = useState(false);

  const {
    event: { slug },
  } = useEventData();
  const { data: settings = {} } = useGetSettingsQuery();
  const { name: companyName = "", submitted } = settings as ISettings;
  const [meta, eventType] = useWatch({
    name: ["meta", "eventType"],
  });
  const formData = {
    meta,
    eventType,
  };

  // ability to generate a slug must exist to save
  const formSlug = generateEventSlug(companyName, formData);

  const {
    formState: { isDirty, errors, isValid },
  } = useFormContext();

  const hasError = Object.keys(errors).length > 0;

  // for popover
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleAnchorEl = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(e.currentTarget);
    }
  };
  // end popover

  // click delete draft cta
  const deleteDraft = () => {
    setConfirm(false);
    deleteDraftEvent();
  };

  // close delete draft dialog
  const closeDialog = useCallback(() => {
    setConfirm(false);
  }, []);

  // open delete dialog
  const openDialog = useCallback(() => {
    setConfirm(true);
  }, []);

  const buttonActions = [
    {
      onClick: deleteDraft,
      label: "Delete",
      variant: "warning",
    },
    {
      onClick: closeDialog,
      label: "Cancel",
      variant: "secondary",
    },
  ] as IButtonAction[];

  return (
    <Stack
      position="sticky"
      bottom={0}
      right={0}
      p={10}
      pb={0}
      pt={0}
      width="100%"
      maxWidth={1440}
      m="0 auto"
      bgcolor="#F0F0F0"
      zIndex={10}
    >
      <Box px={1.5}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            borderTop: `1px solid ${COLORS.P_OUTLINE}`,
            pt: 6,
            pb: 10,
          }}
        >
          <Stack>
            {currentStep > 0 && (
              <ButtonV2
                variant="secondary"
                disabled={isLoading}
                data-testid="btn-prev-step"
                onClick={previous}
              >
                Previous
              </ButtonV2>
            )}
          </Stack>
          <Stack direction="row" gap={3}>
            {isDraft && (
              <IconButton
                data-testid="footer-popover-button"
                onClick={toggleAnchorEl}
                variant="ctaAlt"
              >
                <MoreVertIcon />
              </IconButton>
            )}
            {!isSubmitStep && isDraft && (
              <ButtonV2
                variant="secondary"
                disabled={
                  (!formSlug && !slug) || !isDirty || isLoading || !isValid
                } // save is disabled if nothing is dirty or if there is no slug or forms invalid
                onClick={saveEvent}
                data-testid="btn-savedraft"
              >
                Save
              </ButtonV2>
            )}
            {isSubmitStep ? (
              <Tooltip
                title={
                  !submitted
                    ? "A few more details need to be completed before submitting this Q&A for approval. Please go to company settings on the left and fill them out first."
                    : ""
                }
              >
                <ButtonV2
                  data-testid="btn-submit"
                  type="submit"
                  disabled={!submitted || isLoading}
                >
                  Submit
                </ButtonV2>
              </Tooltip>
            ) : (
              <ButtonV2
                data-testid="btn-next"
                type="submit"
                disabled={(hasError && !isValid) || isLoading}
              >
                Next
              </ButtonV2>
            )}
          </Stack>
          <ActionablePopover
            anchorEl={anchorEl}
            handleClose={handleClose}
            actions={[
              {
                testid: "btn-delete-event",
                text: "Delete",
                onClick: openDialog,
              },
            ]}
          />

          <MiniDialog
            open={confirmOpen}
            title="Delete Q&A Draft?"
            content={
              <Box>
                Are you sure you want to delete? This action can&apos;t be
                undone.
              </Box>
            }
            buttonActions={buttonActions}
            close={closeDialog}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default CreateQaFooter;
