import { useSearchParams } from "react-router-dom";
import { Event, EventStatusOptions } from "../../types/Events";
import { useGetAllEventsQuery } from "../../store/allEvents";
import { SORT, SORT_SYMBOL, URL_PARAMS } from "../../constants";
import { validateAllParams } from "../../utils/validateParam";
import { DEFAULT_LIMIT } from "./constants";
import { useMemo } from "react";
import dayjs from "dayjs";

interface EventPageData {
  searchParams: URLSearchParams;
  completedEvents: Event[];
  count: number;
  isLoading: boolean;
  isFetching: boolean;
  page: string;
  limit: string;
  search: string;
  setPage: (newPage: string) => void;
  setLimit: (newLimit: string) => void;
}

export const useEventsPageData = ({
  ordering,
}: {
  ordering: string;
}): EventPageData => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    [URL_PARAMS.limit]: limit = "10",
    [URL_PARAMS.page]: page = "1",
    [URL_PARAMS.search]: search = "",
  } = validateAllParams(Object.fromEntries(new URLSearchParams(searchParams)));
  const setPage = (newPage: string) => {
    searchParams.set(URL_PARAMS.page, newPage);
    setSearchParams(searchParams);
  };
  const setLimit = (newLimit: string) => {
    searchParams.set(URL_PARAMS.limit, newLimit);
    setSearchParams(searchParams);
  };

  const offset = parseInt(page) * parseInt(limit) - parseInt(limit);
  const params = {
    offset: String(offset),
    limit,
    ...(search ? { search } : {}),
    ordering,
    status: EventStatusOptions.COMPLETED,
  };
  const {
    data = { results: [], count: 0 },
    isLoading,
    isFetching,
  } = useGetAllEventsQuery(params);
  const { count, results: completedEvents } = data;

  return {
    searchParams,
    page,
    limit,
    search,
    setPage,
    setLimit,
    completedEvents,
    count,
    isLoading,
    isFetching,
  };
};

const defaultParams = {
  offset: "0",
  limit: String(DEFAULT_LIMIT),
};

export const useHasAnEvent = (): {
  hasInProgressEvent: boolean;
  hasCompletedEvent: boolean;
  hasActiveEvent: boolean;
  eventSummarySlugs: string[];
  hasAnEvent: boolean;
  inProgressOrActiveCount: number;
  loading: boolean;
  isError: boolean;
} => {
  const {
    data: inProgressData,
    isLoading: isInProgressLoading,
    isError: isInProgressError,
  } = useGetAllEventsQuery({
    ...defaultParams,
    statuses: `${EventStatusOptions.DRAFT},${EventStatusOptions.PENDING},${EventStatusOptions.APPROVED},${EventStatusOptions.PUBLISHED}`,
  });
  const {
    data: activeData,
    isLoading: isActiveLoading,
    isError: isActiveError,
  } = useGetAllEventsQuery({
    ...defaultParams,
    statuses: EventStatusOptions.ACTIVE,
  });
  const {
    data: completedData,
    isLoading: isCompletedLoading,
    isError: isCompletedError,
  } = useGetAllEventsQuery({
    ...defaultParams,
    limit: "10",
    ordering: `${SORT_SYMBOL.desc}${SORT.startTime}`,
    status: EventStatusOptions.COMPLETED,
  });

  const hasInProgressEvent = !!inProgressData?.count;
  const hasActiveEvent = !!activeData?.count;
  const hasCompletedEvent = !!completedData?.count;

  const loading = isInProgressLoading || isCompletedLoading || isActiveLoading;
  const isError = isInProgressError || isCompletedError || isActiveError;

  // active slugs and slugs of 30 day or less old completed events show as large cards
  const eventSummarySlugs = useMemo(() => {
    if (!isActiveLoading && !isCompletedLoading) {
      const activeEventSlugs =
        activeData?.results.map((event) => event.slug) || [];
      const completedEventSlugs =
        completedData?.results
          .filter((e) => dayjs().diff(dayjs(e.eventStartDatetime), "day") <= 30)
          .map((event) => event.slug) || [];

      return [...activeEventSlugs, ...completedEventSlugs];
    }
    return [];
  }, [isActiveLoading, isCompletedLoading]);

  return {
    hasActiveEvent,
    hasInProgressEvent,
    eventSummarySlugs,
    inProgressOrActiveCount:
      (inProgressData?.count || 0) + eventSummarySlugs.length,
    hasCompletedEvent,
    hasAnEvent: hasInProgressEvent || hasCompletedEvent,
    loading,
    isError,
  };
};
